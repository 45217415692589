import { Component } from '@angular/core';
import { ScoreListService } from './services/score-list.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { ConsoleDisableService } from './services/console-disable.service';
import { HProfileService } from './services/h-profile.service';

// ########## GET DEVICE INFO - IN THIS CASE LANGUAGE ##########
// https://capacitorjs.com/docs/apis/device#install
import { Device } from '@capacitor/device';
import { firebaseConfig } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  photoURL: string;
  lang = 'en';

  constructor(
    public dc: ConsoleDisableService,
    public sl: ScoreListService,
    private hProf: HProfileService,
    private auth: AuthService,
    private router: Router
  ) {
    // ------- CONSOLE PRINT ----------  //

    console.log('Constructor - AppComponent'); // ------- CONSOLE PRINT ----------  //

    Device.getLanguageCode().then((lang) => {
      this.auth.init(lang.value);
      this.lang = lang.value;
    });

    this.sl.init();
    this.hProf.init();

    // this.auth.init().subscribe((photoURL) => {
    //   this.photoURL = photoURL;
    //   console.log(photoURL);
    // });
  }

  goTo(page) {
    this.router.navigateByUrl('/' + page, { replaceUrl: true });
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }
}
