import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { PermissionsService } from 'src/app/services/permission.service';
import { Capacitor } from '@capacitor/core';
import { PROD } from 'src/environments/environment';


export const loginGuard = () => {
  const router = inject(Router);
  const service = inject(PermissionsService);
  if(!Capacitor.isNativePlatform() && PROD) router.navigateByUrl('/badges',{replaceUrl: true}) ;
  return service.isAvailable().pipe(
  tap((value) => !value ? router.navigateByUrl('/login',{replaceUrl: true}) : true ));
};

export const webGuard = () => {
  const router = inject(Router);
  const service = inject(PermissionsService);
  return service.isNative().pipe(
  tap((value) => !value ? router.navigateByUrl('/badges',{replaceUrl: true}) : true ));
};
