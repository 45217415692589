import { SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';
import { Surgery } from 'src/app/classes.interfaces';

export const environment = {
  production: true,
};

export const SCORE_KEY = 'scores';
export const USER_KEY = 'users';
export const ACCESS_KEY = 'access';
export const HOSP_KEY = 'hospProfile';

export const PROD = true;

export const firebaseConfig = {
  apiKey: 'AIzaSyBlDQiSTkSpHllG5aP-z1KAemjBpXACdBE',
  authDomain: 'excarebr.app',
  databaseURL: 'https://excare-br-366917-default-rtdb.firebaseio.com',
  projectId: 'excare-br-366917',
  storageBucket: 'excare-br-366917.appspot.com',
  messagingSenderId: '326549977141',
  appId: '1:326549977141:web:c57dad253246e2a006d97c',
  measurementId: 'G-GWPRNS6T3B',
};

export const googleNativeSignInOptions = {
  androidClientId: '326549977141-nvjtg5ilfp95v5bdhcp1git16spmptda.apps.googleusercontent.com',
  iosClientId: '326549977141-2gujl4v9vp13ovvlrnls23gbpril8ch2.apps.googleusercontent.com',
};

export const googleWebSignInOptions = {
  clientId: '326549977141-gqca4pmarap6ahrqs3goj7htt4kco7a4.apps.googleusercontent.com',
  clientSecret: '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',
  redirectUri: 'https://excarebr.app/__/auth/handler/',
};

export const appleSignInOptions: SignInWithAppleOptions = {
  clientId: 'excare.model.v1',
  redirectURI: 'https://excarebr.app/__/auth/handler',
  scopes: 'name, email',
  state: '123455',
};

export const fullSurgeryList: {'pt': Surgery[],'en': Surgery[]}  =
{ 'pt':[
  {
    id: 1,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'ALONGAMENTO CIRÚRGICO DO PALATO MOLE',
    size: 0,
  },
  {
    id: 2,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'ALVEOLOPLASTIA COM ENXERTO ÓSSEO EM PACIENTES COM ANOMALIA CRANIOFACIAL',
    size: 0,
  },
  {
    id: 3,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'BIOPSIA / EXCISÃO EM CUNHA DE LABIO',
    size: 0,
  },
  {
    id: 4,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'BIOPSIA DE GLÂNDULA SALIVAR',
    size: 0,
  },
  {
    id: 5,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'BIOPSIA DE LÍNGUA',
    size: 0,
  },
  {
    id: 6,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'BIOPSIA DE TECIDOS MOLES DA BOCA',
    size: 0,
  },
  {
    id: 7,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'CORREÇÃO CIRÚRGICA DE FÍSTULA ORO-NASAL / ORO-SINUSAL',
    size: 0,
  },
  {
    id: 8,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'CURETAGEM PERIAPICAL',
    size: 0,
  },
  {
    id: 9,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'DRENAGEM DE ABSCESSO DA BOCA E ANEXOS',
    size: 0,
  },
  {
    id: 10,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'ENXERTO ÓSSEO DE ÁREA DOADORA INTRABUCAL',
    size: 0,
  },
  {
    id: 11,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'EXCISÃO DE CÁLCULO DE GLÂNDULA SALIVAR',
    size: 0,
  },
  {
    id: 12,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'EXCISÃO DE RÂNULA OU MUCOCELE',
    size: 0,
  },
  {
    id: 13,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'EXCISÃO DE TUMOR DE GLÂNDULA PARÓTIDA ',
    size: 0,
  },
  {
    id: 14,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'EXCISÃO E SUTURA DE LESÃO NA BOCA',
    size: 0,
  },
  {
    id: 15,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'EXÉRESE DE CISTO ODONTOGÊNICO ',
    size: 0,
  },
  {
    id: 16,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'EXÉRESE OU EXCISÃO DE CISTOS ODONTOLÓGICOS',
    size: 0,
  },
  {
    id: 17,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'EXODONTIA DE DENTE PERMANENTE',
    size: 0,
  },
  {
    id: 18,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'FÍSTULA OROANTRAL - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 19,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'FÍSTULA OROFACIAL - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 20,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'FÍSTULA ORONASAL - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 21,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'FRENOTOMIA / FRENECTOMIA LINGUAL',
    size: 0,
  },
  {
    id: 22,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'FRENOTOMIA LABIAL',
    size: 0,
  },
  {
    id: 23,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'GLOSSECTOMIA  TOTAL',
    size: 1,
  },
  {
    id: 24,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'GLOSSECTOMIA PARCIAL ',
    size: 1,
  },
  {
    id: 25,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'LABIOPLASTIA',
    size: 0,
  },
  {
    id: 26,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'OSTEOTOMIA DAS FRATURAS ALVÉOLO-DENTÁRIAS',
    size: 0,
  },
  {
    id: 27,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'PAROTIDECTOMIA PARCIAL ',
    size: 0,
  },
  {
    id: 28,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'PAROTIDECTOMIA TOTAL COM OU SEM CONSERVAÇÃO DO NERVO FACIAL',
    size: 0,
  },
  {
    id: 29,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RECONSTRUÇÃO DO SULCO GENGIVO-LABIAL',
    size: 0,
  },
  {
    id: 30,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RECONSTRUÇÃO TOTAL OU PARCIAL DE LÁBIO',
    size: 0,
  },
  {
    id: 31,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'REMOÇÃO DE DENTE RETIDO ',
    size: 0,
  },
  {
    id: 32,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE GLÂNDULA SALIVAR',
    size: 0,
  },
  {
    id: 33,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE GLÂNDULA SUBLINGUAL  ',
    size: 0,
  },
  {
    id: 34,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE GLÂNDULA SUBMANDIBULAR ',
    size: 0,
  },
  {
    id: 35,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE LÁBIO COM ENXERTO OU RETALHO ',
    size: 0,
  },
  {
    id: 36,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE LESÃO BENIGNA DA BOCA      ',
    size: 0,
  },
  {
    id: 37,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE LESÃO MALIGNA DA BOCA      ',
    size: 0,
  },
  {
    id: 38,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE LESÃO MALIGNA DE MUCOSA BUCAL ',
    size: 0,
  },
  {
    id: 39,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RESSECÇÃO DE TUMOR DE GLÂNDULA SUBLINGUAL  ',
    size: 0,
  },
  {
    id: 40,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'RETIRADA DE MATERIAL DE SÍNTESE ÓSSEA / DENTÁRIA',
    size: 0,
  },
  {
    id: 41,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'TRATAMENTO CIRÚRGICO DA MACROSTOMIA',
    size: 0,
  },
  {
    id: 42,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'TRATAMENTO CIRÚRGICO DA MICROSTOMIA',
    size: 0,
  },
  {
    id: 43,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA CUTÂNEA DE ORIGEM DENTÁRIA',
    size: 0,
  },
  {
    id: 44,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA ORO-SINUSAL / ORO-NASAL',
    size: 0,
  },
  {
    id: 45,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'TRATAMENTO CIRÚRGICO DE OSTEOMA, ODONTOMA',
    size: 0,
  },
  {
    id: 46,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'TRATAMENTO FENDA PALATINA/LABIAL',
    size: 0,
  },
  {
    id: 47,
    general: 'CABEÇA/PESCOÇO',
    specific: 'BOCA / GL SALIVARES / DENTE',
    name: 'TRATAMENTO ODONTOLÓGICO PARA PACIENTES COM NECESSIDADES ESPECIAIS',
    size: 0,
  },
  {
    id: 48,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'ADENOIDECTOMIA',
    size: 0,
  },
  {
    id: 49,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'AMIGDALECTOMIA',
    size: 0,
  },
  {
    id: 50,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'AMIGDALECTOMIA C/ ADENOIDECTOMIA',
    size: 0,
  },
  {
    id: 51,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'ARITENOIDECTOMIA OU ARITENOPEXIA',
    size: 0,
  },
  {
    id: 52,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'BIOPSIA DE FARINGE/LARINGE',
    size: 0,
  },
  {
    id: 53,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'DRENAGEM DE ABSCESSO FARINGEO',
    size: 0,
  },
  {
    id: 54,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'DRENAGEM DE ABSCESSO PERIAMIGDALIANO',
    size: 0,
  },
  {
    id: 55,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'EXÉRESE DE PAPILOMA EM LARINGE',
    size: 0,
  },
  {
    id: 56,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'EXTIRPAÇÃO DE TUMOR DO CAVUM E FARINGE',
    size: 0,
  },
  {
    id: 57,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'FARINGECTOMIA PARCIAL ',
    size: 0,
  },
  {
    id: 58,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'FARINGECTOMIA TOTAL ',
    size: 0,
  },
  {
    id: 59,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'FARINGOLARINGECTOMIA ',
    size: 1,
  },
  {
    id: 60,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'LARINGECTOMIA PARCIAL',
    size: 1,
  },
  {
    id: 61,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'LARINGECTOMIA TOTAL',
    size: 1,
  },
  {
    id: 62,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'LARINGECTOMIA TOTAL COM ESVAZIAMENTO CERVICAL',
    size: 1,
  },
  {
    id: 63,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'LARINGOFISSURA PARA COLOCAÇÃO DE MOLDE NOS TRAUMATISMOS DE LARINGE',
    size: 0,
  },
  {
    id: 64,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'LARINGOSCOPIA',
    size: 0,
  },
  {
    id: 65,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'MICROCIRURGIA COM LASER PARA REMOÇÃO DE LESÕES MALIGNAS ',
    size: 0,
  },
  {
    id: 66,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'MICROCIRURGIA PARA RESSECÇÃO DE PÓLIPO, NÓDULO OU GRANULOMA',
    size: 0,
  },
  {
    id: 67,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'PALATOPLASTIA COM ENXERTO ÓSSEO',
    size: 0,
  },
  {
    id: 68,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'PALATO-QUEILOPLASTIA',
    size: 0,
  },
  {
    id: 69,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'RECONSTRUÇÃO PARA FONAÇÃO ',
    size: 0,
  },
  {
    id: 70,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'RESSECÇÃO DE NASOANGIOFIBROMA',
    size: 0,
  },
  {
    id: 71,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'RESSECÇÃO DE TUMOR DA RINOFARINGE POR VIDEOENDOSCOPIA',
    size: 0,
  },
  {
    id: 72,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'RESSECÇÃO DE TUMOR DE RINOFARINGE ',
    size: 0,
  },
  {
    id: 73,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'RETIRADA DE CORPO ESTRANHO DE OUVIDO / FARINGE / LARINGE / NARIZ',
    size: 0,
  },
  {
    id: 74,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'TIROPLASTIA',
    size: 0,
  },
  {
    id: 75,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'TRATAMENTO CIRÚRGICO DA INSUFICIÊNCIA VELOFARINGEA ',
    size: 0,
  },
  {
    id: 76,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'TRATAMENTO CIRÚRGICO DE TRAUMA LARINGEO',
    size: 0,
  },
  {
    id: 77,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FARINGE / LARINGE',
    name: 'UVULOPALATOFARINGOPLASTIA',
    size: 0,
  },
  {
    id: 78,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'ARTROPLASTIA PARA LUXAÇÃO RECIDIVANTE ARTICULAÇÃO TÊMPORO-MANDIBULAR',
    size: 0,
  },
  {
    id: 79,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'BIOPSIA DE MANDÍBULA',
    size: 0,
  },
  {
    id: 80,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'BLEFAROPLASTIA',
    size: 0,
  },
  {
    id: 81,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'DRENAGEM DE ABSCESSO DE PÁLPEBRA',
    size: 0,
  },
  {
    id: 82,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'MANDIBULECTOMIA PARCIAL ',
    size: 1,
  },
  {
    id: 83,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'MANDIBULECTOMIA TOTAL ',
    size: 1,
  },
  {
    id: 84,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'MAXILECTOMIA PARCIAL',
    size: 1,
  },
  {
    id: 85,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'MAXILECTOMIA TOTAL ',
    size: 1,
  },
  {
    id: 86,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOPLASTIA DO ARCO ZIGOMÁTICO',
    size: 0,
  },
  {
    id: 87,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOPLASTIA DO MENTO COM OU SEM IMPLANTE ALOPLÁSTICO',
    size: 0,
  },
  {
    id: 88,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOPLASTIA ETMOIDO-ORBITAL',
    size: 0,
  },
  {
    id: 89,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOPLASTIA FRONTO-ORBITAL',
    size: 0,
  },
  {
    id: 90,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOPLASTIA PARA PROGNATISMO / MICROGNATISMO',
    size: 0,
  },
  {
    id: 91,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOPLASTIA DA ÓRBITA',
    size: 0,
  },
  {
    id: 92,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOSSÍNTESE DA FRATURA COMPLEXA DA MANDÍBULA',
    size: 0,
  },
  {
    id: 93,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOSSÍNTESE DE FRATURA BILATERAL DO CÔNDILO MANDIBULAR',
    size: 0,
  },
  {
    id: 94,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOSSÍNTESE DE FRATURA COMPLEXA DA MAXILA',
    size: 0,
  },
  {
    id: 95,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOSSÍNTESE DE FRATURA DO COMPLEXO NASO-ÓRBITO-ETMOIDAL',
    size: 0,
  },
  {
    id: 96,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOSSÍNTESE DE FRATURA DO COMPLEXO ÓRBITO-ZIGOMÁTICO-MAXILAR',
    size: 0,
  },
  {
    id: 97,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOSSÍNTESE DE FRATURA SIMPLES DE MANDÍBULA',
    size: 0,
  },
  {
    id: 98,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOSSÍNTESE DE FRATURA UNILATERAL DO CÔNDILO MANDIBULAR',
    size: 0,
  },
  {
    id: 99,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOTOMIA DA MANDÍBULA',
    size: 0,
  },
  {
    id: 100,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOTOMIA DA MAXILA',
    size: 0,
  },
  {
    id: 101,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOTOMIA DE MAXILAR          ',
    size: 0,
  },
  {
    id: 102,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOTOMIA TIPO LEFORT I',
    size: 0,
  },
  {
    id: 103,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOTOMIA TIPO LEFORT II',
    size: 0,
  },
  {
    id: 104,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'OSTEOTOMIA TIPO LEFORT III',
    size: 0,
  },
  {
    id: 105,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'PELVIGLOSSOMANDIBULECTOMIA ',
    size: 1,
  },
  {
    id: 106,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RECONSTITUIÇÃO TOTAL DA PÁLPEBRA',
    size: 0,
  },
  {
    id: 107,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RECONSTRUÇÃO CRÂNIO-FACIAL',
    size: 0,
  },
  {
    id: 108,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RECONSTRUÇÃO PARCIAL DA MANDÍBULA COM ENXERTO ÓSSEO',
    size: 0,
  },
  {
    id: 109,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RECONSTRUÇÃO TOTAL DE MANDÍBULA/MAXILA',
    size: 0,
  },
  {
    id: 110,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RECONSTRUÇÃO TOTAL DE MANDÍBULA/MAXILA    ',
    size: 0,
  },
  {
    id: 111,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RECONSTRUÇO TOTAL DE CAVIDADE ORBITÁRIA ',
    size: 0,
  },
  {
    id: 112,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'REDUÇÃO CRUENTA DE AFUNDAMENTO DO MALAR ',
    size: 0,
  },
  {
    id: 113,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'REDUÇÃO CRUENTA DE FRATURA COMINUTIVA DA MANDÍBULA',
    size: 0,
  },
  {
    id: 114,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'REDUÇÃO CRUENTA DE FRATURA DE MANDÍBULA   ',
    size: 0,
  },
  {
    id: 115,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'REDUÇÃO CRUENTA DE FRATURA DO MAXILAR ',
    size: 0,
  },
  {
    id: 116,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'REDUÇÃO DE FRATURA DA MANDÍBULA SEM OSTEOSSÍNTESE',
    size: 0,
  },
  {
    id: 117,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'REDUÇÃO DE FRATURA DA MAXILA - LE FORT I SEM OSTEOSSÍNTESE',
    size: 0,
  },
  {
    id: 118,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'REMODELAÇÃO CRANIOFACIAL EM PACIENTE COM ANOMALIA CRÂNIO E BUCOMAXILOFACIAL',
    size: 0,
  },
  {
    id: 119,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RESSECÇÃO DO CÔNDILO MANDIBULAR',
    size: 0,
  },
  {
    id: 120,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RETIRADA DE CORPO ESTRANHO DOS OSSOS DA FACE',
    size: 0,
  },
  {
    id: 121,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RETIRADA DE MEIOS DE FIXAÇÃO MAXILO-MANDIBULAR',
    size: 0,
  },
  {
    id: 122,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'RETIRADA DOS MEIOS DE FIXAÇÃO',
    size: 0,
  },
  {
    id: 123,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRANSLOCAÇÃO ETMOIDO ORBITAL PARA TRATAMENTO DO HIPERTELORISMO',
    size: 0,
  },
  {
    id: 124,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO CIRÚRGICO DA BLEFAROCALASE',
    size: 0,
  },
  {
    id: 125,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO CIRÚRGICO DE ANQUILOSE DA ARTICULAÇÃO TÊMPORO-MANDIBULAR',
    size: 0,
  },
  {
    id: 126,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO CIRÚRGICO DE CISTO DO COMPLEXO MAXILO-MANDIBULAR',
    size: 0,
  },
  {
    id: 127,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA E CISTOS ORO-MAXILARES',
    size: 0,
  },
  {
    id: 128,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO OSSO ZIGOMÁTICO ',
    size: 0,
  },
  {
    id: 129,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO CIRÚRGICO DE OSTEOMIELITE DE OSSOS DA FACE',
    size: 0,
  },
  {
    id: 130,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO CIRÚRGICO DE PARALISIA FACIAL ',
    size: 0,
  },
  {
    id: 131,
    general: 'CABEÇA/PESCOÇO',
    specific: 'FACE / MAXILA  / MANDÍBULA ',
    name: 'TRATAMENTO DE PTOSE PALPEBRAL',
    size: 0,
  },
  {
    id: 132,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'BIOPSIA DE PARATIROIDE',
    size: 0,
  },
  {
    id: 133,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'BIOPSIA DE TIREOIDE / PARATIREOIDE (PAAF)',
    size: 0,
  },
  {
    id: 134,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'CERVICOTOMIA EXPLORADORA',
    size: 0,
  },
  {
    id: 135,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'DISSECÇÃO RADICAL DO PESCOÇO',
    size: 0,
  },
  {
    id: 136,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'DRENAGEM DE ABSCESSO CERVICAL PROFUNDO',
    size: 0,
  },
  {
    id: 137,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'ENDARTERECTOMIA CAROTIDEA',
    size: 0,
  },
  {
    id: 138,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'ESVAZIAMENTO CERVICAL UNILATERAL',
    size: 0,
  },
  {
    id: 139,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'EXÉRESE DE CISTO BRANQUIAL',
    size: 0,
  },
  {
    id: 140,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'EXÉRESE DE CISTO TIREOGLOSSO',
    size: 0,
  },
  {
    id: 141,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'EXÉRESE DE NEUROBLASTOMA CERVICAL',
    size: 1,
  },
  {
    id: 142,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'ISTMECTOMIA OU NODULECTOMIA',
    size: 0,
  },
  {
    id: 143,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'LIGADURA DE CARÓTIDA OU RAMOS',
    size: 0,
  },
  {
    id: 144,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'PARATIREOIDECTOMIA',
    size: 0,
  },
  {
    id: 145,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'PARATIREOIDECTOMIA TOTAL ',
    size: 0,
  },
  {
    id: 146,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'PAROTIDECTOMIA TOTAL AMPLIADA ',
    size: 0,
  },
  {
    id: 147,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'REIMPLANTE PARATIREOIDE',
    size: 0,
  },
  {
    id: 148,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'RESSECÇÃO DE TUMOR TIREOIDIANO POR VIA TRANSESTERNAL ',
    size: 1,
  },
  {
    id: 149,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'RETIRADA DE BÓCIO INTRATORÁCICO (VIA ESTERNAL)',
    size: 1,
  },
  {
    id: 150,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TIREOIDECTOMIA PARCIAL',
    size: 0,
  },
  {
    id: 151,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TIREOIDECTOMIA TOTAL',
    size: 0,
  },
  {
    id: 152,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TIREOIDECTOMIA TOTAL COM ESVAZIAMENTO GANGLIONAR',
    size: 0,
  },
  {
    id: 153,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TIREOPLASTIA',
    size: 0,
  },
  {
    id: 154,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TRAÇÃO CERVICAL TRANSESQUELÉTICA',
    size: 0,
  },
  {
    id: 155,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TRATAMENTO CIRÚRGICO DE LIPOMATOSE CERVICAL',
    size: 0,
  },
  {
    id: 156,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TRATAMENTO CIRÚRGICO DE TUMOR CAROTIDEO',
    size: 0,
  },
  {
    id: 157,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TRATAMENTO CIRÚRGICO TORCICOLO CONGÊNITO',
    size: 0,
  },
  {
    id: 158,
    general: 'CABEÇA/PESCOÇO',
    specific: 'PESCOÇO / TIREOIDE / PARATIREOIDE',
    name: 'TRATAMENTO DE FÍSTULA DO PESCOÇO',
    size: 0,
  },
  {
    id: 159,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'BIOPSIA DE OSSO DO CRÂNIO OU DA FACE',
    size: 0,
  },
  {
    id: 160,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CIRURGIA INTRACRANIANA POR VIA ENDOSCÓPICA',
    size: 0,
  },
  {
    id: 161,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CRANIECTOMIA POR TUMOR ÓSSEO',
    size: 0,
  },
  {
    id: 162,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CRANIOPLASTIA',
    size: 0,
  },
  {
    id: 163,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CRANIOTOMIA DESCOMPRESSIVA',
    size: 1,
  },
  {
    id: 164,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CRANIOTOMIA PARA BIOPSIA ENCEFÁLICA',
    size: 0,
  },
  {
    id: 165,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CRANIOTOMIA PARA DRENAGEM ABSCESSO',
    size: 1,
  },
  {
    id: 166,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CRANIOTOMIA PARA RETIRADA DE CORPO ESTRANHO',
    size: 1,
  },
  {
    id: 167,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'CRANIOTOMIA PARA RETIRADA DE TUMOR INTRACRANIANO',
    size: 1,
  },
  {
    id: 168,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'INSTALAÇÃO DE HALO CRANIANO',
    size: 0,
  },
  {
    id: 169,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'OSTEOTOMIA CRÂNIO-FACIAL',
    size: 0,
  },
  {
    id: 170,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'RECONSTRUÇÃO CRANIANA OU CRANIOFACIAL',
    size: 0,
  },
  {
    id: 171,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'RESSECÇÃO DE OSSO TEMPORAL',
    size: 0,
  },
  {
    id: 172,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'RETIRADA DE PLACA DE CRANIOPLASTIA',
    size: 0,
  },
  {
    id: 173,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO CRÂNIO COM AFUNDAMENTO',
    size: 0,
  },
  {
    id: 174,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'TRATAMENTO CIRÚRGICO DE OSTEOMIELITE DO CRANIO',
    size: 0,
  },
  {
    id: 175,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'TRATAMENTO CIRÚRGICO PARA CRANIOSSINOSTOSE',
    size: 0,
  },
  {
    id: 176,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'TREPANAÇÃO CRANIANA PARA PROPEDÊUTICA NEUROCIRÚRGICA',
    size: 0,
  },
  {
    id: 177,
    general: 'CABEÇA/PESCOÇO',
    specific: 'CRÂNIO',
    name: 'TREPANAÇÃO CRANIANA PARA PUNÇÃO OU BIOPSIA',
    size: 0,
  },
  {
    id: 178,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'ANTROTOMIA DA MASTOIDE',
    size: 0,
  },
  {
    id: 179,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'BIOPSIA DE CONDUTO AUDITIVO EXTERNO',
    size: 0,
  },
  {
    id: 180,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'BIOPSIA DE PAVILHÃO AURICULAR',
    size: 0,
  },
  {
    id: 181,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'CIRURGIA PARA PRÓTESE AUDITIVA ANCORADA NO OSSO UNILATERAL',
    size: 0,
  },
  {
    id: 182,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'DRENAGEM DO SACO ENDO-LINFÁTICO ',
    size: 0,
  },
  {
    id: 183,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'ENXERTO TOTAL DO NERVO FACIAL INTRATEMPORAL',
    size: 0,
  },
  {
    id: 184,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'ESTAPEDECTOMIA OU ESTAPEDOTOMIA',
    size: 0,
  },
  {
    id: 185,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'EXPLORAÇÃO E DESCOMPRESSÃO DO NERVO FACIAL',
    size: 0,
  },
  {
    id: 186,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'IMPLANTE COCLEAR',
    size: 0,
  },
  {
    id: 187,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'LABIRINTECTOMIA (MEMBRANOSA OU OSSEA)',
    size: 0,
  },
  {
    id: 188,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'MASTOIDECTOMIA RADICAL',
    size: 1,
  },
  {
    id: 189,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'MASTOIDECTOMIA SUBTOTAL',
    size: 1,
  },
  {
    id: 190,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'MICROCIRURGIA OTOLÓGICA',
    size: 0,
  },
  {
    id: 191,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'MIRINGOTOMIA / TIMPANOPLASTIA',
    size: 0,
  },
  {
    id: 192,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'NEURECTOMIA VESTIBULAR',
    size: 0,
  },
  {
    id: 193,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'OTOPLASTIA',
    size: 0,
  },
  {
    id: 194,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'PARACENTESE DO TÍMPANO',
    size: 0,
  },
  {
    id: 195,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RECONSTRUÇÃO DE LÓBULO DA ORELHA',
    size: 0,
  },
  {
    id: 196,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RECONSTRUÇÃO DE POLO SUPERIOR / HÉLIX DA ORELHA',
    size: 0,
  },
  {
    id: 197,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RECONSTRUÇÃO TOTAL DE ORELHA',
    size: 0,
  },
  {
    id: 198,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RESSECÇÃO DE GLOMO JUGULAR',
    size: 1,
  },
  {
    id: 199,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RESSECÇÃO DE GLOMO TIMPÂNICO',
    size: 1,
  },
  {
    id: 200,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RESSECÇÃO DE PAVILHAO AURICULAR ',
    size: 0,
  },
  {
    id: 201,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RESSECÇÃO DE TUMOR DO ACÚSTICO',
    size: 0,
  },
  {
    id: 202,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RESSECÇÃO DE TUMOR DO NERVO ACÚSTICO',
    size: 0,
  },
  {
    id: 203,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'RESSECÇÃO DE TUMOR GLÔMICO ',
    size: 1,
  },
  {
    id: 204,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'TIMPANOMASTOIDECTOMIA',
    size: 1,
  },
  {
    id: 205,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'TIMPANOTOMIA PARA TUBO DE VENTILAÇÃO',
    size: 0,
  },
  {
    id: 206,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'TRATAMENTO CIRÚRGICO DE ESTENOSE DO CONDUTO AUDITIVO',
    size: 0,
  },
  {
    id: 207,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'TRATAMENTO CIRÚRGICO DO SINUS PRÉ-AURICULAR',
    size: 0,
  },
  {
    id: 208,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'TRATAMENTO CIRÚRGICO DOENÇA DE MENIERE - DESCOMPRESSÃO SACO ENDOLINFÁTICO',
    size: 0,
  },
  {
    id: 209,
    general: 'CABEÇA/PESCOÇO',
    specific: 'ORELHA',
    name: 'TRATAMENTO CIRÚRGICO NÃO ESTÉTICO DA ORELHA',
    size: 0,
  },
  {
    id: 210,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'ANTROSTOMIA DE MAXILA INTRANASAL',
    size: 0,
  },
  {
    id: 211,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'ANTROSTOMIA DE MAXILA INTRANASAL POR VIDEOENDOSCOPIA',
    size: 0,
  },
  {
    id: 212,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'BIOPSIA DE PIRÂMIDE NASAL',
    size: 0,
  },
  {
    id: 213,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'BIOPSIA DE SEIO PARANASAL',
    size: 0,
  },
  {
    id: 214,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'CAUTERIZAÇÃO CORNETO INFERIOR',
    size: 0,
  },
  {
    id: 215,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'DRENAGEM DE HEMATOMA OU ABSCESSO DE SEPTO NASAL',
    size: 0,
  },
  {
    id: 216,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'EPISTAXE - CAUTERIZAÇÃO DAS ARTÉRIAS ETMOIDAIS / ESFENOPALATINAS',
    size: 0,
  },
  {
    id: 217,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'EPISTAXE - LIGADURA DAS ARTÉRIAS ETMOIDAIS',
    size: 0,
  },
  {
    id: 218,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'ETMOIDECTOMIA',
    size: 0,
  },
  {
    id: 219,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'EXÉRESE DE LESÃO ENDOSCÓPICA FRONTAL',
    size: 0,
  },
  {
    id: 220,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'EXÉRESE DE TUMOR DE SEIOS PARANASAIS POR VIA ENDOSCÓPICA',
    size: 0,
  },
  {
    id: 221,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'INFILTRAÇÃO MEDICAMENTOSA EM CORNETO INFERIOR',
    size: 0,
  },
  {
    id: 222,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'RECONSTRUÇÃO TOTAL OU PARCIAL DE NARIZ',
    size: 0,
  },
  {
    id: 223,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'REDUÇÃO CIRÚRGICA DE FRATURA DOS OSSOS PRÓPRIOS DO NARIZ',
    size: 0,
  },
  {
    id: 224,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'REDUÇÃO CRUENTA DE FRATURA DOS OSSOS DO NARIZ     ',
    size: 0,
  },
  {
    id: 225,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'RESSECÇÃO DE TUMORES MALIGNOS TRANSNASAIS',
    size: 0,
  },
  {
    id: 226,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'RESSECÇÃO MUCOCELE FRONTAL',
    size: 0,
  },
  {
    id: 227,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'RINOPLASTIA ',
    size: 0,
  },
  {
    id: 228,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'RINOSEPTOPLASTIA ',
    size: 0,
  },
  {
    id: 229,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'SEPTOPLASTIA',
    size: 0,
  },
  {
    id: 230,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'SINUSECTOMIA FRONTOETMOIDAL',
    size: 0,
  },
  {
    id: 231,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'SINUSECTOMIA MAXILAR',
    size: 0,
  },
  {
    id: 232,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'SINUSOTOMIA BILATERAL',
    size: 0,
  },
  {
    id: 233,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'SINUSOTOMIA ESFENOIDAL',
    size: 0,
  },
  {
    id: 234,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'SINUSOTOMIA FRONTAL ',
    size: 0,
  },
  {
    id: 235,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'SINUSOTOMIA TRANSMAXILAR',
    size: 0,
  },
  {
    id: 236,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'TAMPONAMENTO NASAL ',
    size: 0,
  },
  {
    id: 237,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'TRATAMENTO CIRÚRGICO DE IMPERFURAÇÃO COANAL',
    size: 0,
  },
  {
    id: 238,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'TRATAMENTO CIRÚRGICO DE PERFURAÇÃO DO SEPTO NASAL',
    size: 0,
  },
  {
    id: 239,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'TRATAMENTO CIRÚRGICO DE RINOFIMA',
    size: 0,
  },
  {
    id: 240,
    general: 'CABEÇA/PESCOÇO',
    specific: 'NARIZ / SEIOS PARANASAIS',
    name: 'TURBINECTOMIA',
    size: 0,
  },
  {
    id: 241,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'BIOPSIA DE LESÃO DE PARTES MOLES',
    size: 0,
  },
  {
    id: 242,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'BIOPSIA DE LESÃO DE PARTES MOLES ',
    size: 0,
  },
  {
    id: 243,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'BIOPSIA TUMOR SUPERCIAL DE PELE',
    size: 0,
  },
  {
    id: 244,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'CIRURGIA DA HIDROSADENITE',
    size: 0,
  },
  {
    id: 245,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'CORREÇÃO DE RETRAÇÃO CICATRICIAL VÁRIOS ESTÁGIOS',
    size: 0,
  },
  {
    id: 246,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'CURATIVO COM OU SEM DEBRIDAMENTO',
    size: 0,
  },
  {
    id: 247,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'CURATIVO DE DEMORA COM OU SEM PREPARO BIOMECÂNICO',
    size: 0,
  },
  {
    id: 248,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'DEBRIDAMENTO DE ÚLCERA / TECIDOS DESVITALIZADO',
    size: 0,
  },
  {
    id: 249,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'DRENAGEM DE ABSCESSO ',
    size: 0,
  },
  {
    id: 250,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'DRENAGEM E/OU ASPIRAÇÃO DE SEROMA',
    size: 0,
  },
  {
    id: 251,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'ELETROCOAGULAÇÃO DE LESÕES DE PELE E MUCOSA',
    size: 0,
  },
  {
    id: 252,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'ENXERTO COMPOSTO',
    size: 0,
  },
  {
    id: 253,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'ENXERTO DERMO-EPIDÉRMICO',
    size: 0,
  },
  {
    id: 254,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXCISÃO DE LESÃO E/OU SUTURA DE FERIMENTO DA PELE ANEXOS E MUCOSA',
    size: 0,
  },
  {
    id: 255,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXCISÃO E ENXERTO DE PELE',
    size: 0,
  },
  {
    id: 256,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXCISÃO E SUTURA COM PLÁSTICA EM Z NA PELE ',
    size: 0,
  },
  {
    id: 257,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXCISÃO E SUTURA DE HEMANGIOMA',
    size: 0,
  },
  {
    id: 258,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXCISÃO E SUTURA DE LINFANGIOMA / NEVUS',
    size: 0,
  },
  {
    id: 259,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXÉRESE DE CISTO DERMOIDE',
    size: 0,
  },
  {
    id: 260,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXÉRESE DE CISTO SACRO-COCCÍGEO',
    size: 0,
  },
  {
    id: 261,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXÉRESE DE TUMOR DE PELE E ANEXOS',
    size: 0,
  },
  {
    id: 262,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXPANSOR TECIDUAL   ',
    size: 0,
  },
  {
    id: 263,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'EXTIRPAÇÃO MÚLTIPLA DE LESÃO DA PELE OU TECIDO CELULAR SUBCUTÂNEO',
    size: 0,
  },
  {
    id: 264,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'IMPLANTE CAPILAR',
    size: 0,
  },
  {
    id: 265,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'MOLDAGEM / IMPLANTE EM PELE / MUCOSA   ',
    size: 0,
  },
  {
    id: 266,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'PREPARO DE RETALHO',
    size: 0,
  },
  {
    id: 267,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'RECONSTRUÇÃO POR MICROCIRURGIA (QUALQUER PARTE)',
    size: 0,
  },
  {
    id: 268,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'RETIRADA DE CORPO ESTRANHO SUBCUTÂNEO',
    size: 0,
  },
  {
    id: 269,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRANSFERÊNCIA INTERMEDIARIA DE RETALHO',
    size: 0,
  },
  {
    id: 270,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRATAMENTO CIRÚRGICO ALOPÉCIA PARCIAL - ROTAÇÃO RETALHO',
    size: 0,
  },
  {
    id: 271,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRATAMENTO CIRÚRGICO DE LESÕES EXTENSAS C/ PERDA DE SUBSTÂNCIA CUTÂNEA',
    size: 0,
  },
  {
    id: 272,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRATAMENTO CIRÚRGICO DE RETRAÇÃO CICATRICIAL ',
    size: 0,
  },
  {
    id: 273,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRATAMENTO DA MIIASE FURUNCULOIDE',
    size: 0,
  },
  {
    id: 274,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRATAMENTO DE FÍSTULA CUTÂNEA',
    size: 0,
  },
  {
    id: 275,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRATAMENTO DE GRANDE QUEIMADO',
    size: 1,
  },
  {
    id: 276,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'TRATAMENTO DE MÉDIO QUEIMADO',
    size: 0,
  },
  {
    id: 277,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'CUTÂNEO',
    name: 'ZETAPLASTIA',
    size: 0,
  },
  {
    id: 278,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'ALONGAMENTO / ENCURTAMENTO MIOTENDINOSO',
    size: 0,
  },
  {
    id: 279,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'AUTONOMIZAÇÃO DE RETALHO',
    size: 0,
  },
  {
    id: 280,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'BIOPSIA DE MÚSCULO',
    size: 0,
  },
  {
    id: 281,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'DEBRIDAMENTO DE FASCEITE NECROTIZANTE',
    size: 0,
  },
  {
    id: 282,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'DEBRIDAMENTO DE ÚLCERA / NECROSE',
    size: 0,
  },
  {
    id: 283,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'DRENAGEM CIRÚRGICA DO ILIOPSOAS',
    size: 0,
  },
  {
    id: 284,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'EXÉRESE DE CISTO SACRO-COCCIGEO',
    size: 0,
  },
  {
    id: 285,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'FASCIECTOMIA     ',
    size: 0,
  },
  {
    id: 286,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'FASCIOTOMIA PARA DESCOMPRESSÃO',
    size: 0,
  },
  {
    id: 287,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'LIPOENXERTIA DE GLÚTEO ',
    size: 0,
  },
  {
    id: 288,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'RECONSTRUÇÃO COM RETALHO MIOCUTÂNEO (QUALQUER PARTE)',
    size: 0,
  },
  {
    id: 289,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'RECONSTRUÇÃO COM RETALHO OSTEOMIOCUTÂNEO',
    size: 0,
  },
  {
    id: 290,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'REINSERÇÃO MUSCULAR',
    size: 0,
  },
  {
    id: 291,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'REPARO DE ROTURA DO MANGUITO ROTADOR',
    size: 0,
  },
  {
    id: 292,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'RESSECÇÃO AMPLIADA DE TUMORES DE PARTES MOLES',
    size: 0,
  },
  {
    id: 293,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'RESSECÇÃO DE PARTES MOLES DAS EXTREMIDADES C/ RECONSTRUÇÃO ',
    size: 0,
  },
  {
    id: 294,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'RESSECÇÃO DE TUMOR DE PARTES MOLES ',
    size: 0,
  },
  {
    id: 295,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'RESSECÇÃO DE TUMOR E RECONSTRUÇÃO C/ RETALHO OU ENXERTO',
    size: 0,
  },
  {
    id: 296,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'RESSECÇÃO MUSCULAR',
    size: 0,
  },
  {
    id: 297,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'TRANSPOSIÇÃO / ALONGAMENTO MIOTENDINOSO DO ILIOPSOAS EM DOENCA NEUROMUSCULAR',
    size: 0,
  },
  {
    id: 298,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'MÚSCULO-CUTÂNEO',
    name: 'TRATAMENTO CIRÚRGICO DE ESCARA OU ULCERAÇÕES COM RETALHO MIOCUTÂNEO',
    size: 0,
  },
  {
    id: 299,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTROCENTESE',
    size: 0,
  },
  {
    id: 300,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTRODESE AO NÍVEL DO OMBRO',
    size: 0,
  },
  {
    id: 301,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTRODESE DE GRANDES ARTICULACOES ESCÁPULO-TORACICAS',
    size: 0,
  },
  {
    id: 302,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTRODESE DE GRANDES ARTICULACOES ESCÁPULO-UMERAIS',
    size: 0,
  },
  {
    id: 303,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTRODESE DE MÉDIAS / GRANDES ARTICULAÇÕES DE MEMBRO INFERIOR',
    size: 0,
  },
  {
    id: 304,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTRODESE DE MÉDIAS / GRANDES ARTICULAÇÕES DE MEMBRO SUPERIOR',
    size: 0,
  },
  {
    id: 305,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTROPLASTIA ESCÁPULO-UMERAL ',
    size: 0,
  },
  {
    id: 306,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'ARTROSCOPIA ',
    size: 0,
  },
  {
    id: 307,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'DESARTICULAÇÃO ESCÁPULO-TORÁCICA INTERNA ',
    size: 1,
  },
  {
    id: 308,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'EXPLORAÇÃO ARTICULAR DE MEDIAS / GRANDES ARTICULAÇÕES',
    size: 0,
  },
  {
    id: 309,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'EXPLORAÇÃO ARTICULAR DE PEQUENAS ARTICULAÇÕES',
    size: 0,
  },
  {
    id: 310,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'MANIPULAÇÃO ARTICULAR',
    size: 0,
  },
  {
    id: 311,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'PRÓTESES DE SUBSTITUIÇÃO DE PEQUENAS ARTICULAÇÕES',
    size: 0,
  },
  {
    id: 312,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'RETIRADA DE CORPO ESTRANHO INTRA-ARTICULAR',
    size: 0,
  },
  {
    id: 313,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'RETIRADA DE PRÓTESE DE SUBSTITUIÇÃO DE GRANDES ARTICULAÇÕES (OMBRO / COTOVELO / QUADRIL / JOELHO)',
    size: 0,
  },
  {
    id: 314,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'RETIRADA DE PRÓTESE DE SUBSTITUIÇÃO EM PEQUENAS E MÉDIAS ARTICULAÇÕES',
    size: 0,
  },
  {
    id: 315,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'TRATAMENTO CIRÚRGICO DE ARTRITE INFECCIOSA ',
    size: 0,
  },
  {
    id: 316,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'TRATAMENTO CIRÚRGICO DE DEFORMIDADE ARTICULAR ',
    size: 0,
  },
  {
    id: 317,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'ARTICULAÇÕES',
    name: 'VIDEOARTROSCOPIA',
    size: 0,
  },
  {
    id: 318,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'ALONGAMENTO DOS OSSOS DO ANTEBRAÇO COM FIXADOR EXTERNO',
    size: 0,
  },
  {
    id: 319,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'AMPUTAÇÃO / DESARTICULAÇÃO DE DEDO',
    size: 0,
  },
  {
    id: 320,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'AMPUTAÇÃO DE MEMBROS SUPERIORES',
    size: 0,
  },
  {
    id: 321,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'ARTRODESE DE PEQUENAS ARTICULAÇÕES',
    size: 0,
  },
  {
    id: 322,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'ARTROPLASTIA DE CABEÇA DO RÁDIO',
    size: 0,
  },
  {
    id: 323,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'ARTROPLASTIA DE RESSECÇÃO DE MÉDIA / GRANDE ARTICULAÇÃO',
    size: 0,
  },
  {
    id: 324,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'ARTROPLASTIA DE RESSECÇÃO DE PEQUENAS ARTICULAÇÕES    ',
    size: 0,
  },
  {
    id: 325,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'DERMOLIPECTOMIA BRAQUIAL - BRAQUIOPLASTIA ',
    size: 0,
  },
  {
    id: 326,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'DESARTICULAÇÃO DA ARTICULAÇÃO ESCÁPULO-UMERAL',
    size: 1,
  },
  {
    id: 327,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'FASCIOTOMIA DE MEMBROS SUPERIORES',
    size: 0,
  },
  {
    id: 328,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'INSTALAÇÃO DE TRAÇÃO ESQUELÉTICA DO MEMBRO SUPERIOR',
    size: 0,
  },
  {
    id: 329,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'OSTEOMIELITE OSSOS DO BRAÇO / ANTEBRAÇO - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 330,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'REIMPLANTE DO OMBRO ATÉ O TERÇO MÉDIO DO ANTEBRAÇO / LIMPEZA CIRÚRGICA OMBRO ',
    size: 0,
  },
  {
    id: 331,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'RESSECÇÃO DO OLÉCRANO E/OU CABEÇA DO RÁDIO',
    size: 0,
  },
  {
    id: 332,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TENOSINOVECTOMIA EM MEMBRO SUPERIOR',
    size: 0,
  },
  {
    id: 333,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TRANSPOSIÇÃO DA ULNA PARA O RÁDIO',
    size: 0,
  },
  {
    id: 334,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA  DO ÚMERO',
    size: 0,
  },
  {
    id: 335,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA / LESÃO FISÁRIA DA EXTREMIDADE PROXIMAL DO ÚMERO ',
    size: 0,
  },
  {
    id: 336,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DA EXTREMIDADE / METÁFISE DISTAL DOS OSSOS DO ANTEBRAÇO ',
    size: 0,
  },
  {
    id: 337,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DA ULNA / RÁDIO',
    size: 0,
  },
  {
    id: 338,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DOS OSSOS DO ANTEBRACO',
    size: 0,
  },
  {
    id: 339,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'BRAÇO / ANTEBRAÇO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA-LUXAÇÃO DE GALEAZZI / MONTEGGIA / ESSEX-LOPRESTI',
    size: 0,
  },
  {
    id: 340,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'AMPUTAÇÃO / DESARTICULAÇÃO DE MÃO E PUNHO',
    size: 0,
  },
  {
    id: 341,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'ARTRODESE ENTRE OS OSSOS DO CARPO',
    size: 0,
  },
  {
    id: 342,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'ARTROPLASTIA DE ARTICULAÇÃO DA MÃO',
    size: 0,
  },
  {
    id: 343,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'ARTROPLASTIA DE PUNHO',
    size: 0,
  },
  {
    id: 344,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'ARTROPLASTIA TOTAL DE COTOVELO',
    size: 0,
  },
  {
    id: 345,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'ARTROTOMIA DE COTOVELO - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 346,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'EXPLORAÇÃO CIRÚRGICA DE TENDÃO DE MÃO',
    size: 0,
  },
  {
    id: 347,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'LESÕES LIGAMENTARES DE COTOVELO',
    size: 0,
  },
  {
    id: 348,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'OSTECTOMIA DE OSSOS DA MÃO E/OU DO PÉ',
    size: 0,
  },
  {
    id: 349,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'OSTEOMIELITE OSSOS AO NÍVEL DA MÃO - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 350,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'REALINHAMENTO DE MECANISMO EXTENSOR DOS DEDOS DA MÃO',
    size: 0,
  },
  {
    id: 351,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'RECONSTRUÇÃO CÁPSULO-LIGAMENTAR DE COTOVELO PUNHO',
    size: 0,
  },
  {
    id: 352,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'RECONSTRUÇÃO DE POLIA TENDINOSA DOS DEDOS DA MÃO',
    size: 0,
  },
  {
    id: 353,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'REDUÇÃO CIRÚRGICA DE OSSOS DO CARPO',
    size: 0,
  },
  {
    id: 354,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'REIMPLANTE DE DEDO MÃO ',
    size: 0,
  },
  {
    id: 355,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'RETRAÇÃO DE APONEUROSE PALMAR (DUPUYTREN)',
    size: 0,
  },
  {
    id: 356,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'REVISÃO CIRÚRGICA DE COTO DE AMPUTAÇÃO DOS DEDOS',
    size: 0,
  },
  {
    id: 357,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TENOSSINOVECTOMIA DE MÃO OU PUNHO',
    size: 0,
  },
  {
    id: 358,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRANSPLANTE DE DEDOS',
    size: 0,
  },
  {
    id: 359,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE DEDO EM GATILHO',
    size: 0,
  },
  {
    id: 360,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE DEDO EM MARTELO ',
    size: 0,
  },
  {
    id: 361,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DAS FALANGES DA MÃO',
    size: 0,
  },
  {
    id: 362,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURAS E/OU LUXAÇÕES DO PUNHO',
    size: 0,
  },
  {
    id: 363,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE LESÃO AGUDA CÁPSULO-LIGAMENTAR DO MEMBRO SUPERIOR: COTOVELO / PUNHO',
    size: 0,
  },
  {
    id: 364,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE LESÃO DA MUSCULATURA INTRÍNSECA DA MÃO',
    size: 0,
  },
  {
    id: 365,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE LUXAÇÃO / FRATURA DO COTOVELO',
    size: 0,
  },
  {
    id: 366,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE POLIDACTILIA',
    size: 0,
  },
  {
    id: 367,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE ROTURA / DESINSERÇÃO / ARRANCAMENTO CÁPSULO-TENO-LIGAMENTAR NA MÃO',
    size: 0,
  },
  {
    id: 368,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE ROTURA LIGAMENTAR NA MÃO',
    size: 0,
  },
  {
    id: 369,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE RUTURA DO APARELHO EXTENSOR DO DEDO',
    size: 0,
  },
  {
    id: 370,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE SINDACTILIA DA MÃO ',
    size: 0,
  },
  {
    id: 371,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO DE SINDROME COMPRESSIVA EM TÚNEL OSTEO-FIBROSO AO NÍVEL DO CARPO',
    size: 0,
  },
  {
    id: 372,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TRATAMENTO CIRÚRGICO P/ REPARAÇÕES DE PERDA DE SUBSTÂNCIA DA MÃO',
    size: 0,
  },
  {
    id: 373,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COTOVELO / PUNHO / MÃO',
    name: 'TÚNEL DO CARPO - DESCOMPRESSÃO',
    size: 0,
  },
  {
    id: 374,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'AMPUTAÇÃO AO NÍVEL DA COXA',
    size: 0,
  },
  {
    id: 375,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ARTRODESE COXOFEMORAL',
    size: 0,
  },
  {
    id: 376,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ARTRODESE DA SÍNFISE PÚBICA',
    size: 1,
  },
  {
    id: 377,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ARTRODESE DE ARTICULAÇÕES SACROILÍACAS',
    size: 0,
  },
  {
    id: 378,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ARTROPLASTIA DE QUADRIL ',
    size: 0,
  },
  {
    id: 379,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ARTROPLASTIA DE REVISÃO OU RECONSTRUÇÃO DO QUADRIL',
    size: 1,
  },
  {
    id: 380,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ARTROPLASTIA PARCIAL DE QUADRIL',
    size: 0,
  },
  {
    id: 381,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ARTROPLASTIA TOTAL PRIMÁRIA DE QUADRIL',
    size: 0,
  },
  {
    id: 382,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'BIOPSIA CIRÚRGICA DE FÊMUR',
    size: 0,
  },
  {
    id: 383,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'CANTOPLASTIA UNGUEAL',
    size: 0,
  },
  {
    id: 384,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'DERMOLIPECTOMIA DOS MEMBROS INFERIORES - COXOPLASTIA PÓS-BARIÁTRICA',
    size: 0,
  },
  {
    id: 385,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'DESARTICULAÇÃO COXOFEMORAL',
    size: 1,
  },
  {
    id: 386,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'DESARTICULAÇÃO INTERILIO-ABDOMINAL',
    size: 1,
  },
  {
    id: 387,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'ENCURTAMENTO DO FÊMUR - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 388,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'EPIFISIODESE DO TROCANTER MAIOR DO FÊMUR',
    size: 0,
  },
  {
    id: 389,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'LESÃO LABRAL',
    size: 0,
  },
  {
    id: 390,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'OSTEOMIELITE AO NÍVEL DA PELVE - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 391,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'OSTEOTOMIA DA PELVE',
    size: 0,
  },
  {
    id: 392,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'RECONSTRUÇÃO OSTEOPLÁSTICA DO QUADRIL',
    size: 0,
  },
  {
    id: 393,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'RETIRADA DE ENXERTO AUTOGENO DE ILÍACO',
    size: 0,
  },
  {
    id: 394,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DA AVULSÃO DE TUBEROSIDADES E CRISTA ILIACA S/ LESÃO DO ANEL PÉLVICO',
    size: 0,
  },
  {
    id: 395,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DE AVULSÃO DO TROCANTER',
    size: 0,
  },
  {
    id: 396,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DE DISJUNÇÃO DO ANEL PÉLVICO',
    size: 1,
  },
  {
    id: 397,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA / LESÃO FISARIA PROXIMAL (COLO) DO FÊMUR ',
    size: 0,
  },
  {
    id: 398,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA / LUXAÇÃO COXOFEMORAL ',
    size: 0,
  },
  {
    id: 399,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO ACETÁBULO',
    size: 0,
  },
  {
    id: 400,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA TROCANTERIANA',
    size: 0,
  },
  {
    id: 401,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO DE LUXAÇÃO COXO-FEMORAL TRAUMATICA / POS-ARTROPLASTIA',
    size: 0,
  },
  {
    id: 402,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COXA / FÊMUR / PELVE / QUADRIL',
    name: 'TRATAMENTO CIRÚRGICO FRATURA DE FÊMUR',
    size: 0,
  },
  {
    id: 403,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'ALONGAMENTO / ENCURTAMENTO DOS OSSOS DA PERNA - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 404,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'AMPUTAÇÃO  DE MEMBROS INFERIORES',
    size: 0,
  },
  {
    id: 405,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'ARTRODESE DE JOELHO - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 406,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'ARTROPLASTIA DE JOELHO ',
    size: 0,
  },
  {
    id: 407,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'ARTROPLASTIA TOTAL PRIMÁRIA DO JOELHO',
    size: 0,
  },
  {
    id: 408,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'CONDROPLASTIA ',
    size: 0,
  },
  {
    id: 409,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'FASCIOTOMIA DE MEMBROS INFERIORES',
    size: 0,
  },
  {
    id: 410,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'FRATURAS E/OU LUXAÇOES AO NÍVEL DO JOELHO - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 411,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'HASTE INTRAMEDULAR BLOQUEADA DE FÊMUR ',
    size: 0,
  },
  {
    id: 412,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'HASTE INTRAMEDULAR BLOQUEADA DE TÍBIA ',
    size: 0,
  },
  {
    id: 413,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'INSTALAÇÃO DE TRAÇÃO ESQUELÉTICA DO MEMBRO INFERIOR',
    size: 0,
  },
  {
    id: 414,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'LESÕES LIGAMENTARES DE JOELHO',
    size: 0,
  },
  {
    id: 415,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'OSTEOMIELITE OSSOS DA PERNA - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 416,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'OSTEOTOMIA AO NÍVEL DO JOELHO',
    size: 0,
  },
  {
    id: 417,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'PATELECTOMIA TOTAL OU PARCIAL',
    size: 0,
  },
  {
    id: 418,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'REALINHAMENTO DO MECANISMO EXTENSOR DO JOELHO',
    size: 0,
  },
  {
    id: 419,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'RECONSTRUÇÃO DE TENDAO PATELAR / TENDAO QUADRICIPITAL',
    size: 0,
  },
  {
    id: 420,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'REVISÃO CIRÚRGICA DE COTO DE AMPUTAÇÃO EM MEMBRO INFERIOR',
    size: 0,
  },
  {
    id: 421,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TENOSINOVECTOMIA EM MEMBRO INFERIOR',
    size: 0,
  },
  {
    id: 422,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRANSFERÊNCIA MUSCULAR / TENDINOSA NO MEMBRO INFERIOR',
    size: 0,
  },
  {
    id: 423,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRANSPLANTE DE MENISCO',
    size: 0,
  },
  {
    id: 424,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRANSPOSIÇÃO DA FÍBULA PARA A TÍBIA',
    size: 0,
  },
  {
    id: 425,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DA PATELA POR FIXAÇÃO INTERNA',
    size: 0,
  },
  {
    id: 426,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DA TÍBIA',
    size: 0,
  },
  {
    id: 427,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO FÊMUR',
    size: 0,
  },
  {
    id: 428,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO PILÃO TIBIAL ',
    size: 0,
  },
  {
    id: 429,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRATAMENTO CIRÚRGICO DE LESÃO AGUDA CÁPSULO-LIGAMENTAR MEMBRO INFERIOR ',
    size: 0,
  },
  {
    id: 430,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRATAMENTO CIRÚRGICO DE PSEUDARTROSE  DA TIBIA',
    size: 0,
  },
  {
    id: 431,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'JOELHO / PERNA',
    name: 'TRATAMENTO CIRÚRGICO DE ROTURA DE MENISCO ',
    size: 0,
  },
  {
    id: 432,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'AMPUTAÇÃO AO NÍVEL DO TORNOZELO ',
    size: 0,
  },
  {
    id: 433,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'AMPUTAÇÃO DÉ PE E TARSO',
    size: 0,
  },
  {
    id: 434,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'AMPUTAÇÃO DE PODODÁCTILOS',
    size: 0,
  },
  {
    id: 435,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'ARTRODESE AO NÍVEL DO TORNOZELO',
    size: 0,
  },
  {
    id: 436,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'ARTRODESE DE TARSO E/OU MÉDIO PÉ',
    size: 0,
  },
  {
    id: 437,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'ARTROPLASTIA DE TORNOZELO',
    size: 0,
  },
  {
    id: 438,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'FASCIOTOMIA OU RESSECÇÃO DE FÁSCIA PLANTAR',
    size: 0,
  },
  {
    id: 439,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'OSTEOMIELITE AO NÍVEL OSSOS DO PÉ - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 440,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'RECONSTRUÇÃO LIGAMENTAR DO TORNOZELO',
    size: 0,
  },
  {
    id: 441,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'REPARO DE BAINHA TENDINOSA AO NÍVEL DO TORNOZELO',
    size: 0,
  },
  {
    id: 442,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'RESSECÇÃO DE OSSOS DO PÉ',
    size: 0,
  },
  {
    id: 443,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'REVISÃO CIRÚRGICA DO PÉ TORTO CONGÊNITO',
    size: 0,
  },
  {
    id: 444,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TALECTOMIA',
    size: 0,
  },
  {
    id: 445,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE COALIZÃO TARSAL',
    size: 0,
  },
  {
    id: 446,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA / LESÃO FISÁRIA DE OSSOS DO MÉDIO-PÉ',
    size: 0,
  },
  {
    id: 447,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA / LESÃO FISARIA DOS METACARPIANOS',
    size: 0,
  },
  {
    id: 448,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DE OSSOS DO PÉ',
    size: 0,
  },
  {
    id: 449,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO CALCÂNEO',
    size: 0,
  },
  {
    id: 450,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO TALUS',
    size: 0,
  },
  {
    id: 451,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO TORNOZELO UNIMALEOLAR ',
    size: 0,
  },
  {
    id: 452,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA E/OU LUXAÇÃO DO TORNOZELO ',
    size: 0,
  },
  {
    id: 453,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE HALUX VALGUS ',
    size: 0,
  },
  {
    id: 454,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE PÉ CAVO',
    size: 0,
  },
  {
    id: 455,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE PÉ PLANO VALGO',
    size: 0,
  },
  {
    id: 456,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DE PÉ TORTO CONGENITO',
    size: 0,
  },
  {
    id: 457,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO DO HALUX RIGIDUS',
    size: 0,
  },
  {
    id: 458,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO MAL PERFURANTE PLANTAR',
    size: 0,
  },
  {
    id: 459,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO OSTEOCONDRITE DE TORNOZELO',
    size: 0,
  },
  {
    id: 460,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO CIRÚRGICO ROTURA TENDÃO DE AQUILES',
    size: 0,
  },
  {
    id: 461,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TORNOZELO / PÉ',
    name: 'TRATAMENTO DE FRATURA BIMALEOLAR / TRIMALEOLAR / DA FRATURA-LUXAÇÃO DO TORNOZELO ',
    size: 0,
  },
  {
    id: 462,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE CERVICAL / CÉRVICO TORACICA POSTERIOR CINCO OU MAIS NÍVEIS',
    size: 1,
  },
  {
    id: 463,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE CERVICAL / CÉRVICO-TORACICA POSTERIOR ATÉ QUATRO NÍVEIS',
    size: 0,
  },
  {
    id: 464,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE CERVICAL ANTERIOR ',
    size: 0,
  },
  {
    id: 465,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE CERVICAL ANTERIOR C1-C2 VIA TRANS-ORAL',
    size: 0,
  },
  {
    id: 466,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE OCCIPTO-CERVICAL POSTERIOR',
    size: 0,
  },
  {
    id: 467,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE TÓRACO-LOMBO-SACRA ANTERIOR ',
    size: 1,
  },
  {
    id: 468,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE TÓRACO-LOMBO-SACRA POSTERIOR ATÉ TRÊS NÍVEIS',
    size: 0,
  },
  {
    id: 469,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'ARTRODESE TÓRACO-LOMBO-SACRA POSTERIOR QUATRO OU MAIS NÍVEIS',
    size: 1,
  },
  {
    id: 470,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'BIOPSIA DE CORPO VERTEBRAL A CÉU ABERTO',
    size: 0,
  },
  {
    id: 471,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'CIRURGIA DE COLUNA POR VIA ENDOSCÓPICA',
    size: 0,
  },
  {
    id: 472,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'DESCOMPRESSÃO COM ESVAZIAMENTO MEDULAR POR BROCAGEM ',
    size: 1,
  },
  {
    id: 473,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'DESCOMPRESSÃO DA JUNÇÃO CRÂNIO-CERVICAL ',
    size: 0,
  },
  {
    id: 474,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'DISCECTOMIA CERVICAL / LOMBAR / LOMBO-SACRA POR VIA POSTERIOR (ATÉ DOIS NÍVEIS)',
    size: 0,
  },
  {
    id: 475,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'DISCECTOMIA CERVICAL / LOMBAR / LOMBO-SACRA POR VIA POSTERIOR (TRÊS OU MAIS NÍVEIS)',
    size: 1,
  },
  {
    id: 476,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'DISCECTOMIA CERVICAL POR VIA ANTERIOR ',
    size: 0,
  },
  {
    id: 477,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'DISCECTOMIA TÓRACO-LOMBO-SACRA POR VIA ANTERIOR ',
    size: 1,
  },
  {
    id: 478,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'EXÉRESE DE TERATOMA SACRO-COCCÍGEO',
    size: 0,
  },
  {
    id: 479,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'LAMINECTOMIA OU LAMINOTOMIA',
    size: 0,
  },
  {
    id: 480,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RESSECAO DE COCCIX',
    size: 0,
  },
  {
    id: 481,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RESSECÇÃO DE 2 OU MAIS CORPOS VERTEBRAIS TÓRACO-LOMBO-SACROS',
    size: 0,
  },
  {
    id: 482,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RESSECÇÃO DE ELEMENTO VERTEBRAL POSTERIOR ',
    size: 0,
  },
  {
    id: 483,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RESSECÇÃO DE ELEMENTO VERTEBRAL POSTERIOR / POSTERO-LATERAL  (ATÉ 2 SEGMENTOS)',
    size: 0,
  },
  {
    id: 484,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RESSECÇÃO DE UM CORPO VERTEBRAL CERVICAL',
    size: 0,
  },
  {
    id: 485,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RESSECÇÃO DE UM CORPO VERTEBRAL TÓRACO-LOMBO-SACRO',
    size: 1,
  },
  {
    id: 486,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RETIRADA DE CORPO ESTRANHO DA COLUNA CERVICAL      ',
    size: 0,
  },
  {
    id: 487,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RETIRADA DE CORPO ESTRANHO DA COLUNA TÓRACO-LOMBO-SACRA POR VIA ANTERIOR',
    size: 1,
  },
  {
    id: 488,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'RETIRADA DE CORPO ESTRANHO DA COLUNA TÓRACO-LOMBO-SACRA POR VIA POSTERIOR',
    size: 0,
  },
  {
    id: 489,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'REVISÃO DE ARTRODESE / TRATAMENTO CIRÚRGICO DE PSEUDARTOSE DA COLUNA TÓRACO-LOMBO-SACRA ANTERIOR',
    size: 1,
  },
  {
    id: 490,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'REVISÃO DE ARTRODESE / TRATAMENTO CIRÚRGICO DE PSEUDARTROSE DA COLUNA CERVICAL POSTERIOR ',
    size: 0,
  },
  {
    id: 491,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'REVISAO DE ARTRODESE / TRATAMENTO CIRÚRGICO DE PSEUDARTROSE DA COLUNA TÓRACO-LOMBO-SACRA POSTERIOR',
    size: 1,
  },
  {
    id: 492,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'REVISAO DE ARTRODESE TRATAMENTO CIRÚRGICO DE PSEUDOARTORSE DA COLUNA CERVICAL ANTERIOR',
    size: 0,
  },
  {
    id: 493,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TORACOTOMIA PARA PROCEDIMENTOS ORTOPÉDICOS SOBRE A COLUNA VERTEBRAL',
    size: 1,
  },
  {
    id: 494,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO CISTO SACRO-COCCÍGEO',
    size: 0,
  },
  {
    id: 495,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE DEFORMIDADE DA COLUNA VIA ANTERIOR ',
    size: 1,
  },
  {
    id: 496,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE DEFORMIDADE DA COLUNA VIA POSTERIOR ATÉ TRÊS NÍVEIS      ',
    size: 0,
  },
  {
    id: 497,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE DEFORMIDADE DA COLUNA VIA POSTERIOR QUATRO OU MAIS NÍVEIS',
    size: 1,
  },
  {
    id: 498,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE ESPONDILOLISTESE',
    size: 0,
  },
  {
    id: 499,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO CÓCCIX',
    size: 0,
  },
  {
    id: 500,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO SACRO',
    size: 0,
  },
  {
    id: 501,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA NÍVEL C1 - C2 ',
    size: 0,
  },
  {
    id: 502,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE HÉRNIA DE DISCO',
    size: 0,
  },
  {
    id: 503,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DE TUMOR ÓSSEO VERTEBRAL (RESSECÇÃO COM SUBSTITUIÇÃO)',
    size: 1,
  },
  {
    id: 504,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO DO DISRAFISMO',
    size: 0,
  },
  {
    id: 505,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO CIRÚRGICO OSTEOMIELITE COLUNA ',
    size: 0,
  },
  {
    id: 506,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'TRATAMENTO DE FRATURA COLUNA VERTEBRAL COM LESÃO DA MEDULA ESPINHAL ',
    size: 1,
  },
  {
    id: 507,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'COLUNA',
    name: 'VERTEBROPLASTIA EM UM NÍVEL',
    size: 0,
  },
  {
    id: 508,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'ACROMIOPLASTIA',
    size: 0,
  },
  {
    id: 509,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'ALONGAMENTO E/OU TRANSPORTE ÓSSEO DE OSSOS LONGOS',
    size: 0,
  },
  {
    id: 510,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'BIOPSIA DE OSSO/CARTILAGEM DA CINTURA ESCAPULAR OU PÉLVICA',
    size: 0,
  },
  {
    id: 511,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'BIOPSIA DE OSSO/CARTILAGEM DE MEMBRO SUPERIOR OU INFERIOR',
    size: 0,
  },
  {
    id: 512,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'COLOCAÇÃO DE FIOS, PINOS, PARAFUSOS OU HASTES METÁLICAS INTRA-ÓSSEAS',
    size: 0,
  },
  {
    id: 513,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'FIXADOR EXTERNO CIRCULAR / SEMI-CIRCULAR  ',
    size: 0,
  },
  {
    id: 514,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'FIXADOR EXTERNO LINEAR',
    size: 0,
  },
  {
    id: 515,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'OSTECTOMIA DA CLAVÍCULA OU DA ESCAPULA',
    size: 0,
  },
  {
    id: 516,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'OSTECTOMIA DE OSSOS LONGOS ',
    size: 0,
  },
  {
    id: 517,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'OSTEOMIELITE AO NÍVEL CINTURA ESCAPULAR - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 518,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'OSTEOTOMIA DA CLAVÍCULA OU DA ESCAPULA',
    size: 0,
  },
  {
    id: 519,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'OSTEOTOMIA DE OSSOS LONGOS ',
    size: 0,
  },
  {
    id: 520,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'OSTEOTOMIA DE OSSOS LONGOS E CURTOS DA MÃO E DO PE',
    size: 0,
  },
  {
    id: 521,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RESSECÇÃO DE EXOSTOSE',
    size: 0,
  },
  {
    id: 522,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RESSECÇÃO DE TUMOR E RECONSTRUÇÃO C/ TRANSPORTE ÓSSEO',
    size: 0,
  },
  {
    id: 523,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RESSECÇÃO DE TUMOR ÓSSEO C/ SUBSTITUIÇÃO (ENDOPRÓTESE)',
    size: 0,
  },
  {
    id: 524,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RESSECÇÃO LATERAL DA CLAVÍCULA',
    size: 0,
  },
  {
    id: 525,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RESSECÇÃO SIMPLES DE TUMOR ÓSSEO / DE PARTES MOLES',
    size: 0,
  },
  {
    id: 526,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RETIRADA DE CORPO ESTRANHO INTRA-ÓSSEO',
    size: 0,
  },
  {
    id: 527,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RETIRADA DE ESPAÇADORES / OUTROS MATERIAIS',
    size: 0,
  },
  {
    id: 528,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RETIRADA DE FIO OU PINO INTRA-ÓSSEO',
    size: 0,
  },
  {
    id: 529,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RETIRADA DE FIXADOR EXTERNO',
    size: 0,
  },
  {
    id: 530,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'RETIRADA DE PLACA E/OU PARAFUSOS',
    size: 0,
  },
  {
    id: 531,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DA CLAVÍCULA',
    size: 0,
  },
  {
    id: 532,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO COLO E CAVIDADE GLENOIDE DE ESCAPULA',
    size: 0,
  },
  {
    id: 533,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA DO CORPO DE ESCÁPULA',
    size: 0,
  },
  {
    id: 534,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA VICIOSAMENTE CONSOLIDADA DOS OSSOS LONGOS ',
    size: 0,
  },
  {
    id: 535,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE LUXAÇÃO / FRATURA-LUXAÇÃO ACROMIO-CLAVÍCULAR',
    size: 0,
  },
  {
    id: 536,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE LUXAÇÃO / FRATURA-LUXAÇÃO ESCAPULO-UMERAL AGUDA',
    size: 0,
  },
  {
    id: 537,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE LUXAÇÃO / FRATURA-LUXAÇÃO ESTERNO-CLAVÍCULAR',
    size: 0,
  },
  {
    id: 538,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE LUXAÇÃO RECIDIVANTE / HABITUAL DE ARTICULAÇÃO ESCAPULO-UMERAL',
    size: 0,
  },
  {
    id: 539,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE LUXAÇÕES / ARTRODESE / CONTRATURAS COM FIXADOR EXTERNO',
    size: 0,
  },
  {
    id: 540,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO DE RETARDO DE CONSOLIDAÇÃO DA PSEUDARTROSE DE CLAVÍCULA / ESCÁPULA',
    size: 0,
  },
  {
    id: 541,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO CIRÚRGICO EM POLITRAUMATIZADO',
    size: 1,
  },
  {
    id: 542,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'OSSOS',
    name: 'TRATAMENTO DAS MALFORMAÇÕES/DEFORMIDADES DO SISTEMA OSTEOMUSCULAR',
    size: 0,
  },
  {
    id: 543,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'ALONGAMENTO / ENCURTAMENTO DE TENDÃO',
    size: 0,
  },
  {
    id: 544,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'BIOPSIA CIRÚRGICA DE TENDÕES, BURSAS E SINÓVIAS',
    size: 0,
  },
  {
    id: 545,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'BURSECTOMIA',
    size: 0,
  },
  {
    id: 546,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'RECONSTRUÇÃO DE POLIA TENDINOSA DE MÃO',
    size: 0,
  },
  {
    id: 547,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'RECONSTRUÇÃO DE TENDÃO PATELAR / TENDÃO QUADRICIPITAL ',
    size: 0,
  },
  {
    id: 548,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'RECONSTRUÇÃO LIGAMENTAR INTRA-ARTICULAR DO JOELHO ',
    size: 0,
  },
  {
    id: 549,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'RESSECÇÃO DE CISTO SINOVIAL',
    size: 0,
  },
  {
    id: 550,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'SINOVECTOMIA TOTAL OU PARCIAL',
    size: 0,
  },
  {
    id: 551,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TENODESE',
    size: 0,
  },
  {
    id: 552,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TENÓLISE',
    size: 0,
  },
  {
    id: 553,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TENOMIORRAFIA',
    size: 0,
  },
  {
    id: 554,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TENOMIOTOMIA / DESINSERÇÃO',
    size: 0,
  },
  {
    id: 555,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TENOPLASTIA OU ENXERTO DE TENDÃO UNICO',
    size: 0,
  },
  {
    id: 556,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TENOSSINOVITE INFECCIOSA - DRENAGEM',
    size: 0,
  },
  {
    id: 557,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TRANSPOSIÇÃO / TRANSFERÊNCIA MIOTENDINOSA',
    size: 0,
  },
  {
    id: 558,
    general: 'SISTEMA MÚSCULO-ESQUELÉTICO ',
    specific: 'TENDÕES/BURSAS E SINÓVIAS',
    name: 'TUMORES DE TENDÃO OU SINOVIAL - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 559,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'BIOPSIA DE TUMOR DO MEDIASTINO',
    size: 0,
  },
  {
    id: 560,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'BRONCOPLASTIA',
    size: 1,
  },
  {
    id: 561,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'BRONCOSCOPIA PARA ESTENOSE TRAQUEAL ',
    size: 0,
  },
  {
    id: 562,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'BRONCOSCOPIA/BRONCOFIBROSCOPIA',
    size: 0,
  },
  {
    id: 563,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'BRONCOTOMIA E/OU BRONCORRAFIA',
    size: 1,
  },
  {
    id: 564,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'CISTO OU DUPLICAÇÃO BRÔNQUICA OU ESOFÁGICA - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 565,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'CISTO OU DUPLICAÇÃO BRÔNQUICA OU ESOFÁGICA - TRATAMENTO CIRÚRGICO POR VÍDEO',
    size: 1,
  },
  {
    id: 566,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'COLACAÇÃO DE PRÓTESE LARINGO TRAQUEAL/ TRAQUEO-BRONQUICA (INCLUI PRÓTESE)',
    size: 0,
  },
  {
    id: 567,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'COLACAÇÃO DE PRÓTESE LARINGO-TRAQUEAL, TRAQUEAL, TRAQUEO-BRONQUICA, BRONQUICA POR VIA ENDOSCÓPICA (INCLUI PRÓTESE)',
    size: 0,
  },
  {
    id: 568,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'EXÉRESE DE TUMOR DE VIAS AÉREAS SUPERIORES FACE E PESCOÇO',
    size: 1,
  },
  {
    id: 569,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'FECHAMENTO DE FÍSTULA TRAQUEO-CUTÂNEA',
    size: 0,
  },
  {
    id: 570,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'LINFADENECTOMIA MEDIASTINAL',
    size: 0,
  },
  {
    id: 571,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'MEDIASTINOSCOPIA',
    size: 0,
  },
  {
    id: 572,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'MEDIASTINOTOMIA ',
    size: 0,
  },
  {
    id: 573,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'RESSECÇÃO DE TUMOR DE TRAQUEIA COM ANASTOMOSE',
    size: 1,
  },
  {
    id: 574,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'RESSECÇÃO DE TUMOR DO MEDIASTINO',
    size: 1,
  },
  {
    id: 575,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'RESSECÇÃO DE TUMOR DO MEDIASTINO POR VÍDEO',
    size: 1,
  },
  {
    id: 576,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TIMECTOMIA',
    size: 1,
  },
  {
    id: 577,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRAQUEOPLASTIA E/OU LARINGOTRAQUEOPLASTIA',
    size: 1,
  },
  {
    id: 578,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRAQUEOPLASTIA POR ACESSO TORÁCICO',
    size: 1,
  },
  {
    id: 579,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRAQUEORRAFIA E/OU FECHAMENTO DE FÍSTULA TRAQUEO-CUTÂNEA',
    size: 0,
  },
  {
    id: 580,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRAQUEOSCOPIA',
    size: 0,
  },
  {
    id: 581,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRAQUEOSTOMIA',
    size: 0,
  },
  {
    id: 582,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA BRONCOPLEURAL ',
    size: 1,
  },
  {
    id: 583,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA TRAQUEOESOFÁGICA',
    size: 1,
  },
  {
    id: 584,
    general: 'TÓRAX',
    specific: 'MEDIASTINO / TRAQUEIA / BRÔNQUIOS',
    name: 'TRATAMENTO DE MEDIASTINITE ',
    size: 1,
  },
  {
    id: 585,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'BIOPSIA/EXÉRESE DE NÓDULO DE MAMA',
    size: 0,
  },
  {
    id: 586,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'DRENAGEM DE ABSCESSO DE MAMA',
    size: 0,
  },
  {
    id: 587,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'ESVAZIAMENTO PERCUTÂNEO DE CISTO MAMÁRIO',
    size: 0,
  },
  {
    id: 588,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'EXÉRESE DE MAMA SUPRANUMERARIA',
    size: 0,
  },
  {
    id: 589,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'FITULECTOMIA DE MAMA',
    size: 0,
  },
  {
    id: 590,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'MAMOPLASTIA PÓS-CIRURGIA BARIÁTRICA',
    size: 0,
  },
  {
    id: 591,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'MAMOPLASTIA REDUTORA',
    size: 0,
  },
  {
    id: 592,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'MASTECTOMIA RADICAL C/ LINFADENECTOMIA',
    size: 0,
  },
  {
    id: 593,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'MASTECTOMIA SIMPLES',
    size: 0,
  },
  { id: 594, general: 'TÓRAX', specific: 'MAMAS', name: 'MASTOPEXIA', size: 0 },
  {
    id: 595,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'PLÁSTICA MAMARIA FEMININA NAO ESTETICA',
    size: 0,
  },
  {
    id: 596,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'PLÁSTICA MAMARIA MASCULINA',
    size: 0,
  },
  {
    id: 597,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'PLÁSTICA MAMARIA RECONSTRUTIVA - POS MASTECTOMIA C/ IMPLANTE DE PRÓTESE',
    size: 0,
  },
  {
    id: 598,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'QUADRANTECTOMIA',
    size: 0,
  },
  {
    id: 599,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'QUADRANTECTOMIA COM LINFADENECTOMIA AXILAR',
    size: 0,
  },
  {
    id: 600,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'RECONSTRUÇÃO DE ARÉOLA MAMILAR',
    size: 0,
  },
  {
    id: 601,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'RECONSTRUÇÃO MAMÁRIA COM RETALHO MUSCULAR OU MIOCUTÂNEO',
    size: 0,
  },
  {
    id: 602,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'RESSECÇÃO DE LESÃO DE MAMA COM MARCAÇÃO ',
    size: 0,
  },
  {
    id: 603,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'RESSECÇÃO DOS DUCTOS PRINCIPAIS DA MAMA',
    size: 0,
  },
  {
    id: 604,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'RETIRADA / SUBSTITUIÇÃO DE PRÓTESE MAMARIA',
    size: 0,
  },
  {
    id: 605,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'SETORECTOMIA / QUADRANTECTOMIA',
    size: 0,
  },
  {
    id: 606,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'SETORECTOMIA / QUADRANTECTOMIA C/ ESVAZIAMENTO GANGLIONAR',
    size: 0,
  },
  {
    id: 607,
    general: 'TÓRAX',
    specific: 'MAMAS',
    name: 'TRATAMENTO DE GINECOMASTIA ',
    size: 0,
  },
  {
    id: 608,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'BIOPSIA CIRÚRGICA DE ESTENO OU COSTELA',
    size: 0,
  },
  {
    id: 609,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'BIOPSIA DE PULMÃO POR ASPIRAÇÃO',
    size: 0,
  },
  {
    id: 610,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'BIOPSIA PLEURAL (POR ASPIRAÇÃO / AGULHA)',
    size: 0,
  },
  {
    id: 611,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'BULECTOMIA ',
    size: 0,
  },
  {
    id: 612,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'BULECTOMIA POR VIDEOTORACOSCOPIA',
    size: 0,
  },
  {
    id: 613,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'CIRURGIA REDUTORA DO VOLUME PULMONAR',
    size: 1,
  },
  {
    id: 614,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'CORREÇÃO DE FÍSTULA BRONCO-PLEURAL',
    size: 1,
  },
  {
    id: 615,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'COSTECTOMIA',
    size: 0,
  },
  {
    id: 616,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'DESCORTICAÇÃO PULMONAR',
    size: 1,
  },
  {
    id: 617,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'DESCORTICAÇÃO PULMONAR POR VIDEOTORACOSCOPIA',
    size: 1,
  },
  {
    id: 618,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'DRENAGEM DE PLEURA',
    size: 0,
  },
  {
    id: 619,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'DRENAGEM TUBULAR PLEURAL ABERTA ',
    size: 0,
  },
  {
    id: 620,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'ESTERNECTOMIA COM OU SEM PRÓTESE',
    size: 1,
  },
  {
    id: 621,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'FECHAMENTO DE PLEUROSTOMIA',
    size: 0,
  },
  {
    id: 622,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'FRATURA, LUXAÇÃO DE ESTERNO OU COSTELA - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 623,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'LOBECTOMIA PULMONAR',
    size: 1,
  },
  {
    id: 624,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'LOBECTOMIA PULMONAR POR VIDEOTORACOSCOPIA',
    size: 1,
  },
  {
    id: 625,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'METASTASECTOMIA PULMONAR ',
    size: 1,
  },
  {
    id: 626,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PLEURECTOMIA',
    size: 1,
  },
  {
    id: 627,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PLEURODESE',
    size: 0,
  },
  {
    id: 628,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PLEUROSCOPIA',
    size: 0,
  },
  {
    id: 629,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PLEUROSTOMIA',
    size: 0,
  },
  {
    id: 630,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PNEUMONECTOMIA',
    size: 1,
  },
  {
    id: 631,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PNEUMONECTOMIA RADICAL',
    size: 1,
  },
  {
    id: 632,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PNEUMORRAFIA      ',
    size: 0,
  },
  {
    id: 633,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'PNEUMOTOMIA COM RESSECÇÃO COSTAL PARA DRENAGEM CAVITÁRIA',
    size: 0,
  },
  {
    id: 634,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'RESSECÇÃO EM CUNHA, TUMORECTOMIA DE PULMÃO ',
    size: 0,
  },
  {
    id: 635,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'RESSECÇÃO TUMOR DE PLEURA LOCALIZADO',
    size: 0,
  },
  {
    id: 636,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'RETIRADA DE CORPO ESTRANHO DA PAREDE TORÁCICA',
    size: 0,
  },
  {
    id: 637,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'SEGMENTECTOMIA PULMONAR ',
    size: 1,
  },
  {
    id: 638,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'SIMPATECTOMIA POR VIDEOTORACOSCOPIA',
    size: 0,
  },
  {
    id: 639,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TORACECTOMIA COM RECONSTRUÇÃO PARIETAL (POR PRÓTESE)',
    size: 1,
  },
  {
    id: 640,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TORACECTOMIA COMPLEXA ',
    size: 1,
  },
  {
    id: 641,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TORACOCENTESE',
    size: 0,
  },
  {
    id: 642,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TORACOPLASTIA',
    size: 1,
  },
  {
    id: 643,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TORACOSTOMIA COM DRENAGEM PLEURAL FECHADA',
    size: 0,
  },
  {
    id: 644,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TORACOTOMIA EXPLORADORA',
    size: 1,
  },
  {
    id: 645,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TRATAMENTO CIRÚRGICO DE DEFORMIDADES DA PAREDE TORÁCICA',
    size: 1,
  },
  {
    id: 646,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURA, NECROSE OU INFECÇÃO DO ESTERNO',
    size: 0,
  },
  {
    id: 647,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TRATAMENTO CIRÚRGICO DE FRATURAS DO GRADIL COSTAL',
    size: 0,
  },
  {
    id: 648,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TRATAMENTO CIRÚRGICO DE HEMORRAGIA INTRAPLEURAL',
    size: 1,
  },
  {
    id: 649,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'TROMBOENDARTERECTOMIA PULMONAR',
    size: 1,
  },
  {
    id: 650,
    general: 'TÓRAX',
    specific: 'PULMÃO / PLEURA / PAREDE TORÁCICA ',
    name: 'VIDEOTORACOSCOPIA',
    size: 0,
  },
  {
    id: 651,
    general: 'TÓRAX',
    specific: 'DIAFRAGMA',
    name: 'DRENAGEM DE ABSCESSO SUBFRÊNICO',
    size: 0,
  },
  {
    id: 652,
    general: 'TÓRAX',
    specific: 'DIAFRAGMA',
    name: 'HERNIOPLASTIA DIAFRAGMÁTICA ',
    size: 1,
  },
  {
    id: 653,
    general: 'TÓRAX',
    specific: 'DIAFRAGMA',
    name: 'IMPLANTE MARCAPASSO DIAFRAGMÁTICO ',
    size: 0,
  },
  {
    id: 654,
    general: 'TÓRAX',
    specific: 'DIAFRAGMA',
    name: 'RESSECÇÃO DE TUMOR DIAFRAGMÁTCIO E RECONSTRUÇÃO',
    size: 1,
  },
  {
    id: 655,
    general: 'TÓRAX',
    specific: 'DIAFRAGMA',
    name: 'TRATAMENTO CIRÚRGICO EVENTRAÇÃO DIAFRAGMÁTICA',
    size: 1,
  },
  {
    id: 656,
    general: 'TÓRAX',
    specific: 'DIAFRAGMA',
    name: 'TRATAMENTO CIRÚRGICO HÉRNIA DIAFRAGMÁTICA',
    size: 1,
  },
  {
    id: 657,
    general: 'TÓRAX',
    specific: 'DIAFRAGMA',
    name: 'TRATAMENTO CIRÚRGICO HÉRNIA DIAFRAGMÁTICA VIDEOLAPAROSCÓPICO',
    size: 1,
  },
  {
    id: 658,
    general: 'TÓRAX',
    specific: 'PERICÁRDIO',
    name: 'DRENAGEM COM BIOPSIA DE PERICÁRDIO',
    size: 0,
  },
  {
    id: 659,
    general: 'TÓRAX',
    specific: 'PERICÁRDIO',
    name: 'EXÉRESE DE CISTO PERICÁRDICO',
    size: 1,
  },
  {
    id: 660,
    general: 'TÓRAX',
    specific: 'PERICÁRDIO',
    name: 'PERICARDIECTOMIA',
    size: 1,
  },
  {
    id: 661,
    general: 'TÓRAX',
    specific: 'PERICÁRDIO',
    name: 'PERICARDIOCENTESE',
    size: 0,
  },
  {
    id: 662,
    general: 'TÓRAX',
    specific: 'PERICÁRDIO',
    name: 'PERICARDIOTOMIA COM ABERTURA PLEURO-PERICÁRDICA',
    size: 1,
  },
  {
    id: 663,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'ABERTURA DE COMUNICAÇO INTER-ATRIAL',
    size: 1,
  },
  {
    id: 664,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'ABERTURA DE ESTENOSE AÓRTICA VALVAR',
    size: 1,
  },
  {
    id: 665,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'ABERTURA DE ESTENOSE PULMONAR VALVAR',
    size: 1,
  },
  {
    id: 666,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'AMPLIAÇÃO DE VIA DE SAÍDA DO VENTRÍCULO DIREITO E/OU RAMOS PULMONARES',
    size: 1,
  },
  {
    id: 667,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'AMPLIAÇÃO DO ANEL VALVAR',
    size: 1,
  },
  {
    id: 668,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'ANEURISMECTOMIA ASSOCIADA OU NAO A REVASCULARIZAÇÃO MIOCÁRDICA',
    size: 1,
  },
  {
    id: 669,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'ANEURISMECTOMIA DO VENTRÍCULO ESQUERDO',
    size: 1,
  },
  {
    id: 670,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CARDIORRAFIA',
    size: 1,
  },
  {
    id: 671,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CARDIOTOMIA PARA RETIRADA DE CORPO ESTRANHO',
    size: 1,
  },
  {
    id: 672,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CORREÇÃO DE COARCTAÇÃO DA AORTA',
    size: 1,
  },
  {
    id: 673,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CORREÇÃO DE COMUNICAÇÃO INTERVENTRICULAR',
    size: 1,
  },
  {
    id: 674,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CORREÇÃO DE ESTENOSE MITRAL CONGÊNITA',
    size: 1,
  },
  {
    id: 675,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CORREÇÃO DE INSUFICIÊNCIA MITRAL CONGÊNITA',
    size: 1,
  },
  {
    id: 676,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CORREÇÃO DE TETRALOGIA DE FALLOT E VARIANTES',
    size: 1,
  },
  {
    id: 677,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CORREÇO DE ÁTRIO ÚNICO',
    size: 1,
  },
  {
    id: 678,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'CORREÇO DE ESTENOSE SUPRA-AÓRTICA',
    size: 1,
  },
  {
    id: 679,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'DERIVAÇÃO CAVO-ATRIAL',
    size: 1,
  },
  {
    id: 680,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'FECHAMENTO DE COMUNIÇÃO INTERATRIAL',
    size: 1,
  },
  {
    id: 681,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'IMPLANTE DE CARDIOVERSOR DESFIBRILADOR',
    size: 0,
  },
  {
    id: 682,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'IMPLANTE DE CARDIOVERSOR DESFIBRILADOR TRANSVENOSO',
    size: 0,
  },
  {
    id: 683,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'IMPLANTE DE MARCAPASSO CARDÍACO',
    size: 0,
  },
  {
    id: 684,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'IMPLANTE DE MARCAPASSO CARDÍACO MULTI-SÍTIO EPIMIOCÁRDICO POR TÓRACOTOMIA',
    size: 1,
  },
  {
    id: 685,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'IMPLANTE DE MARCAPASSO TEMPORARIO TRANSVENOSO',
    size: 0,
  },
  {
    id: 686,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'IMPLANTE DE PRÓTESE VALVAR',
    size: 1,
  },
  {
    id: 687,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'MAPEAMENTO DE FEIXE ANÔMALO',
    size: 0,
  },
  {
    id: 688,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'PLASTIA / TROCA DE VÁLVULA TRICÚSPIDE',
    size: 1,
  },
  {
    id: 689,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'PLASTIA VALVAR ',
    size: 1,
  },
  {
    id: 690,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'PLÁSTICA DE LOJA DE GERADOR DE SISTEMA DE ESTIMULAÇÃO CARDÍACA ARTIFICIAL',
    size: 0,
  },
  {
    id: 691,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'RECONSTRUÇÃO DA RAIZ DA AORTA',
    size: 1,
  },
  {
    id: 692,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'RECONSTRUÇÃO DA RAIZ DA AORTA C/ TUBO VALVADO',
    size: 1,
  },
  {
    id: 693,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'REPOSICIONAMENTO DE ELETRODOS DE CDI / MARCAPASSO',
    size: 0,
  },
  {
    id: 694,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'RESSECÇÃO DE TUMOR INTRACARDIACO',
    size: 1,
  },
  {
    id: 695,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'RESSECÇO DE ENDOMIOCARDIOFIBROSE',
    size: 1,
  },
  {
    id: 696,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'RETIRADA DE CORPO ESTRANHO DE SISTEMA CARDIOVASCULAR POR TÉCNICAS HEMODINÂMICAS',
    size: 0,
  },
  {
    id: 697,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'REVASCULARIZAÇÃO MIOCÁRDICA ASSOCIADA A CIRURGIA VALVAR',
    size: 1,
  },
  {
    id: 698,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'REVASCULARIZAÇÃO MIOCARDICA COM OU SEM USO DE EXTRACORPOREA',
    size: 1,
  },
  {
    id: 699,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'REVASCULARIZAÇÃO POR PONTE / TROMBOENDARTERECTOMIA FEMURO-POPLITEA DISTAL',
    size: 1,
  },
  {
    id: 700,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'TRATAMENTO DE COMPLICAÇÕES DE DISPOSITIVOS PROTÉTICOS IMPLANTES E ENXERTOS CARDÍACOS E VALVULARES',
    size: 1,
  },
  {
    id: 701,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'TROCA DE ELETRODOS DE MARCAPASSO ',
    size: 0,
  },
  {
    id: 702,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'TROCA DE GERADOR DE CDI / MARCAPASSO',
    size: 0,
  },
  {
    id: 703,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'TROCA VALVAR COM REVASCULARIZAÇÃO MIOCÁRDICA',
    size: 1,
  },
  {
    id: 704,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'VALVULOPLASTIA AÓRTICA PERCUTÂNEA',
    size: 0,
  },
  {
    id: 705,
    general: 'TÓRAX',
    specific: 'CORAÇÃO',
    name: 'VALVULOPLASTIA MITRAL PERCUTÂNEA ',
    size: 0,
  },
  {
    id: 706,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMA DE ARTÉRIAS VISCERAIS - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 707,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMA DE AXILAR, FEMORAL, POPLÍTEA - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 708,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMA DE CARÓTIDA, SUBCLÁVIA, ILÍACA - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 709,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMA ROTO OU TROMBOSADO DE AORTA - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 710,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMAS ROTOS OU TROMBOSADOS DE ARTÉRIAS VISCERAIS - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 711,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMECTOMIA DE AORTA ABDOMINAL INFRA-RENAL',
    size: 1,
  },
  {
    id: 712,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMECTOMIA DE AORTA ABDOMINAL SUPRA-RENAL',
    size: 1,
  },
  {
    id: 713,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ANEURISMECTOMIA TÓRACO-ABDOMINAL',
    size: 1,
  },
  {
    id: 714,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ARTERIOPLASTIA DA FEMORAL PROFUNDA',
    size: 1,
  },
  {
    id: 715,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'COLOCAÇÃO DE BALÃO INTRA-AÓRTICO',
    size: 0,
  },
  {
    id: 716,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'CONFECÇÃO DE BANDAGEM DA ARTÉRIA PULMONAR',
    size: 1,
  },
  {
    id: 717,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'CONFECÇÃO DE FÍSTULA ARTERIO-VENOSA EM MEMBROS',
    size: 0,
  },
  {
    id: 718,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'CORREÇÃO DAS DISSECÇÕES DA AORTA',
    size: 1,
  },
  {
    id: 719,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'CORREÇÃO DE ANEURISMA/DISSEÇCAO DA AORTA TÓRACO-ABDOMINAL',
    size: 1,
  },
  {
    id: 720,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'CORREÇÃO DE PERSISTÊNCIA DO CANAL ARTERIAL',
    size: 1,
  },
  {
    id: 721,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'CORREÇÕES DE ANOMALIAS DO ARCO AÓRTICO',
    size: 1,
  },
  {
    id: 722,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'DISSECÇÃO DE VEIA / ARTÉRIA',
    size: 0,
  },
  {
    id: 723,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'EMBOLECTOMIA OU TROMBOEMBOLECTOMIA ARTERIAL ',
    size: 1,
  },
  {
    id: 724,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'ENDARTERECTOMIA AORTO-ILÍACA',
    size: 1,
  },
  {
    id: 725,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'FÍSTULA AORTO-CAVA, RENO-CAVA OU ÍLIO-ILÍACA',
    size: 1,
  },
  {
    id: 726,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'INSTALAÇÃO DE CIRCUITO PARA ASSISTÊNCIA MECÂNICA CIRCULATÓRIA',
    size: 1,
  },
  {
    id: 727,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'LESÕES VASCULARES CERVICAIS E CERVICOTORÁCICAS',
    size: 1,
  },
  {
    id: 728,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'LESÕES VASCULARES DE MEMBRO INFERIOR OU SUPERIOR',
    size: 1,
  },
  {
    id: 729,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'LESÕES VASCULARES TRAUMÁTICAS INTRAABDOMINAIS',
    size: 1,
  },
  {
    id: 730,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'LESÕES VASCULARES TRAUMÁTICAS INTRATORÁCICAS',
    size: 1,
  },
  {
    id: 731,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'LIGADURA DE FÍSTULA ARTÉRIO-VENOSA EM MEMBROS',
    size: 0,
  },
  {
    id: 732,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PLASTIA ARTÉRIA HIPOGÁSTRICA',
    size: 1,
  },
  {
    id: 733,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PLASTIA ARTÉRIA MESENTÉRICA INFERIOR',
    size: 1,
  },
  {
    id: 734,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PLASTIA ARTÉRIA MESENTÉRICA SUPERIOR',
    size: 1,
  },
  {
    id: 735,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PLASTIA ARTERIAL ',
    size: 1,
  },
  {
    id: 736,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) AORTO-(BI)FEMORAL',
    size: 1,
  },
  {
    id: 737,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) AORTO-CERVICAIS',
    size: 1,
  },
  {
    id: 738,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) AORTO-ILÍACA',
    size: 1,
  },
  {
    id: 739,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) AXILO-FEMORAL',
    size: 1,
  },
  {
    id: 740,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) DE RAMOS DOS TRONCOS SUPRA-AORTICOS',
    size: 1,
  },
  {
    id: 741,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) FÊMORO-FEMORAL ',
    size: 1,
  },
  {
    id: 742,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) FÊMORO-POPLÍTEO',
    size: 1,
  },
  {
    id: 743,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) SUBCLAVIO-FEMORAL',
    size: 1,
  },
  {
    id: 744,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) TROMBOENDARTERECTOMIA DE CARÓTIDA',
    size: 0,
  },
  {
    id: 745,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'PONTE (BYPASS) TROMBOENDARTERECTOMIA ILÍACO-FEMORAL',
    size: 1,
  },
  {
    id: 746,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'REOPERAÇÃO DE AORTA ABDOMINAL',
    size: 1,
  },
  {
    id: 747,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'RETIRADA DE PRÓTESE INFECTADA EM POSIÇÃO AÓRTICA',
    size: 1,
  },
  {
    id: 748,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'RETIRADA DE PRÓTESE INFECTADA EM POSIÇÃO NÃO AÓRTICA',
    size: 1,
  },
  {
    id: 749,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'RETIRADA DE SISTEMA DE ESTIMULAÇÃO CARDÍACA ARTIFICIAL',
    size: 0,
  },
  {
    id: 750,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'REVASCULARIZAÇÃO DO MEMBRO SUPERIOR',
    size: 1,
  },
  {
    id: 751,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRANSPLANTE DE SEGMENTO VENOSO VALVULADO',
    size: 0,
  },
  {
    id: 752,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRATAMENTO CIRÚRGICO ANEURISMA DE AORTA',
    size: 1,
  },
  {
    id: 753,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRATAMENTO CIRÚRGICO DE ANEURISMAS DAS ARTÉRIAS VISCERAIS',
    size: 1,
  },
  {
    id: 754,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA ARTERIO-VENOSA DOS GRANDES VASOS INTRATORÁCICOS',
    size: 1,
  },
  {
    id: 755,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRATAMENTO CIRÚRGICO DE LESÕES VASCULARES TRAUMÁTICAS DA REGIÃO CERVICAL',
    size: 0,
  },
  {
    id: 756,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRATAMENTO CIRÚRGICO DE LESÕES VASCULARES TRAUMÁTICAS DE MEMBRO INFERIOR',
    size: 1,
  },
  {
    id: 757,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRATAMENTO CIRÚRGICO DE LESÕES VASCULARES TRAUMÁTICAS DE MEMBRO SUPERIOR',
    size: 0,
  },
  {
    id: 758,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TRATAMENTO CIRÚRGICO DE LESÕES VASCULARES TRAUMÁTICAS DO ABDOMEN',
    size: 1,
  },
  {
    id: 759,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TROCA DE AORTA DESCENDENTE',
    size: 1,
  },
  {
    id: 760,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA ARTERIAL',
    name: 'TROCA DE ARCO AÓRTICO',
    size: 1,
  },
  {
    id: 761,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'ALCOOLIZAÇÃO PERCUTÂNEA DE HEMANGIOMA E MALFORMAÇÕES VENOSAS ',
    size: 0,
  },
  {
    id: 762,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'ANASTOMOSE CAVO-PULMONAR BIDIRECIONAL',
    size: 1,
  },
  {
    id: 763,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'ANASTOMOSE LINFOVENOSA',
    size: 0,
  },
  {
    id: 764,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'COLOCAÇÃO PERCUTÂNEA DE FILTRO DE VEIA CAVA',
    size: 0,
  },
  {
    id: 765,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'DERIVAÇÃO PORTO-SISTÊMICA',
    size: 1,
  },
  {
    id: 766,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'DESCONEXÃO ÁZIGOS-PORTAL SEM ESPLENECTOMIA',
    size: 0,
  },
  {
    id: 767,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'DESCONEXÃO ÁZIGOS-PORTAL SEM ESPLENECTOMIA POR VIDEOLAPAROSCOPIA',
    size: 0,
  },
  {
    id: 768,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'TRATAMENTO CIRÚRGICO DE VARIZES ',
    size: 0,
  },
  {
    id: 769,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'TRATAMENTO CIRÚRGICO VARIZES MEMBROS INFERIORES',
    size: 0,
  },
  {
    id: 770,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'CIRURGIA VENOSA',
    name: 'TROMBECTOMIA DO SISTEMA VENOSO',
    size: 0,
  },
  {
    id: 771,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOGRAFIA DE ARCO AÓRTICO E TRONCOS SUPRA-AÓRTICOS',
    size: 0,
  },
  {
    id: 772,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOPLASTIA ARTÉRIA RENAL',
    size: 0,
  },
  {
    id: 773,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOPLASTIA CORONARIANA COM IMPLANTE DE STENT',
    size: 0,
  },
  {
    id: 774,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOPLASTIA DE CARÓTIDA ',
    size: 0,
  },
  {
    id: 775,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOPLASTIA EM ENXERTO CORONARIANO',
    size: 0,
  },
  {
    id: 776,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOPLASTIA INTRALUMINAL DE AORTA, VEIA CAVA / VASOS ILIACOS ',
    size: 0,
  },
  {
    id: 777,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOPLASTIA INTRALUMINAL DE VASOS DAS EXTREMIDADES ',
    size: 0,
  },
  {
    id: 778,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ANGIOPLASTIA INTRALUMINAL DE VASOS DO PESCOÇO / TRONCOS SUPRA-AORTICOS ',
    size: 0,
  },
  {
    id: 779,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'ATRIOSSEPTOSTOMIA POR BALÃO',
    size: 0,
  },
  {
    id: 780,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'CATETERISMO CARDÍACO',
    size: 0,
  },
  {
    id: 781,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'CORREÇÃO ENDOVASCULAR DE ANEURISMA / DISSECÇÃO DA AORTA ABDOMINAL C/ ENDOPRÓTESE ',
    size: 0,
  },
  {
    id: 782,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'CORREÇÃO ENDOVASCULAR DE ANEURISMA / DISSECÇÃO DA AORTA ABDOMINAL E ILÍACAS C/ ENDOPRÓTESE BIFURCADA',
    size: 0,
  },
  {
    id: 783,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'CORRECAO ENDOVASCULAR DE ANEURISMA / DISSECCAO DA AORTA TORACICA COM ENDOPRÓTESE ',
    size: 0,
  },
  {
    id: 784,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'CORREÇÃO ENDOVASCULAR DE ANEURISMA / DISSECCAO DAS ILÍACAS COM ENDOPRÓTESE TUBULAR',
    size: 0,
  },
  {
    id: 785,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO ARTERIAL DE HEMORRAGIA DIGESTIVA',
    size: 0,
  },
  {
    id: 786,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO BRÔNQUICA PARA TRATAMENTO DE HEMOPTISE',
    size: 0,
  },
  {
    id: 787,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO DE ANEURISMA CEREBRAL',
    size: 0,
  },
  {
    id: 788,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO DE FÍSTULA ARTÉRIO-VENOSA DA CABECA E PESCOCO',
    size: 0,
  },
  {
    id: 789,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO DE MALFORMAÇÃO ARTERIO-VENOSA DURAL ',
    size: 0,
  },
  {
    id: 790,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO DE MALFORMAÇÃO POR PUNÇÃO DIRETA',
    size: 0,
  },
  {
    id: 791,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO DE MALFORMAÇÃO VASCULAR ARTÉRIO-VENOSA',
    size: 0,
  },
  {
    id: 792,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO ENDOVASCULAR ANEURISMA CEREBRAL',
    size: 0,
  },
  {
    id: 793,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'EMBOLIZAÇÃO ENDOVASCULAR DE TUMOR INTRA-CRANIANO ',
    size: 0,
  },
  {
    id: 794,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'FECHAMENTO PERCUTÂNEO DE FÍSTULAS ARTERIOVENOSAS COM LIBERAÇÃO DE COILS',
    size: 0,
  },
  {
    id: 795,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'FIBRINÓLISE INTRAVASCULAR POR CATETER ',
    size: 0,
  },
  {
    id: 796,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'IMPLANTE DE PRÓTESE INTRAVASCULAR EM VASOS PULMONARES OU RAMOS',
    size: 0,
  },
  {
    id: 797,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'IMPLANTE DE STENT CORONARIANO COM OU SEM ANGIOPLASTIA POR BALÃO',
    size: 0,
  },
  {
    id: 798,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'IMPLANTE TRANSCATETER DE PRÓTESE VALVAR AÓRTICA (TAVI)',
    size: 0,
  },
  {
    id: 799,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'OCLUSÃO DO APÊNDICE ATRIAL ESQUERDO',
    size: 0,
  },
  {
    id: 800,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'OCLUSÃO PERCUTÂNEA DE FÍSTULA E/OU CONEXÕES SISTÊMICO PULMONARES',
    size: 0,
  },
  {
    id: 801,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'OCLUSÃO PERCUTÂNEA DE SHUNTS INTRACARDÍACOS',
    size: 0,
  },
  {
    id: 802,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'TRATAMENTO DE ANEURISMA GIGANTE POR OCLUSÃO DO VASO PORTADOR',
    size: 0,
  },
  {
    id: 803,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'TRATAMENTO DE EPISTAXE POR EMBOLIZAÇÃO',
    size: 0,
  },
  {
    id: 804,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'TRATAMENTO DE HEMATÚRIA POR EMBOLIZAÇÃO ',
    size: 0,
  },
  {
    id: 805,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'TRATAMENTO DE HEMOPTISE POR EMBOLIZAÇÃO PERCUTÂNEA ',
    size: 0,
  },
  {
    id: 806,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'TRATAMENTO ENDOVASCULAR DO PSEUDOANEURISMA',
    size: 0,
  },
  {
    id: 807,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'VALVULOPLASTIA PERCUTÂNEA POR VIA ARTERIAL OU VENOSA',
    size: 0,
  },
  {
    id: 808,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'ENDOVASCULAR',
    name: 'VALVULOPLASTIA PERCUTÂNEA POR VIA TRANSEPTAL',
    size: 1,
  },
  {
    id: 809,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'BIOPSIA DE GÂNGLIO LINFÁTICO',
    size: 0,
  },
  {
    id: 810,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'DESCONEXÃO ÁZIGOS-PORTAL COM ESPLENECTOMIA',
    size: 0,
  },
  {
    id: 811,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'ESPLENECTOMIA PARCIAL',
    size: 0,
  },
  {
    id: 812,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'ESPLENECTOMIA PARCIAL VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 813,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'ESPLENECTOMIA TOTAL',
    size: 0,
  },
  {
    id: 814,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'ESPLENECTOMIA TOTAL VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 815,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'EXÉRESE DE GÂNGLIO LINFÁTICO',
    size: 0,
  },
  {
    id: 816,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'EXÉRESE DE HIGROMA CÍSTICO',
    size: 0,
  },
  {
    id: 817,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LIGADURA DE DUCTO TORÁCICO VIDEOLAPAROSCÓPICO',
    size: 0,
  },
  {
    id: 818,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA AXILAR ',
    size: 0,
  },
  {
    id: 819,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA DE TRONCO CELIACO',
    size: 1,
  },
  {
    id: 820,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA INGUINAL ',
    size: 0,
  },
  {
    id: 821,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA INGUINO-ILÍACA ',
    size: 0,
  },
  {
    id: 822,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA MEDIASTINAL ',
    size: 0,
  },
  {
    id: 823,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA PROFUNDA',
    size: 0,
  },
  {
    id: 824,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA RADICAL AXILAR ',
    size: 0,
  },
  {
    id: 825,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA RADICAL CERVICAL BILATERAL',
    size: 1,
  },
  {
    id: 826,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA RADICAL CERVICAL UNLATERAL',
    size: 0,
  },
  {
    id: 827,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA RADICAL INGUINAL',
    size: 0,
  },
  {
    id: 828,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA RETROPERITONIAL',
    size: 1,
  },
  {
    id: 829,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA SELETIVA GUIADA (LINFONODO SENTINELA) ',
    size: 0,
  },
  {
    id: 830,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA SUPERFICIAL',
    size: 0,
  },
  {
    id: 831,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'LINFADENECTOMIA SUPRACLAVICULAR ',
    size: 0,
  },
  {
    id: 832,
    general: 'SISTEMA CIRCULATÓRIO',
    specific: 'SISTEMA LINFÁTICO / BAÇO',
    name: 'TRATAMENTO CIRÚRGICO DE LINFEDEMA',
    size: 0,
  },
  {
    id: 833,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ATRESIA DE ESÔFAGO COM FÍSTULA TRAQUEAL - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 834,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ATRESIA DE ESÔFAGO SEM FÍSTULA ',
    size: 1,
  },
  {
    id: 835,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'CIRURGIA BARIÁTRICA POR VIDEOLAPAROSCOPIA        ',
    size: 1,
  },
  {
    id: 836,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'DEGASTROGASTRECTOMIA COM VAGOTOMIA',
    size: 1,
  },
  {
    id: 837,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'DEGASTROGASTRECTOMIA SEM VAGOTOMIA',
    size: 1,
  },
  {
    id: 838,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'DISSECÇÃO DO ESÔFAGO TORÁCICO',
    size: 1,
  },
  {
    id: 839,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGECTOMIA DISTAL ',
    size: 1,
  },
  {
    id: 840,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGECTOMIA VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 841,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGOCOLONPLASTIA',
    size: 1,
  },
  {
    id: 842,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGOCOLOPLASTIA OU ESOFAGOGASTROPLASTIA ',
    size: 1,
  },
  {
    id: 843,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGOGASTRECTOMIA',
    size: 1,
  },
  {
    id: 844,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGOGASTRECTOMIA ',
    size: 1,
  },
  {
    id: 845,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGOPLASTIA / GASTROPLASTIA',
    size: 1,
  },
  {
    id: 846,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGORRAFIA CERVICAL',
    size: 0,
  },
  {
    id: 847,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGORRAFIA TORACICA',
    size: 1,
  },
  {
    id: 848,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESOFAGOSTOMIA',
    size: 0,
  },
  {
    id: 849,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'ESTENOSE DE ESÔFAGO - TRATAMENTO CIRÚRGICO VIA TORÁCICA',
    size: 1,
  },
  {
    id: 850,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'FARINGOLARINGOESOFAGECTOMIA TOTAL',
    size: 1,
  },
  {
    id: 851,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTRECTOMIA PARCIAL',
    size: 1,
  },
  {
    id: 852,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTRECTOMIA TOTAL',
    size: 1,
  },
  {
    id: 853,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTRECTOMIA VERTICAL EM MANGA (SLEEVE)',
    size: 1,
  },
  {
    id: 854,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTRECTOMIA VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 855,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTROENTEROANASTOMOSE',
    size: 1,
  },
  {
    id: 856,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTROPLASTIA COM DERIVAÇÃO INTESTINAL',
    size: 1,
  },
  {
    id: 857,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTROPLASTIA VERTICAL COM BANDA',
    size: 1,
  },
  {
    id: 858,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTRORRAFIA',
    size: 1,
  },
  {
    id: 859,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTROSTOMIA',
    size: 0,
  },
  {
    id: 860,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'GASTROSTOMIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 861,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'LIGADURA ELÁSTICA DE LESÃO HEMORRÁGICA DO APARELHO DIGESTIVO',
    size: 0,
  },
  {
    id: 862,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'PILOROMIOTOMIA',
    size: 0,
  },
  {
    id: 863,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'PILOROMIOTOMIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 864,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'PILOROPLASTIA',
    size: 0,
  },
  {
    id: 865,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'PILOROPLASTIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 866,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'RECONSTRUÇÃO DO ESÔFAGO CERVICAL E/OU TORÁCICO COM TRANSPLANTE SEGMENTAR DE INTESTINO',
    size: 1,
  },
  {
    id: 867,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'REINTERVENÇÃO SOBRE TRANSIÇÃO ESÔFAGO-GÁSTRICA',
    size: 1,
  },
  {
    id: 868,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'REINTERVENÇÃO SOBRE TRANSIÇÃO ESÔFAGO-GÁSTRICA POR VIDEOLAPAROSCOPIA',
    size: 0,
  },
  {
    id: 869,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'RESSECÇÃO DO ESÔFAGO CERVICAL E/OU TORÁCICO ',
    size: 1,
  },
  {
    id: 870,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO CIRÚRGICO DE ACALASIA (CARDIOMIOPLASTIA)',
    size: 0,
  },
  {
    id: 871,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO CIRÚRGICO DE REFLUXO GASTROESOFÁGICO',
    size: 1,
  },
  {
    id: 872,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO CIRÚRGICO DE REFLUXO GASTROESOFÁGICO POR VIDEOLAPAROSCOPIA',
    size: 0,
  },
  {
    id: 873,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO CIRÚRGICO DE VARIZES ESOFÁGICAS',
    size: 0,
  },
  {
    id: 874,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO CIRÚRGICO DO DIVERTÍCULO ESOFÁGICO',
    size: 0,
  },
  {
    id: 875,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO CIRÚRGICO DO DIVERTÍCULO ESOFÁGICO POR VIDEOTORACOSCOPIA',
    size: 0,
  },
  {
    id: 876,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO CIRÚRGICO DO DIVERTÍCULO FARINGOESOFÁGICO',
    size: 0,
  },
  {
    id: 877,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMENTO DE INTERCORRÊNCIAS CIRÚRGICA PÓS- CIRURGIA BARIÁTRICA',
    size: 1,
  },
  {
    id: 878,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'TRATAMETO CIRÚRGICO DE MEGAESOFAGO SEM RESSECÇÃO / CONSERVADOR',
    size: 0,
  },
  {
    id: 879,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ESÔFAGO / ESTÔMAGO',
    name: 'VAGOTOMIA GÁSTRICA PROXIMAL',
    size: 0,
  },
  {
    id: 880,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'APENDICECTOMIA',
    size: 0,
  },
  {
    id: 881,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'APENDICECTOMIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 882,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'ATRESIA DE CÓLON - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 883,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'CIRURGIA ABAIXAMENTO DE CÓLON',
    size: 1,
  },
  {
    id: 884,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'CIRURGIA ABAIXAMENTO DE CÓLON VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 885,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'COLECTOMIA PARCIAL (HEMICOLECTOMIA)',
    size: 1,
  },
  {
    id: 886,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'COLECTOMIA PARCIAL (HEMICOLECTOMIA) VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 887,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'COLECTOMIA TOTAL',
    size: 1,
  },
  {
    id: 888,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'COLECTOMIA TOTAL VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 889,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'COLORRAFIA POR VIA ABDOMINAL',
    size: 1,
  },
  {
    id: 890,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'COLOSTOMIA',
    size: 0,
  },
  {
    id: 891,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'DUODENOPANCREATECTOMIA',
    size: 1,
  },
  {
    id: 892,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'ENTERECTOMIA',
    size: 1,
  },
  {
    id: 893,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'ENTEROANASTOMOSE ',
    size: 1,
  },
  {
    id: 894,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'ENTEROPEXIA',
    size: 1,
  },
  {
    id: 895,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'ENTEROTOMIA E/OU ENTERORRAFIA C/ SUTURA / RESSECÇÃO ',
    size: 1,
  },
  {
    id: 896,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'EXCISÃO DE LESÃO INTESTINAL / MESENTÉRICA LOCALIZADA        ',
    size: 0,
  },
  {
    id: 897,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'FECHAMENTO DE COLOSTOMIA OU ENTEROSTOMIA ',
    size: 0,
  },
  {
    id: 898,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'FECHAMENTO DE FÍSTULA DE CÓLON',
    size: 0,
  },
  {
    id: 899,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'JEJUNOSTOMIA / ILEOSTOMIA',
    size: 0,
  },
  {
    id: 900,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'LAPAROTOMIA EXPLORADORA',
    size: 1,
  },
  {
    id: 901,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'LAPAROTOMIA VIDEOLAPAROSCÓPICA PARA DRENAGEM E/OU BIOPSIA',
    size: 0,
  },
  {
    id: 902,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'LIBERAÇÃO DE ADERÊNCIAS INTESTINAIS',
    size: 1,
  },
  {
    id: 903,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'LISE DE BRIDAS POR LAPAROSCOPIA',
    size: 0,
  },
  {
    id: 904,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'MEGACÓLON CONGÊNITO - TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 905,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'MEGACÓLON CONGÊNITO - TRATAMENTO CIRÚRGICO VIDEOLAPAROSCÓPICO',
    size: 1,
  },
  {
    id: 906,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'PROCTOCOLECTOMIA TOTAL ',
    size: 1,
  },
  {
    id: 907,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'PROCTOCOLECTOMIA TOTAL C/ RESERVATÓRIO ILEAL',
    size: 1,
  },
  {
    id: 908,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'PROCTOCOLECTOMIA TOTAL C/ RESERVATÓRIO ILEAL VIDEOLAPAROSCÓPICO',
    size: 1,
  },
  {
    id: 909,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'PROCTOPEXIA ABDOMINAL POR PROCIDÊNCIA DO RETO',
    size: 0,
  },
  {
    id: 910,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'REDUÇÃO CIRÚRGICA DE VOLVO POR LAPAROTOMIA',
    size: 1,
  },
  {
    id: 911,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'RESSECÇÃO ALARGADA DE TUMOR DE INTESTINO',
    size: 1,
  },
  {
    id: 912,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'RETOSSIGMOIDECTOMIA ABDOMINAL',
    size: 1,
  },
  {
    id: 913,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'RETOSSIGMOIDECTOMIA ABDOMINAL VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 914,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'RETOSSIGMOIDECTOMIA ABDOMINO-PERINEAL',
    size: 1,
  },
  {
    id: 915,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'REVASCULARIZAÇÃO DE ARTÉRIAS INTESTINAIS',
    size: 1,
  },
  {
    id: 916,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'TRATAMENTO CIRÚRGICO DE ATRESIA DUODENO E/OU JEJUNO',
    size: 1,
  },
  {
    id: 917,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'TRATAMENTO CIRÚRGICO DE DIVERTÍCULO DO TUBO DIGESTIVO',
    size: 0,
  },
  {
    id: 918,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'TRATAMENTO CIRÚRGICO DUPLICAÇÃO DO TUBO DIGESTIVO',
    size: 1,
  },
  {
    id: 919,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'TRATAMENTO CIRÚRGICO PERFURAÇÃO DUODENAL OU DELGADO',
    size: 1,
  },
  {
    id: 920,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'TRATAMENTO CIRÚRGICO RESSECÇÃO DE INVAGINAÇÃO INTESTINAL',
    size: 1,
  },
  {
    id: 921,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'TRATAMENTO DE MALFORMACOES CONGENITAS DO APARELHO DIGESTIVO',
    size: 1,
  },
  {
    id: 922,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'INTESTINOS',
    name: 'TUMOR ANORRETAL - RESSECÇÃO ANORRETAL, TRATAMENTO CIRÚRGICO',
    size: 1,
  },
  {
    id: 923,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'AMPUTAÇÃO ABDOMINO-PERINEAL DE RETO ',
    size: 1,
  },
  {
    id: 924,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'AMPUTAÇÃO COMPLETA ABDOMINO-PERINEAL DO RETO VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 925,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'AMPUTAÇÃO POR PROCIDÊNCIA DE RETO        ',
    size: 1,
  },
  {
    id: 926,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'BIOPSIA DE ÂNUS E CANAL ANAL',
    size: 0,
  },
  {
    id: 927,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'CERCLAGEM DE ÂNUS',
    size: 0,
  },
  {
    id: 928,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'DILATAÇÃO DIGITAL / INSTRUMENTAL DO ÂNUS E/OU RETO',
    size: 0,
  },
  {
    id: 929,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'DRENAGEM DE ABSCESSO ANORRETAL',
    size: 0,
  },
  {
    id: 930,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'DRENAGEM DE ABSCESSO ISQUIORRETAL',
    size: 0,
  },
  {
    id: 931,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'DRENAGEM DE HEMATOMA / ABSCESSO RETRO-RETAL',
    size: 0,
  },
  {
    id: 932,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'ELETROCAUTERIZAÇÃO DE LESÃO DE ÂNUS',
    size: 0,
  },
  {
    id: 933,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'ELETROCAUTERIZAÇÃO DE LESÃO TRANSPARIETAL DE ÂNUS',
    size: 0,
  },
  {
    id: 934,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'ESFINCTEROPLASTIA ANAL',
    size: 0,
  },
  {
    id: 935,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'ESFINCTEROTOMIA INTERNA E TRATAMENTO DE FISSURA ANAL',
    size: 0,
  },
  {
    id: 936,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'EXCISÃO DE LESÃO / TUMOR ANORRETAL',
    size: 0,
  },
  {
    id: 937,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'EXCISÃO DE PLICOMA',
    size: 0,
  },
  {
    id: 938,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'FECHAMENTO DE FÍSTULA DE RETO',
    size: 0,
  },
  {
    id: 939,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'FISSURECTOMIA COM OU SEM ESFINCTEROTOMIA',
    size: 0,
  },
  {
    id: 940,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'FISTULECTOMIA / FISTULOTOMIA ANAL',
    size: 0,
  },
  {
    id: 941,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'FIXAÇÃO DO RETO POR VIA ABDOMINAL',
    size: 1,
  },
  {
    id: 942,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'FIXAÇÃO DO RETO POR VIA ABDOMINAL POR VIDEOLAPAROSCOPIA',
    size: 1,
  },
  {
    id: 943,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'HEMORROIDECTOMIA',
    size: 0,
  },
  {
    id: 944,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'LACERAÇÃO ANORRETAL - TRATAMENTO CIRÚRGICO (VIA PERINEAL)',
    size: 0,
  },
  {
    id: 945,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'LIGADURA ELÁSTICA DE HEMORROIDAS',
    size: 0,
  },
  {
    id: 946,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'REMOÇÃO CIRURGICA DE FECALOMA',
    size: 0,
  },
  {
    id: 947,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'RETIRADA DE CORPO ESTRANHO / PÓLIPOS DO RETO ',
    size: 0,
  },
  {
    id: 948,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'TRATAMENTO CIRÚRGICO DE ANOMALIAS CONGÊNITAS DO ÂNUS E RETO',
    size: 0,
  },
  {
    id: 949,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'TRATAMENTO CIRÚRGICO DE AUSÊNCIA DO RETO',
    size: 1,
  },
  {
    id: 950,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'ÂNUS / RETO',
    name: 'TRATAMENTO CIRÚRGICO DE PROLAPSO RETAL / ANAL',
    size: 0,
  },
  {
    id: 951,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'ALCOOLIZAÇÃO PERCUTÂNEA DE TUMOR HEPÁTICO',
    size: 0,
  },
  {
    id: 952,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'ANASTOMOSE BILEO-DIGESTIVA',
    size: 1,
  },
  {
    id: 953,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'BIOPSIA DE FÍGADO EM CUNHA',
    size: 0,
  },
  {
    id: 954,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'BIOPSIA DE FÍGADO POR PUNÇÃO',
    size: 0,
  },
  {
    id: 955,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'BIOPSIA DE PÂNCREAS POR LAPAROTOMIA',
    size: 1,
  },
  {
    id: 956,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'BIOPSIA DE PÂNCREAS POR PUNÇÃO DIRIGIDA',
    size: 0,
  },
  {
    id: 957,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'BIOPSIA DE PÂNCREAS POR VIDEOLAPAROSCOPIA',
    size: 0,
  },
  {
    id: 958,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLANGIOGRAFIA TRANSCUTÂNEA',
    size: 0,
  },
  {
    id: 959,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLANGIOPANCREATOGRAFIA RETRÓGRADA ENDOSCÓPICA',
    size: 0,
  },
  {
    id: 960,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLECISTECTOMIA',
    size: 0,
  },
  {
    id: 961,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLECISTECTOMIA VIDEOLAPAROSCÓPICA COM COLANGIOGRAFIA',
    size: 0,
  },
  {
    id: 962,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLECISTECTOMIA VIDEOLAPAROSCÓPICA SEM COLANGIOGRAFIA',
    size: 0,
  },
  {
    id: 963,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLECISTOJEJUNOSTOMIA',
    size: 1,
  },
  {
    id: 964,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLECISTOJEJUNOSTOMIA VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 965,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLECISTOSTOMIA',
    size: 0,
  },
  {
    id: 966,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLEDOCO-DUODENOSTOMIA',
    size: 1,
  },
  {
    id: 967,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLEDOCO-DUODENOSTOMIA VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 968,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLEDOCOPLASTIA',
    size: 1,
  },
  {
    id: 969,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLEDOCOSTOMIA COM OU SEM COLECISTECTOMIA ',
    size: 0,
  },
  {
    id: 970,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLEDOCOTOMIA COM OU SEM COLECISTECTOMIA ',
    size: 0,
  },
  {
    id: 971,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'COLEDOCOTOMIA VIDEOLAPAROSCÓPICA COM OU SEM COLECISTECTOMIA',
    size: 0,
  },
  {
    id: 972,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'DESVASCULARIZAÇÃO HEPÁTICA',
    size: 1,
  },
  {
    id: 973,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'DILATAÇÃO PERCUTÂNEA DE ESTENOSES E ANASTOMOSES BILIARES',
    size: 0,
  },
  {
    id: 974,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'DRENAGEM BILIAR PERCUTÂNEA EXTERNA',
    size: 0,
  },
  {
    id: 975,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'DRENAGEM EXTERNA PSEUDOCISTO PÂNCREAS',
    size: 0,
  },
  {
    id: 976,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'DRENAGEM INTERNA PSEUDOCISTO DE PÂNCREAS (QUALQUER TÉCNICA)',
    size: 0,
  },
  {
    id: 977,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'ENUCLEAÇÃO DE TUMORES PANCREÁTICOS',
    size: 1,
  },
  {
    id: 978,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'ENUCLEAÇÃO DE TUMORES PANCREÁTICOS POR VÍDEO',
    size: 1,
  },
  {
    id: 979,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'HEPATECTOMIA PARCIAL',
    size: 1,
  },
  {
    id: 980,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'HEPATORRAFIA COM LESÃO DE VIAS BILIARES',
    size: 1,
  },
  {
    id: 981,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'HEPATOTOMIA E DRENAGEM DE ABSCESSO / CISTO',
    size: 0,
  },
  {
    id: 982,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'IMPLANTAÇÃO DE SHUNT INTRA-HEPÁTICO PORTO-SISTÊMICO (TIPS) ',
    size: 0,
  },
  {
    id: 983,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'MARSUPIALIZAÇÃO DE ABSCESSO / CISTO',
    size: 0,
  },
  {
    id: 984,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'METASTASECTOMIA HEPÁTICA ',
    size: 1,
  },
  {
    id: 985,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'PANCREATECTOMIA PARCIAL',
    size: 1,
  },
  {
    id: 986,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'PANCREATECTOMIA VIDEOLAPAROSCÓPICA   ',
    size: 1,
  },
  {
    id: 987,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'PANCREATODUODENECTOMIA',
    size: 1,
  },
  {
    id: 988,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'PANCREATO-ENTEROSTOMIA',
    size: 1,
  },
  {
    id: 989,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'PANCREATOTOMIA P/ DRENAGEM',
    size: 1,
  },
  {
    id: 990,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'PAPILOTOMIA TRANSDUODENAL',
    size: 0,
  },
  {
    id: 991,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'RADIOABLAÇÃO / TERMOABLAÇÃO DE TUMOR HEPÁTICO',
    size: 0,
  },
  {
    id: 992,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'RESSECÇÃO AMPLIADA DE VIA BILIAR EXTRA-HEPÁTICA ',
    size: 1,
  },
  {
    id: 993,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'RESSECÇÃO DE TUMOR DE VESÍCULA OU VIA BILIAR (COM OU SEM HEPATECTOMIA)',
    size: 1,
  },
  {
    id: 994,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'TRATAMENTO CIRÚRGICO DE ATRESIA DE VIAS BILIARES',
    size: 1,
  },
  {
    id: 995,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'TRATAMENTO CIRÚRGICO DE CISTOS PANCREÁTICOS',
    size: 1,
  },
  {
    id: 996,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'TRATAMENTO CIRÚRGICO DE ESTENOSE CICATRICIAL DAS VIAS BILIARES',
    size: 1,
  },
  {
    id: 997,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'TRATAMENTO CIRÚRGICO PÂNCREAS ANULAR',
    size: 1,
  },
  {
    id: 998,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'FÍGADO E VIAS BILIARES / PÂNCREAS',
    name: 'TRATAMENTO CIRÚRGICO PÂNCREAS ANULAR POR VÍDEO',
    size: 1,
  },
  {
    id: 999,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'ABDOMINOPLASTIA ',
    size: 0,
  },
  {
    id: 1000,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'BIOPSIA DE PAREDE ABDOMINAL',
    size: 0,
  },
  {
    id: 1001,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'BIOPSIA ESPLÊNICA',
    size: 0,
  },
  {
    id: 1002,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'BIOPSIAS MÚLTIPLAS INTRA-ABDOMINAIS',
    size: 0,
  },
  {
    id: 1003,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'DERMOLIPECTOMIA ABDOMINAL ',
    size: 0,
  },
  {
    id: 1004,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'DIÁSTASE DOS RETOS ABDOMINAIS - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 1005,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'DRENAGEM DE HEMATOMA / ABSCESSO PRÉ-PERITONEAL',
    size: 0,
  },
  {
    id: 1006,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'EPIPLOPLASTIA',
    size: 0,
  },
  {
    id: 1007,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIOPLASTIA EPIGÁSTRICA',
    size: 0,
  },
  {
    id: 1008,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIOPLASTIA EPIGÁSTRICA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1009,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIOPLASTIA INCISIONAL',
    size: 0,
  },
  {
    id: 1010,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIOPLASTIA INGUINAL ',
    size: 0,
  },
  {
    id: 1011,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIOPLASTIA RECIDIVANTE',
    size: 0,
  },
  {
    id: 1012,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIOPLASTIA UMBILICAL',
    size: 0,
  },
  {
    id: 1013,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIORRAFIA C/ RESSECÇÃO INTESTINAL (ESTRANGULADA) ',
    size: 1,
  },
  {
    id: 1014,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIORRAFIA CRURAL',
    size: 0,
  },
  {
    id: 1015,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIORRAFIA DE HERNIA FEMORAL ENCARCERADA',
    size: 0,
  },
  {
    id: 1016,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIORRAFIA INGUINAL VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1017,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIORRAFIA LOMBAR',
    size: 0,
  },
  {
    id: 1018,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIORRAFIA S/ RESSECÇÃO INTESTINAL ',
    size: 0,
  },
  {
    id: 1019,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'HERNIORRAFIA UMBILICAL VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1020,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'LIPOASPIRAÇÃO DE PAREDE ABDOMINAL OU DORSO',
    size: 0,
  },
  {
    id: 1021,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'OMENTECTOMIA ',
    size: 1,
  },
  {
    id: 1022,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'PARACENTESE ABDOMINAL',
    size: 0,
  },
  {
    id: 1023,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'PERITONIOSTOMIA C/ TELA INORGÂNICA',
    size: 0,
  },
  {
    id: 1024,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'RECONSTRUÇÃO DA PAREDE ABDOMINAL COM RETALHO MUSCULAR OU MIOCUTÂNEO',
    size: 0,
  },
  {
    id: 1025,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'REPARAÇÃO DE OUTRAS HÉRNIAS',
    size: 0,
  },
  {
    id: 1026,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'RESSECÇÃO ALARGADA DE TUMOR DE PARTES MOLES DE PAREDE ABDOMINAL',
    size: 0,
  },
  {
    id: 1027,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'RESSECÇÃO DE NEUROBLASTOMA ABDOMINAL',
    size: 1,
  },
  {
    id: 1028,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'RESSECÇÃO DE TUMOR RETROPERITONIAL ',
    size: 1,
  },
  {
    id: 1029,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'RESSECÇÃO DO EPIPLOM',
    size: 1,
  },
  {
    id: 1030,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'RESSUTURA DE PAREDE ABDOMINAL (POR DEISCÊNCIA TOTAL / EVISCERAÇÃO)',
    size: 1,
  },
  {
    id: 1031,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'TRATAMENTO CIRÚRGICO DE PERITONITE',
    size: 1,
  },
  {
    id: 1032,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'TRATAMENTO DE DOENÇAS DO PERITÔNEO',
    size: 1,
  },
  {
    id: 1033,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'VIDEOLAPAROSCOPIA',
    size: 0,
  },
  {
    id: 1034,
    general: 'SISTEMA DIGESTIVO E ANEXOS',
    specific: 'PERITÔNIO / PAREDE E CAVIDADE ABDOMINAL',
    name: 'VIDEOLAPAROSCOPIA (SEM RESSECÇÃO INTESTINAL)',
    size: 0,
  },
  {
    id: 1035,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'ADRENALECTOMIA BILATERAL',
    size: 1,
  },
  {
    id: 1036,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'ADRENALECTOMIA UNILATERAL',
    size: 1,
  },
  {
    id: 1037,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'ADRENALECTOMIA UNILATERAL VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1038,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'BIOPSIA DE RIM POR ASPIRAÇÃO',
    size: 0,
  },
  {
    id: 1039,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'BIOPSIA RENAL CIRÚRGICA',
    size: 0,
  },
  {
    id: 1040,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'CAPSULECTOMIA RENAL',
    size: 0,
  },
  {
    id: 1041,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'CISTO RENAL - ESCLEROTERAPIA PERCUTÂNEA',
    size: 0,
  },
  {
    id: 1042,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'DRENAGEM PERCUTÂNEA DE ABSCESSO RENAL / PERI-RENAL',
    size: 0,
  },
  {
    id: 1043,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'EXÉRESE DE TUMOR RENAL',
    size: 1,
  },
  {
    id: 1044,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'EXTRAÇÃO ENDOSCÓPICA DE CÁLCULO EM PELVE RENAL',
    size: 0,
  },
  {
    id: 1045,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'LITOTRIPSIA EXTRACORPÓREA (ONDA DE CHOQUE)',
    size: 0,
  },
  {
    id: 1046,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'LOMBOTOMIA EXPLORADORA',
    size: 1,
  },
  {
    id: 1047,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFRECTOMIA PARCIAL',
    size: 1,
  },
  {
    id: 1048,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFRECTOMIA PARCIAL VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 1049,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFRECTOMIA TOTAL',
    size: 1,
  },
  {
    id: 1050,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFRECTOMIA TOTAL VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 1051,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFROLITOTOMIA',
    size: 0,
  },
  {
    id: 1052,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFROLITOTOMIA PERCUTÂNEA',
    size: 0,
  },
  {
    id: 1053,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFROLITOTRIPSIA PERCUTÂNEA',
    size: 0,
  },
  {
    id: 1054,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFROPIELOSTOMIA',
    size: 0,
  },
  {
    id: 1055,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFRORRAFIA',
    size: 0,
  },
  {
    id: 1056,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFROSTOMIA PERCUTÂNEA',
    size: 0,
  },
  {
    id: 1057,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'NEFROURETERECTOMIA TOTAL ',
    size: 1,
  },
  {
    id: 1058,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'PIELOENTEROCISTOSTOMIA ',
    size: 0,
  },
  {
    id: 1059,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'PIELOGRAFIA ANTERÓGRADA PERCUTÂNEA',
    size: 0,
  },
  {
    id: 1060,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'PIELOGRAFIA ASCENDENTE',
    size: 0,
  },
  {
    id: 1061,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'PIELOLITOTOMIA',
    size: 0,
  },
  {
    id: 1062,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'PIELOPLASTIA',
    size: 0,
  },
  {
    id: 1063,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'PIELOTOMIA ',
    size: 0,
  },
  {
    id: 1064,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'REVASCULARIZAÇÃO RENAL',
    size: 1,
  },
  {
    id: 1065,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'SINFISIOTOMIA (RIM EM FERRADURA)',
    size: 0,
  },
  {
    id: 1066,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'TRANSPLANTE DE RIM',
    size: 1,
  },
  {
    id: 1067,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'TRATAMENTO CIRÚRGICO DE CISTO DE RIM POR PUNÇÃO',
    size: 0,
  },
  {
    id: 1068,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'TRATAMENTO CIRÚRGICO ESTENOSE PIELOURETERAL',
    size: 0,
  },
  {
    id: 1069,
    general: 'SISTEMA URINÁRIO',
    specific: 'RIM / SUPRARRENAL',
    name: 'TRATAMENTO CIRÚRGICO FÍSTULA PIELO-CUTÂNEA',
    size: 0,
  },
  {
    id: 1070,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'BIOPSIA DE URETER',
    size: 0,
  },
  {
    id: 1071,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'DILATAÇÃO PERCUTÂNEA DE ESTENOSES URETERAIS E JUNÇÃO URÉTERO-VESICAL',
    size: 0,
  },
  {
    id: 1072,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'EXTRAÇÃO ENDOSCÓPICA DE CORPO ESTRANHO / CÁLCULO EM URETER',
    size: 0,
  },
  {
    id: 1073,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'IMPLANTE DE CATETER URETERAL POR TÉCNICA CISTOSCÓPICA',
    size: 0,
  },
  {
    id: 1074,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'IMPLANTE URETEROINTESTINAL VIDEOLAPAROSCÓPICO',
    size: 0,
  },
  {
    id: 1075,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'INSTALAÇÃO ENDOSCÓPICA DE CATETER DUPLO J',
    size: 0,
  },
  {
    id: 1076,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'LITOTRIPSIA',
    size: 0,
  },
  {
    id: 1077,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'RESSECÇÃO ENDOSCÓPICA DA EXTREMIDADE DISTAL DO URETER',
    size: 0,
  },
  {
    id: 1078,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'RETIRADA PERCUTÂNEA DE CÁLCULO URETERAL ',
    size: 0,
  },
  {
    id: 1079,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULAS URETERAIS',
    size: 0,
  },
  {
    id: 1080,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'TRATAMENTO CIRÚRGICO DE URETEROCELE',
    size: 0,
  },
  {
    id: 1081,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'TRATAMENTO ENDOSCÓPICO DE URETEROCELE',
    size: 0,
  },
  {
    id: 1082,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETERECTOMIA',
    size: 0,
  },
  {
    id: 1083,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROCISTONEOSTOMIA',
    size: 0,
  },
  {
    id: 1084,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROENTEROPLASTIA',
    size: 0,
  },
  {
    id: 1085,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROENTEROSTOMIA',
    size: 0,
  },
  {
    id: 1086,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROILEOCISTOSTOMIA ',
    size: 0,
  },
  {
    id: 1087,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETERÓLISE',
    size: 0,
  },
  {
    id: 1088,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROLITOTOMIA',
    size: 0,
  },
  {
    id: 1089,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROPLASTIA',
    size: 0,
  },
  {
    id: 1090,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROSSIGMOIDOSPLASTIA',
    size: 0,
  },
  {
    id: 1091,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROSTOMIA CUTÂNEA',
    size: 0,
  },
  {
    id: 1092,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETER',
    name: 'URETEROURETEROSTOMIA UNILATERAL',
    size: 0,
  },
  {
    id: 1093,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'BIOPSIA DE BEXIGA',
    size: 0,
  },
  {
    id: 1094,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CAUTERIZAÇÃO QUÍMICA DA BEXIGA',
    size: 0,
  },
  {
    id: 1095,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTECTOMIA PARCIAL',
    size: 1,
  },
  {
    id: 1096,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTECTOMIA PARCIAL VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 1097,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTECTOMIA TOTAL',
    size: 1,
  },
  {
    id: 1098,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTECTOMIA TOTAL E DERIVAÇÃO',
    size: 1,
  },
  {
    id: 1099,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTECTOMIA TOTAL VIDEOLAPAROSCÓPICA',
    size: 1,
  },
  {
    id: 1100,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTOENTEROPLASTIA ',
    size: 1,
  },
  {
    id: 1101,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTOLITOTOMIA E/OU RETIRADA DE CORPO ESTRANHO DA BEXIGA',
    size: 0,
  },
  {
    id: 1102,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTOLITOTRIPSIA ',
    size: 0,
  },
  {
    id: 1103,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTOPLASTIA (CORREÇÃO DE EXTROFIA VESICAL)',
    size: 1,
  },
  {
    id: 1104,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTOPLASTIA REDUTORA',
    size: 1,
  },
  {
    id: 1105,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTORRAFIA',
    size: 0,
  },
  {
    id: 1106,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTOSCOPIA E/OU URETEROSCOPIA E/OU URETROSCOPIA',
    size: 0,
  },
  {
    id: 1107,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'CISTOSTOMIA',
    size: 0,
  },
  {
    id: 1108,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'DIVERTICULECTOMIA VESICAL',
    size: 0,
  },
  {
    id: 1109,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'DIVERTICULECTOMIA VESICAL VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1110,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'EXTRAÇÃO ENDOSCÓPICA DE CÁLCULO VESICAL',
    size: 0,
  },
  {
    id: 1111,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'INJEÇÃO INTRAVESICAL DE TOXINA BOTULÍNICA',
    size: 0,
  },
  {
    id: 1112,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'LIGADURA / SECÇÃO DE VASOS ABERRANTES',
    size: 0,
  },
  {
    id: 1113,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'RESSECÇÃO DO COLO VESICAL',
    size: 0,
  },
  {
    id: 1114,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'RESSECÇÃO ENDOSCÓPICA DE LESÃO VESICAL',
    size: 0,
  },
  {
    id: 1115,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'RESSECÇÃO ENDOSCÓPICA DE TUMOR VESICAL ',
    size: 0,
  },
  {
    id: 1116,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TRATAMENTO CIRÚRGICO DE BEXIGA NEUROGÊNICA / AMPLIAÇÃO VESICAL',
    size: 1,
  },
  {
    id: 1117,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TRATAMENTO CIRÚRGICO DE CISTOCELE',
    size: 0,
  },
  {
    id: 1118,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA VÉSICO-CUTÂNEA',
    size: 0,
  },
  {
    id: 1119,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA VÉSICO-ENTÉRICA',
    size: 0,
  },
  {
    id: 1120,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA VÉSICO-UTERINA',
    size: 0,
  },
  {
    id: 1121,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TRATAMENTO CIRÚRGICO DE HEMORRAGIA VESICAL ',
    size: 0,
  },
  {
    id: 1122,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TRATAMENTO CIRÚRGICO DE REFLUXO VÉSICO-URETERAL',
    size: 0,
  },
  {
    id: 1123,
    general: 'SISTEMA URINÁRIO',
    specific: 'BEXIGA',
    name: 'TUMOR VESICAL - FOTOCOAGULAÇÃO A LASER',
    size: 0,
  },
  {
    id: 1124,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'BIOPSIA DE URETRA',
    size: 0,
  },
  {
    id: 1125,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'EXTRAÇÃO ENDOSCÓPICA DE CORPO ESTRANHO / CÁLCULO URETRAL',
    size: 0,
  },
  {
    id: 1126,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'MEATOTOMIA SIMPLES',
    size: 0,
  },
  {
    id: 1127,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'MEATOPLASTIA COM RETALHO ',
    size: 0,
  },
  {
    id: 1128,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'RESSECÇÃO E FECHAMENTO DE FÍSTULA URETRAL',
    size: 0,
  },
  {
    id: 1129,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'TRATAMENTO CIRÚRGICO DIVERTÍCULO URETRAL',
    size: 0,
  },
  {
    id: 1130,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'URETROPLASTIA ',
    size: 0,
  },
  {
    id: 1131,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'URETROTOMIA INTERNA',
    size: 0,
  },
  {
    id: 1132,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'URETROTOMIA P/ RETIRADA DE CÁLCULO OU CORPO ESTRANHO',
    size: 0,
  },
  {
    id: 1133,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'URETROSCISTOSCOPIA',
    size: 0,
  },
  {
    id: 1134,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'URETRORRAFIA',
    size: 0,
  },
  {
    id: 1135,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'URETRECTOMIA TOTAL',
    size: 0,
  },
  {
    id: 1136,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'RESSECÇÃO DE CARÚNCULA URETRAL',
    size: 0,
  },
  {
    id: 1137,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'RESSECÇÃO DE VÁLVULA URETRAL POSTERIOR',
    size: 0,
  },
  {
    id: 1138,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'RESSECÇÃO DE PROLAPSO DA MUCOSA DA URETRA',
    size: 0,
  },
  {
    id: 1139,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'DRENAGEM DE COLEÇÃO PERI-URETRAL',
    size: 0,
  },
  {
    id: 1140,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'TRATAMENTO CIRÚRGICO FÍSTULA URETRO-CUTÂNEA',
    size: 0,
  },
  {
    id: 1141,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'TRATAMENTO CIRÚRGICO FÍSTULA URETRO-RETAL',
    size: 0,
  },
  {
    id: 1142,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'EXCISÃO DE TUMOR URETRAL',
    size: 0,
  },
  {
    id: 1143,
    general: 'SISTEMA URINÁRIO',
    specific: 'URETRA',
    name: 'TRATAMENTO CIRÚRGICO DE INCONTINÊNCIA URINÁRIA ',
    size: 0,
  },
  {
    id: 1144,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'ABLAÇÃO PROSTÁTICA A LASER',
    size: 0,
  },
  {
    id: 1145,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'BIOPSIA DE EPIDÍDIMO',
    size: 0,
  },
  {
    id: 1146,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'BIOPSIA DE PRÓSTATA',
    size: 0,
  },
  {
    id: 1147,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'DRENAGEM DE ABSCESSO DE PRÓSTATA',
    size: 0,
  },
  {
    id: 1148,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'DRENAGEM DE ABSCESSO DO EPIDÍDIMO E/OU CANAL DEFERENTE',
    size: 0,
  },
  {
    id: 1149,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'EPIDIDIMECTOMIA',
    size: 0,
  },
  {
    id: 1150,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'ESPERMATOCELECTOMIA',
    size: 0,
  },
  {
    id: 1151,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'EXÉRESE DE CISTO DE EPIDÍDIMO',
    size: 0,
  },
  {
    id: 1152,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'EXÉRESE DE VESÍCULA SEMINAL',
    size: 0,
  },
  {
    id: 1153,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'EXPLORAÇÃO CIRÚRGICA DO CANAL DEFERENTE',
    size: 0,
  },
  {
    id: 1154,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'PROSTATECTOMIA SUPRAPÚBICA',
    size: 1,
  },
  {
    id: 1155,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'PROSTATOVESICULECTOMIA RADICAL ',
    size: 1,
  },
  {
    id: 1156,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'PROSTATOVESICULECTOMIA RADICAL VIDEOLAPAROSCÓPICA ',
    size: 1,
  },
  {
    id: 1157,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'RECANALIZAÇÃO DOS DUCTOS DEFERENTES',
    size: 0,
  },
  {
    id: 1158,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'RESSECÇÃO ENDOSCÓPICA DE PRÓSTATA',
    size: 0,
  },
  {
    id: 1159,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'REVISÃO ENDOSCÓPICA DE HEMORRAGIA LOJA PROSTÁTICA',
    size: 0,
  },
  {
    id: 1160,
    general: 'SISTEMA GENITAL ',
    specific: 'PRÓSTATA / CORDÃO ESPERMÁTICO',
    name: 'VASECTOMIA',
    size: 0,
  },
  {
    id: 1161,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'AMPUTAÇÃO DE PÊNIS',
    size: 0,
  },
  {
    id: 1162,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'BIOPSIA DE PÊNIS',
    size: 0,
  },
  {
    id: 1163,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'CORREÇÃO CIRÚRGICA DE PARAFIMOSE',
    size: 0,
  },
  {
    id: 1164,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'CORREÇÃO DE EPISPADIA/HIPOSPADIA',
    size: 0,
  },
  {
    id: 1165,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'IMPLANTE DE PRÓTESE PENIANA ',
    size: 0,
  },
  {
    id: 1166,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'PLÁSTICA DE FREIO BALANO-PREPUCIAL',
    size: 0,
  },
  {
    id: 1167,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'PLÁSTICA TOTAL DO PÊNIS',
    size: 0,
  },
  {
    id: 1168,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'POSTECTOMIA',
    size: 0,
  },
  {
    id: 1169,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'REDESIGNAÇÃO SEXUAL',
    size: 0,
  },
  {
    id: 1170,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'REIMPLANTE DE PÊNIS',
    size: 0,
  },
  {
    id: 1171,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'REVASCULARIZAÇÃO PENIANA',
    size: 0,
  },
  {
    id: 1172,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'TRATAMENTO CIRÚRGICO DE PRIAPRISMO',
    size: 0,
  },
  {
    id: 1173,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'TRATAMENTO CIRÚRGICO DOENÇA DE PEYRONIE',
    size: 0,
  },
  {
    id: 1174,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'TRATAMENTO CIRÚRGICO FRATURA PENIANA',
    size: 0,
  },
  {
    id: 1175,
    general: 'SISTEMA GENITAL ',
    specific: 'PÊNIS',
    name: 'TRATAMENTO CIRÚRGICO INCONTINÊNCIA URINÁRIA MASCULINA',
    size: 0,
  },
  {
    id: 1176,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'AUTOTRANSPLANTE DE UM TESTÍCULO',
    size: 0,
  },
  {
    id: 1177,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'BIOPSIA DE ESCROTO / TESTÍCULO',
    size: 0,
  },
  {
    id: 1178,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'BIOPSIA DE TESTÍCULO',
    size: 0,
  },
  {
    id: 1179,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'ESCROTO AGUDO - EXPLORAÇÃO CIRÚRGICA',
    size: 0,
  },
  {
    id: 1180,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'EXÉRESE DE CISTO DE BOLSA ESCROTAL',
    size: 0,
  },
  {
    id: 1181,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'EXPLORAÇÃO CIRÚRGICA DA BOLSA ESCROTAL',
    size: 0,
  },
  {
    id: 1182,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'IMPLANTE DE PRÓTESE TESTICULAR ',
    size: 0,
  },
  {
    id: 1183,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'ORQUIDOPEXIA ',
    size: 0,
  },
  {
    id: 1184,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'ORQUIECTOMIA ',
    size: 0,
  },
  {
    id: 1185,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'PLÁSTICA DA BOLSA ESCROTAL',
    size: 0,
  },
  {
    id: 1186,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'RECONSTRUÇÃO DE ESCROTO COM RETALHO INGUINAL PEDICULADO',
    size: 0,
  },
  {
    id: 1187,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'RESSECÇÃO PARCIAL DA BOLSA ESCROTAL',
    size: 0,
  },
  {
    id: 1188,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'RESSECÇÃO TUMOR DE TESTÍCULO',
    size: 0,
  },
  {
    id: 1189,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'TRATAMENTO CIRÚRGICO DE HIDROCELE',
    size: 0,
  },
  {
    id: 1190,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'TRATAMENTO CIRÚRGICO DE TORÇÃO DO TESTÍCULO',
    size: 0,
  },
  {
    id: 1191,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'TRATAMENTO CIRÚRGICO DE VARICOCELE',
    size: 0,
  },
  {
    id: 1192,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'TRATAMENTO CIRÚRGICO ELEFANTÍASE PENO-ESCROTAL',
    size: 0,
  },
  {
    id: 1193,
    general: 'SISTEMA GENITAL ',
    specific: 'ESCROTO / TESTÍCULO ',
    name: 'TRATAMENTO CIRÚRGICO VARICOCELE',
    size: 0,
  },
  {
    id: 1194,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'ALARGAMENTO DA ENTRADA VAGINAL',
    size: 0,
  },
  {
    id: 1195,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'BIOPSIA/PUNÇÃO DE VAGINA',
    size: 0,
  },
  {
    id: 1196,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'BIOPSIA/PUNÇÃO DE VULVA',
    size: 0,
  },
  {
    id: 1197,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'CIRURGIA DO PROLAPSO DE CÚPULA VAGINAL',
    size: 0,
  },
  {
    id: 1198,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'CLITORECTOMIA / CLITOROPLASTIA',
    size: 0,
  },
  {
    id: 1199,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'COLPOCLEISE (CIRURGIA DE LE FORT)',
    size: 0,
  },
  {
    id: 1200,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'COLPOPERINEOCLEISE',
    size: 0,
  },
  {
    id: 1201,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'COLPOPERINEOPLASTIA ',
    size: 0,
  },
  {
    id: 1202,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'COLPOPLASTIA',
    size: 0,
  },
  {
    id: 1203,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'COLPORRAFIA',
    size: 0,
  },
  {
    id: 1204,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'COLPOTOMIA OU CULDOCENTESE',
    size: 0,
  },
  {
    id: 1205,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'CONSTRUÇÃO DE VAGINA',
    size: 0,
  },
  {
    id: 1206,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'CORREÇÃO CIRÚRGICA DE HIPERTROFIA DOS PEQUENOS LÁBIOS',
    size: 0,
  },
  {
    id: 1207,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'EPISIOTOMIA / EPISIOPERINEORRAFIA ',
    size: 0,
  },
  {
    id: 1208,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'EXÉRESE DE CISTO VAGINAL',
    size: 0,
  },
  {
    id: 1209,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'EXÉRESE / DRENAGEM DE GLÂNDULA DE BARTHOLIN ',
    size: 0,
  },
  {
    id: 1210,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'EXTIRPAÇÃO DE LESÃO DE VULVA / PERINEO ',
    size: 0,
  },
  {
    id: 1211,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'EXTRAÇÃO DE CORPO ESTRANHO DA VAGINA',
    size: 0,
  },
  {
    id: 1212,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'HIMENOTOMIA',
    size: 0,
  },
  {
    id: 1213,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'MARSUPIALIZAÇÃO DE GLÂNDULA DE BARTHOLIN',
    size: 0,
  },
  {
    id: 1214,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'OPERAÇÃO DE BURCH',
    size: 0,
  },
  {
    id: 1215,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'RECONSTRUÇÃO PERINEAL COM RETALHOS MIOCUTÂNEIS',
    size: 0,
  },
  {
    id: 1216,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'RESSECÇÃO TUMOR DO SEPTO RETO-VAGINAL',
    size: 0,
  },
  {
    id: 1217,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'SUTURA DE LACERAÇÕES DE TRAJETO PÉLVICO',
    size: 0,
  },
  {
    id: 1218,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'TRATAMENTO CIRÚRGICO DE COAPTAÇÃO DE NINFAS',
    size: 0,
  },
  {
    id: 1219,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA RETO-VAGINAL',
    size: 0,
  },
  {
    id: 1220,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA URETRO-VAGINAL',
    size: 0,
  },
  {
    id: 1221,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA VÉSICO-VAGINAL',
    size: 0,
  },
  {
    id: 1222,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'TRATAMENTO CIRÚRGICO DE HIPERTROFIA DOS PEQUENOS LÁBIOS',
    size: 0,
  },
  {
    id: 1223,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'TRATAMENTO CIRÚRGICO DE VAGINA SEPTADA / ATRÉSICA      ',
    size: 0,
  },
  {
    id: 1224,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'VULVECTOMIA AMPLIADA C/ LINFADENECTOMIA',
    size: 0,
  },
  {
    id: 1225,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'VULVECTOMIA PARCIAL',
    size: 0,
  },
  {
    id: 1226,
    general: 'SISTEMA GENITAL ',
    specific: 'VULVA / VAGINA',
    name: 'VULVECTOMIA SIMPLES',
    size: 0,
  },
  {
    id: 1227,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'AMNIORREDUÇÃO OU AMNIOINFUSÃO',
    size: 0,
  },
  {
    id: 1228,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'AMPUTAÇÃO CÔNICA DE COLO DE ÚTERO COM COLPECTOMIA ',
    size: 0,
  },
  {
    id: 1229,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'ASSISTÊNCIA AO TRABALHO DE PARTO ',
    size: 0,
  },
  {
    id: 1230,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'BIOPSIA DE ENDOMÉTRIO',
    size: 0,
  },
  {
    id: 1231,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'BIOPSIA DO COLO UTERINO',
    size: 0,
  },
  {
    id: 1232,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'CERCLAGEM DE COLO UTERINO',
    size: 0,
  },
  {
    id: 1233,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'CESAREA',
    size: 0,
  },
  {
    id: 1234,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'CIRURGIA FETAL A CÉU ABERTO',
    size: 1,
  },
  {
    id: 1235,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'CIRURGIA FETAL ENDOSCÓPICA',
    size: 0,
  },
  {
    id: 1236,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'COLPECTOMIA',
    size: 0,
  },
  {
    id: 1237,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'COLPOPERINEOPLASTIA ANTERIOR E POSTERIOR C/ AMPUTAÇÃO DE COLO',
    size: 0,
  },
  {
    id: 1238,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'CURETAGEM PÓS-ABORTAMENTO / PUERPERAL',
    size: 0,
  },
  {
    id: 1239,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'CURETAGEM SEMIÓTICA',
    size: 0,
  },
  {
    id: 1240,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'CURETAGEM UTERINA EM MOLA HIDATIFORME',
    size: 0,
  },
  {
    id: 1241,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'DILATAÇÃO DE COLO DO ÚTERO',
    size: 0,
  },
  {
    id: 1242,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'ASPIRAÇÃO MANUAL INTRA-UTERINA (AMIU)        ',
    size: 0,
  },
  {
    id: 1243,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'EXCISÃO DE PÓLIPO CERVICAL',
    size: 0,
  },
  {
    id: 1244,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'EXCISÃO DO COLO UTERINO',
    size: 0,
  },
  {
    id: 1245,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'EXÉRESE DE PÓLIPO DE ÚTERO',
    size: 0,
  },
  {
    id: 1246,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA (POR VIA VAGINAL)',
    size: 0,
  },
  {
    id: 1247,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA C/ ANEXECTOMIA ',
    size: 0,
  },
  {
    id: 1248,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA COM RESSECÇÃO DE ÓRGÃOS CONTÍGUOS',
    size: 1,
  },
  {
    id: 1249,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA PÓS-PARTO',
    size: 0,
  },
  {
    id: 1250,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA SUBTOTAL',
    size: 0,
  },
  {
    id: 1251,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA TOTAL',
    size: 0,
  },
  {
    id: 1252,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA TOTAL AMPLIADA (WERTHEIN-MEIGS)',
    size: 1,
  },
  {
    id: 1253,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERECTOMIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1254,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTERORRAFIA',
    size: 0,
  },
  {
    id: 1255,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTEROSCOPIA (DIAGNÓSTICA)',
    size: 0,
  },
  {
    id: 1256,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'HISTEROSCOPIA CIRÚRGICA',
    size: 0,
  },
  {
    id: 1257,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'INSEMINAÇÃO ARTIFICIAL',
    size: 0,
  },
  {
    id: 1258,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'IMPLANTE OU REMOÇÃO DE DISPOSITIVO INTRA-UTERINO (DIU)',
    size: 0,
  },
  {
    id: 1259,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'INVERSÃO UTERINA - TRATAMENTO CIRÚRGICO',
    size: 0,
  },
  {
    id: 1260,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'LAQUEADURA TUBARIA',
    size: 0,
  },
  {
    id: 1261,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'METROPLASTIA (STRASSMANN OU OUTRA TÉCNICA)',
    size: 0,
  },
  {
    id: 1262,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'MIOMECTOMIA',
    size: 0,
  },
  {
    id: 1263,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'MIOMECTOMIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1264,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'TRAQUELECTOMIA',
    size: 0,
  },
  {
    id: 1265,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'TRAQUELECTOMIA RADICAL',
    size: 0,
  },
  {
    id: 1266,
    general: 'SISTEMA GENITAL ',
    specific: 'ÚTERO',
    name: 'TRAQUELOPLASTIA',
    size: 0,
  },
  {
    id: 1267,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'ANEXECTOMIA UNI / BILATERAL ',
    size: 0,
  },
  {
    id: 1268,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'ASPIRAÇÃO DE FOLÍCULOS PARA FERTILIZAÇÃO',
    size: 0,
  },
  {
    id: 1269,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'CIRURGIA ESTERILIZADORA FEMININA',
    size: 0,
  },
  {
    id: 1270,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'LIGADURA VEIA OVARIANA',
    size: 0,
  },
  {
    id: 1271,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'NEOPLASIA DE OVÁRIO (DEBULKING)',
    size: 1,
  },
  {
    id: 1272,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'NEOPLASIA DE OVÁRIO (DEBULKING) POR VÍDEO',
    size: 1,
  },
  {
    id: 1273,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'NEOSSALPINGOSTOMIA DISTAL',
    size: 0,
  },
  {
    id: 1274,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'OOFORECTOMIA / OOFOROPLASTIA',
    size: 0,
  },
  {
    id: 1275,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'RECANALIZAÇÃO TUBÁRIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1276,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'SALPINGECTOMIA ',
    size: 0,
  },
  {
    id: 1277,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'SALPINGECTOMIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1278,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'SALPINGOPLASTIA',
    size: 0,
  },
  {
    id: 1279,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'SALPINGOPLASTIA VIDEOLAPAROSCÓPICA',
    size: 0,
  },
  {
    id: 1280,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'TRANSLOCAÇÃO DE OVÁRIOS',
    size: 0,
  },
  {
    id: 1281,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'TRATAMENTO CIRÚRGICO DE GRAVIDEZ ECTÓPICA',
    size: 0,
  },
  {
    id: 1282,
    general: 'SISTEMA GENITAL ',
    specific: 'TUBAS / OVÁRIOS ',
    name: 'TRATAMENTO CIRÚRGICO GRAVIDEZ ECTÓPICA VIDEOLAPAROSCÓPICO',
    size: 0,
  },
  {
    id: 1283,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'DRENAGEM DE ABSCESSO PELVICO',
    size: 0,
  },
  {
    id: 1284,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'EXENTERAÇÃO PÉLVICA TOTAL ',
    size: 1,
  },
  {
    id: 1285,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'HEMIPELVECTOMIA',
    size: 1,
  },
  {
    id: 1286,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'LIBERAÇÃO DE ADERÊNCIAS PÉLVICAS ',
    size: 0,
  },
  {
    id: 1287,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'LIGAMENTOPEXIA PÉLVICA',
    size: 0,
  },
  {
    id: 1288,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'LINFADENECTOMIA PÉLVICA',
    size: 0,
  },
  {
    id: 1289,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'RESSECÇÃO OU LIGADURA DE VARIZES PÉLVICAS',
    size: 0,
  },
  {
    id: 1290,
    general: 'SISTEMA GENITAL ',
    specific: 'CAVIDADE E PAREDES PÉLVICAS',
    name: 'TRATAMENTO CIRÚRGICO ENDOMETRIOSE PERITONEAL',
    size: 0,
  },
  {
    id: 1291,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'BIOPSIA ESTEREOTÁXICA',
    size: 0,
  },
  {
    id: 1292,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'CINGULOTOMIA OU CAPSULOTOMIA UNILATERAL',
    size: 0,
  },
  {
    id: 1293,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'CRANIOTOMIA PARA RETIRADA DE TUMOR CEREBRAL',
    size: 1,
  },
  {
    id: 1294,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'DERIVAÇÃO VENTRICULAR EXTERNA',
    size: 0,
  },
  {
    id: 1295,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'DERIVAÇÃO VENTRICULAR PARA PERITÔNEO / ÁTRIO / PLEURA / RAQUE',
    size: 0,
  },
  {
    id: 1296,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'DRENAGEM DE ABSCESSO CEREBRAL',
    size: 1,
  },
  {
    id: 1297,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'DRENAGEM DE HEMATOMA CEREBRAL',
    size: 1,
  },
  {
    id: 1298,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'EMBOLIZAÇÃO DE TUMOR INTRA-CRANIANO OU DA CABECA E PESCOÇO',
    size: 0,
  },
  {
    id: 1299,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'HIPOFISECTOMIA TRANSESFENOIDAL ENDOSCÓPICA ',
    size: 1,
  },
  {
    id: 1300,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'IMPLANTE DE ELETRODO PARA ESTIMULAÇÃO CEREBRAL',
    size: 0,
  },
  {
    id: 1301,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'MICROCIRURGIA CEREBRAL ENDOSCÓPICA',
    size: 0,
  },
  {
    id: 1302,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'MICROCIRURGIA DE TUMOR DE BASE DO CRÂNIO',
    size: 1,
  },
  {
    id: 1303,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'MICROCIRURGIA PARA ANEURISMA DA CIRCULAÇÃO CEREBRAL ',
    size: 1,
  },
  {
    id: 1304,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'MICROCIRURGIA PARA LOBECTOMIA TEMPORAL / AMIGDALO-HIPOCAMPECTOMIA SELETIVA ',
    size: 1,
  },
  {
    id: 1305,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'MICROCIRURGIA PARA MALFORMAÇÃO ARTÉRIO-VENOSA CEREBRAL',
    size: 1,
  },
  {
    id: 1306,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'MICROCIRURGIA PARA TUMOR INTRACRANIANO ',
    size: 1,
  },
  {
    id: 1307,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'MICROCIRURGIA VASCULAR INTRACRANIANA',
    size: 1,
  },
  {
    id: 1308,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'RETIRADA/REVISÃO DE DERIVAÇÃO VENTRICULAR PARA PERITÔNEO / ATRIO / PLEURA / RAQUE',
    size: 0,
  },
  {
    id: 1309,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TERCEIROVENTRICULOSTOMIA',
    size: 0,
  },
  {
    id: 1310,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TRATAMENTO CIRÚRGICO DA EPILEPSIA',
    size: 0,
  },
  {
    id: 1311,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TRATAMENTO CIRÚRGICO DE HEMATOMA EXTRADURAL',
    size: 1,
  },
  {
    id: 1312,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TRATAMENTO CIRÚRGICO DE HEMATOMA INTRACEREBRAL',
    size: 1,
  },
  {
    id: 1313,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TRATAMENTO CIRÚRGICO DE HEMATOMA SUBDURAL',
    size: 1,
  },
  {
    id: 1314,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TRATAMENTO CIRÚRGICO DE PLATIBASIA E MALFORMAÇÃO DE ARNOLD CHIARI',
    size: 1,
  },
  {
    id: 1315,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TRATAMENTO DE MOVIMENTO ANORMAL POR ESTEREOTAXIA  ',
    size: 0,
  },
  {
    id: 1316,
    general: 'SISTEMA NERVOSO ',
    specific: 'ENCÉFALO',
    name: 'TROCA DE GERADOR DE PULSOS PARA ESTIMULACAO CEREBRAL',
    size: 0,
  },
  {
    id: 1317,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'CORDOTOMIA / MIELOTOMIA',
    size: 0,
  },
  {
    id: 1318,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'CORREÇÃO DE MIELOMENINGOCELE',
    size: 0,
  },
  {
    id: 1319,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'DERIVAÇÃO LOMBO PERITONEAL',
    size: 0,
  },
  {
    id: 1320,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'DESCOMPRESSÃO MEDULAR E/OU CAUDA EQUINA',
    size: 1,
  },
  {
    id: 1321,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'EXPLORAÇÃO DIAGNÓSTICA CIRÚRGICA PARA IMPLANTAÇÃO BILATERAL DE ELETRODOS SUBDURAIS',
    size: 0,
  },
  {
    id: 1322,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'IMPLANTE INTRATECAL DE BOMBA DE INFUSÃO DE FÁRMACOS',
    size: 0,
  },
  {
    id: 1323,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'MICROCIRURGIA DA SIRINGOMIELIA',
    size: 1,
  },
  {
    id: 1324,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'MICROCIRURGIA DE TUMOR INTRADURAL E EXTRAMEDULAR',
    size: 0,
  },
  {
    id: 1325,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'MICROCIRURGIA DE TUMOR MEDULAR',
    size: 0,
  },
  {
    id: 1326,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'MICROCIRURGIA PARA BIOPSIA DE MEDULA ESPINHAL OU RAÍZES  ',
    size: 0,
  },
  {
    id: 1327,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'MICROCIRURGIA PARA MALFORMAÇÕES ARTERIOVENOSAS MEDULARES',
    size: 1,
  },
  {
    id: 1328,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'PUNÇÃO LOMBAR',
    size: 0,
  },
  {
    id: 1329,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'RIZOTOMIA PERCUTÂNEA',
    size: 0,
  },
  {
    id: 1330,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'SIMPATECTOMIA LOMBAR',
    size: 0,
  },
  {
    id: 1331,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'TRATAMENTO CIRÚRGICO DAS MALFORMAÇÕES CRANIOVERTEBRAIS',
    size: 1,
  },
  {
    id: 1332,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'TRATAMENTO CIRÚRGICO DE FÍSTULA LIQUÓRICA ',
    size: 0,
  },
  {
    id: 1333,
    general: 'SISTEMA NERVOSO ',
    specific: 'MEDULA',
    name: 'TRATAMENTO CIRÚRGICO DE LESÃO TRAUMÁTICA RAQUIMEDULAR',
    size: 1,
  },
  {
    id: 1334,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'ALCOOLIZAÇÃO DE NERVO CRANIANO',
    size: 0,
  },
  {
    id: 1335,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'BIOPSIA DE NERVO',
    size: 0,
  },
  {
    id: 1336,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'DENERVAÇÃO PERCUTÂNEA DE FACETA ARTICULAR',
    size: 0,
  },
  {
    id: 1337,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'DESCOMPRESSÃO NEUROVASCULAR DE NERVOS CRANIANOS',
    size: 0,
  },
  {
    id: 1338,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'ENXERTO INTERFASCICULAR',
    size: 0,
  },
  {
    id: 1339,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'ENXERTO MICROCIRÚRGICO DE NERVO PERIFÉRICO',
    size: 0,
  },
  {
    id: 1340,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'MICROCIRURGIA DE PLEXO BRAQUIAL ',
    size: 0,
  },
  {
    id: 1341,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'MICRONEURÓLISE DE NERVO PERIFÉRICO',
    size: 0,
  },
  {
    id: 1342,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'MICRONEURORRAFIA ',
    size: 0,
  },
  {
    id: 1343,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'RESSECÇÃO DE NEUROMA',
    size: 0,
  },
  {
    id: 1344,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'TRANSPOSIÇÃO DE NERVO',
    size: 0,
  },
  {
    id: 1345,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'TRATAMENTO CIRÚRGICO DE NEUROPATIA COMPRESSIVA ',
    size: 0,
  },
  {
    id: 1346,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'TRATAMENTO CIRÚRGICO DESCOMPRESSIVO  DO DESFILADEIRO TORÁCICO',
    size: 0,
  },
  {
    id: 1347,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'TRATAMENTO DE LESÃO DO SISTEMA NEUROVEGETATIVO',
    size: 0,
  },
  {
    id: 1348,
    general: 'SISTEMA NERVOSO ',
    specific: 'NERVOS ',
    name: 'TRATAMENTO MICROCIRÚRGICO DE TUMOR DE NERVO PERIFÉRICO ',
    size: 0,
  },
  {
    id: 1349,
    general: 'PROCEDIMENTOS ENDOSCÓPICOS',
    specific: '0',
    name: 'ENDOSCOPIA DIGESTIVA ALTA (COM BIOPSIA)',
    size: 0,
  },
  {
    id: 1350,
    general: 'PROCEDIMENTOS ENDOSCÓPICOS',
    specific: '0',
    name: 'COLONOSCOPIA (COM BIOPSIA)',
    size: 0,
  },
  {
    id: 1351,
    general: 'PROCEDIMENTOS ENDOSCÓPICOS',
    specific: '0',
    name: 'DILATAÇÃO ENDOSCÓPICA DE ESÔFAGO COM OGIVAS',
    size: 0,
  },
  {
    id: 1352,
    general: 'PROCEDIMENTOS ENDOSCÓPICOS',
    specific: '0',
    name: 'ELETROCOAGULAÇÃO ENDOSCÓPICA',
    size: 0,
  },
  {
    id: 1353,
    general: 'PROCEDIMENTOS ENDOSCÓPICOS',
    specific: '0',
    name: 'RETIRADA DE CORPO ESTRANHO DO TGI POR ENDOSCOPIA',
    size: 0,
  },
  {
    id: 1354,
    general: 'PROCEDIMENTOS ENDOSCÓPICOS',
    specific: '0',
    name: 'RETIRADA DE PÓLIPO DO TGI POR ENDOSCOPIA',
    size: 0,
  },
  {
    id: 1355,
    general: 'PROCEDIMENTOS ENDOSCÓPICOS',
    specific: '0',
    name: 'RETOSSIGMOIDOSCOPIA (COM BIOPSIA)',
    size: 0,
  },
  {
    id: 1356,
    general: 'OUTROS',
    specific: '0',
    name: 'BIOPSIA DE MEDULA ÓSSEA',
    size: 0,
  },
  {
    id: 1357,
    general: 'OUTROS',
    specific: '0',
    name: 'BIOPSIA PERCUTÂNEA ORIENTADA POR TOMOGRAFIA',
    size: 0,
  },
  {
    id: 1358,
    general: 'OUTROS',
    specific: '0',
    name: 'BIOPSIAS MÚLTIPLAS PARA ESTADIAMENTO',
    size: 0,
  },
  {
    id: 1359,
    general: 'OUTROS',
    specific: '0',
    name: 'CAUTERIZAÇÃO QUÍMICA DE PEQUENAS LESÕES',
    size: 0,
  },
  {
    id: 1360,
    general: 'OUTROS',
    specific: '0',
    name: 'IMPLANTAÇÃO DE CATETER DE LONGA PERMANÊNCIA ',
    size: 0,
  },
  {
    id: 1361,
    general: 'OUTROS',
    specific: '0',
    name: 'IMPLANTE / RETIRADA DE CATETER TENCKHOFF ',
    size: 0,
  },
  {
    id: 1362,
    general: 'OUTROS',
    specific: '0',
    name: 'INSTALAÇÃO DE CATETER PARA ACESSO VENOSO CENTRAL',
    size: 0,
  },
  {
    id: 1363,
    general: 'OUTROS',
    specific: '0',
    name: 'RETIRADA DE CATETER DE LONGA PERMANÊNCIA',
    size: 0,
  },
],
'en':[
  {id:1, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SOFT PALATE SURGERY', size: 0},
{id:2, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'ALVEOLAR BONE GRAFTING', size: 0},
{id:3, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'LIP BIOPSY/ EXCISION', size: 0},
{id:4, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SALIVARY GLAND BIOPSY', size: 0},
{id:5, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'TONGUE BIOPSY', size: 0},
{id:6, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'MOUTH TISSUE BIOPSY', size: 0},
{id:7, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL CORRECTION OF ORO-NASAL/ORO-SINUSAL FISTULA', size: 0},
{id:8, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'PERIAPICAL CURETTAGE', size: 0},
{id:9, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'ORAL ABSCESS DRAINAGE', size: 0},
{id:10, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'INTRAORAL BONE GRAFTING', size: 0},
{id:11, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SUBMANDIBULAR GLAND EXCISION', size: 0},
{id:12, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL EXCISION OF MUCOCELES OR RANULAS', size: 0},
{id:13, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'PAROTID GLAND TUMOR SURGERY', size: 0},
{id:14, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'EXCISION OF MOUTH LESIONS', size: 0},
{id:15, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL EXCISION OF ODONTOGENIC CYSTS', size: 0},
{id:16, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL EXCISION OF ODONTOGENIC CYSTS', size: 0},
{id:17, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'EXTRACTION OF PERMANENT TEETH', size: 0},
{id:18, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL CORRECTION OF OROANTRAL FISTULA', size: 0},
{id:19, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL CORRECTION OF OROFACIAL FISTULA', size: 0},
{id:20, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL CORRECTION OF ORONASAL FISTULA', size: 0},
{id:21, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'LINGUAL FRENECTOMY', size: 0},
{id:22, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'LABIAL FRENECTOMY', size: 0},
{id:23, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'TOTAL GLOSSECTOMY', size: 1},
{id:24, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'PARTIAL GLOSSECTOMY', size: 1},
{id:25, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'LYPOPLASTY', size: 0},
{id:26, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'OSTEOTOMY OF ALVEOLODENTAL FRACTURES', size: 0},
{id:27, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'PARTIAL PAROTIDECTOMY', size: 0},
{id:28, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'TOTAL PAROTIDECTOMY', size: 0},
{id:29, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'RECONSTRUCTION OF GINGIVA-LABIAL SULCUS', size: 0},
{id:30, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'TOTAL OR PARTIAL LIP RECONSTRUCTION', size: 0},
{id:31, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'IMPACTED TOOTH REMOVAL', size: 0},
{id:32, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SALIVARY GLAND EXCISION', size: 0},
{id:33, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SUBLINGUAL GLAND EXCISION', size: 0},
{id:34, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SUBMANDIBULAR GLAND EXCISION', size: 0},
{id:35, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'LIP EXCISION AND RECONSTRUCTION', size: 0},
{id:36, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'EXCISION OF ORAL BENIGN LESION', size: 0},
{id:37, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'EXCISION OF ORAL MALIGNANT LESION', size: 0},
{id:38, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'EXCISION OF ORAL MALIGNANT LESION', size: 0},
{id:39, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SUBLINGUAL GLAND TUMOR RESECTION', size: 0},
{id:40, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'REMOVAL OF BONE / DENTAL SYNTHESIS MATERIAL', size: 0},
{id:41, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF MACROSTOMIA', size: 0},
{id:42, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF MICROSTOMIA', size: 0},
{id:43, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF CUTANEOUS FISTULA OF DENTAL ORIGIN', size: 0},
{id:44, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF ORO-SINUSAL / ORO-NASAL FISTULA', size: 0},
{id:45, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF OSTEOMA AND ODONTOMA', size: 0},
{id:46, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'CLEFT LIP AND PALATE TREATMENT', size: 0},
{id:47, general: 'MOUTH / SALIVARY GLANDS / TOOTH', specific: 'HEAD / NECK', name: 'DENTAL CARE FOR PATIENTS WITH SPECIAL NEEDS', size: 0},
{id:48, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'ADENOIDECTOMY ', size: 0},
{id:49, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'TONSILLECTOMY', size: 0},
{id:50, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'TONSILLECTOMY  WITH ADENOIDECTOMY', size: 0},
{id:51, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'ARITENOIDECTOMY/ LARYNGEAL FRAMEWORK', size: 0},
{id:52, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'PHARYNGEAL/LARINGEAL BIOPSY', size: 0},
{id:53, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'DRAINAGE OF PHARYNGEAL ABSCESS', size: 0},
{id:54, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'PERITONSILLAR ABSCESS DRAINAGE', size: 0},
{id:55, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'EXCISION OF LARYNGEAL PAPILLOMA', size: 0},
{id:56, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'EXCISION OF CAVUM AND PHARYNX TUMOR', size: 0},
{id:57, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'PARTIAL PHARINGECTOMY', size: 0},
{id:58, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'TOTAL PHARINGECTOMY', size: 0},
{id:59, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'PHARINGOLARYNGECTOMY', size: 1},
{id:60, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'PARTIAL LARYNGECTOMY', size: 1},
{id:61, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'TOTAL LARYNGECTOMY', size: 1},
{id:62, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'TOTAL LARYNGECTOMY WITH CERVICAL LYMPHADENECTOMY', size: 1},
{id:63, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'LARYNGOFISSURE FOR LARYNGEAL TRAUMA', size: 0},
{id:64, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'LARYNGOSCOPY', size: 0},
{id:65, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'TRANSORAL LASER MICROSURGERY', size: 0},
{id:66, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'TRANSORAL LASER MICROSURGERY', size: 0},
{id:67, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'PALATOPLASTY/ ALVEOLAR BONE GRAFTING', size: 0},
{id:68, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'PALATE-CHEILOPLASTY', size: 0},
{id:69, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'SURGICAL RECONSTRUCTION FOR PHONATION', size: 0},
{id:70, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'NASOANGIOFIBROMA RESECTION', size: 0},
{id:71, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'ENDOSCOPIC SURGERY FOR NASOPHARYNGEAL TUMOR', size: 0},
{id:72, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF NASOPHARYNGEAL TUMOR', size: 0},
{id:73, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'FOREIGN BODY REMOVAL FROM EAR / PHARYNX / LARYNX / NOSE', size: 0},
{id:74, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'THYROPLASTY', size: 0},
{id:75, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF VELOPHARYNGEAL INSUFFICIENCY', size: 0},
{id:76, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF LARYNGEAL TRAUMA', size: 0},
{id:77, general: 'PHARYNX / LARYNX', specific: 'HEAD / NECK', name: 'UVULOPALATTOPHARYNGOPLASTY', size: 0},
{id:78, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'ARTHROPLASTY FOR RECURRENT LUXATION OF THE TEMPOROMANDIBULAR JOINT', size: 0},
{id:79, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'MANDIBULAR BIOPSY', size: 0},
{id:80, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'BLEPHAROPLASY', size: 0},
{id:81, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'DRAINAGE OF EYELID ABSCESS', size: 0},
{id:82, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'PARTIAL MANDIBULECTOMY', size: 1},
{id:83, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'TOTAL MANDIBULECTOMY', size: 1},
{id:84, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'PARTIAL MAXILLECTOMY', size: 1},
{id:85, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'TOTAL MAXILLECTOMY', size: 1},
{id:86, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'ZYGOMATIC ARCH OSTEOPLASTY', size: 0},
{id:87, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'MENTAL OSTEOPLASTY WITH OR WITHOUT ALLOPLASTIC REPLACEMENT', size: 0},
{id:88, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'ETMOIDAL OSTEOPLASTY', size: 0},
{id:89, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'FRONTO-ORBITAL OSTEOPLASTY', size: 0},
{id:90, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEPLASTY FOR PROGNATISM / MICROGNATHISM', size: 0},
{id:91, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'ORBITAL OSTEOPLASTY', size: 0},
{id:92, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEOSYNTHESIS OF COMPLEX  MANDIBLE FRACTURES', size: 0},
{id:93, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEOSYNTHESIS OF BILATERAL MANDIBULAR CONDYLE FRACTURE ', size: 0},
{id:94, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEOSYNTHESIS OF MAXILLARY COMPLEX FRACTURE', size: 0},
{id:95, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEOSYNTHESIS OF NASO-ORBITO-ETHMOID COMPLEX FRACTURE', size: 0},
{id:96, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEOSYNTHESIS OF ORBITOZYGOMATIC-MAXILLARY COMPLEX FRACTURE', size: 0},
{id:97, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEOSYNTHESIS OF MANDIBULAR FRACTURE', size: 0},
{id:98, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'OSTEOSYNTHESIS OF UNILATERAL MANDIBULAR CONDYLE FRACTURE', size: 0},
{id:99, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'MANDIBLE OSTEOTOMY', size: 0},
{id:100, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'MAXILLARY OSTEOTOMY', size: 0},
{id:101, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'MAXILLARY OSTEOTOMY', size: 0},
{id:102, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'LEFORT I OSTEOTOMY', size: 0},
{id:103, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'LEFORT II OSTEOTOMY', size: 0},
{id:104, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'LEFORT III OSTEOTOMY', size: 0},
{id:105, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'PELVIGLOSOMANDIBULECTOMY', size: 1},
{id:106, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'TOTAL EYELID RECONSTITUTION', size: 0},
{id:107, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'CRANIO-FACIAL RECONSTRUCTION', size: 0},
{id:108, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'PARTIAL RECONSTRUCTION OF THE MANDIBLE WITH BONE GRAFT', size: 0},
{id:109, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'TOTAL RECONSTRUCTION OF THE MANDIBLE/MAXILLA', size: 0},
{id:110, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'TOTAL RECONSTRUCTION OF THE MANDIBLE/MAXILLA', size: 0},
{id:111, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'TOTAL ORBIT RECONSTRUCTION', size: 0},
{id:112, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'REDUCTION OF ZYGOMATIC COMPLEX FRACTURE', size: 0},
{id:113, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'REDUCTION OF COMMINUTED MANDIBULAR FRACTURE', size: 0},
{id:114, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'REDUCTION OF MANDIBULAR FRACTURE', size: 0},
{id:115, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'REDUCTION OF MAXILLARY FRACTURE', size: 0},
{id:116, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'MANDIBLE FRACTURE REDUCTION WITHOUT OSTEOSYNTHESIS', size: 0},
{id:117, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'MAXILLARY FRACTURE REDUCTION - LE FORT I WITHOUT OSTEOSYNTHESIS', size: 0},
{id:118, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'CRANIOFACIAL REMODELING IN PATIENT WITH SKULL AND ORAL MAXILLOFACIAL ANOMALY', size: 0},
{id:119, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'RESECTION OF THE MANDIBULAR CONDYLE', size: 0},
{id:120, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'REMOVAL OF FOREIGN BODY FROM FACE BONES', size: 0},
{id:121, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'REMOVAL OF MAXILLOMANDIBULAR FIXATION DEVICES', size: 0},
{id:122, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'REMOVAL OF SURGICAL IMPLANTS ', size: 0},
{id:123, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'ORBITAL TRANSLOCATION FOR HYPERTELORISM TREATMENT', size: 0},
{id:124, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF BLEPHAROCHALASIS', size: 0},
{id:125, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF TEMPOROMANDIBULAR JOINT ANKYLOSIS ', size: 0},
{id:126, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT MAXILLO-MANDIBULAR COMPLEX CYST', size: 0},
{id:127, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF OROMAXILLARY FISTULA AND CYSTS', size: 0},
{id:128, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF ZYGOMATIC BONE FRACTURE', size: 0},
{id:129, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF FACIAL OSTEOMYELITIS', size: 0},
{id:130, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF FACIAL PALSY', size: 0},
{id:131, general: 'FACE / MAXILLA / MANDIBLE', specific: 'HEAD / NECK', name: 'TREATMENT OF PALPEBRAL PTOSIS', size: 0},
{id:132, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'PARATHYROID BIOPSY', size: 0},
{id:133, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'THYROID / PARATHYROID BIOPSY (FNAB)', size: 0},
{id:134, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'EXPLORATORY CERVICOTOMY', size: 0},
{id:135, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'RADICAL NECK DISSECTION', size: 0},
{id:136, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'NECK ABSCESS DRAINAGE', size: 0},
{id:137, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'CAROTID ENDOARTERECTOMY', size: 0},
{id:138, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'RADICAL NECK DISSECTION (UNILATERAL)', size: 0},
{id:139, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'BRANCHIAL CLEFT CYST EXCISION', size: 0},
{id:140, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'THYROGLOSSAL DUCT CYST EXCISION', size: 0},
{id:141, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'SURGERY FOR CERVICAL NEUROBLASTOMA', size: 1},
{id:142, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'NODULECTOMY (THYROID)', size: 0},
{id:143, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'CAROTID ARTERY/ BRANCHES LIGATION', size: 0},
{id:144, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'PARATHYROIDECTOMY', size: 0},
{id:145, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'TOTAL PARATHYROIDECTOMY', size: 0},
{id:146, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'EXTENDED PAROTIDECTOMY', size: 0},
{id:147, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'PARATHYROID GLAND REIMPLANTATION', size: 0},
{id:148, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'TRANSSTERNAL THYROID TUMOR RESECTION', size: 1},
{id:149, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'SURGERY FOR INTRATHORACIC (RETROSTERNAL) GOITER', size: 1},
{id:150, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'PARTIAL THYROIDECTOMY', size: 0},
{id:151, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'TOTAL THYROIDECTOMY', size: 0},
{id:152, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'TOTAL THYROIDECTOMY WITH EXTENSIVE NECK DISSECTION', size: 0},
{id:153, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'THYROPLASTY', size: 0},
{id:154, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'TRANSKELETAL CERVICAL TRACTION', size: 0},
{id:155, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'SURGICAL MANAGEMENT OF CERVICAL LIPOMATOSIS', size: 0},
{id:156, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'SURGERY FOR CAROTID BODY TUMORS', size: 0},
{id:157, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF CONGENITAL TORTICOLLIS', size: 0},
{id:158, general: 'NECK / THYROID / PARATHYROID', specific: 'HEAD / NECK', name: 'NECK FISTULA TREATMENT', size: 0},
{id:159, general: 'SKULL', specific: 'HEAD / NECK', name: 'SKULL OR FACE BONE BIOPSY', size: 0},
{id:160, general: 'SKULL', specific: 'HEAD / NECK', name: 'ENDOSCOPIC INTRACRANIAL SURGERY', size: 0},
{id:161, general: 'SKULL', specific: 'HEAD / NECK', name: 'BONE TUMOR CRANIECTOMY', size: 0},
{id:162, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIOPLASTY', size: 0},
{id:163, general: 'SKULL', specific: 'HEAD / NECK', name: 'DECOMPRESSIVE CRANIOTOMY', size: 1},
{id:164, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIOTOMY FOR BRAIN BIOPSY', size: 0},
{id:165, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIOTOMY FOR ABSCESS DRAINAGE', size: 1},
{id:166, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIOTOMY FOR FOREIGN BODY REMOVAL', size: 1},
{id:167, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIOTOMY FOR REMOVAL OF INTRACRANIAL TUMOR', size: 1},
{id:168, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIAL HALO APPLICATION', size: 0},
{id:169, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIOFACIAL OSTEOTOMY', size: 0},
{id:170, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIAL OR CRANIOFACIAL RECONSTRUCTION', size: 0},
{id:171, general: 'SKULL', specific: 'HEAD / NECK', name: 'TEMPORAL BONE RESECTION', size: 0},
{id:172, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIOPLASTY PLATE REMOVAL', size: 0},
{id:173, general: 'SKULL', specific: 'HEAD / NECK', name: 'SURGICAL MANAGEMENT OF COMPOUND SKULL FRACTURE', size: 0},
{id:174, general: 'SKULL', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF CRANIAL OSTEOMYELITIS', size: 0},
{id:175, general: 'SKULL', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT FOR CRANIOSYNOSTOSIS', size: 0},
{id:176, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIAL TREPANATION ', size: 0},
{id:177, general: 'SKULL', specific: 'HEAD / NECK', name: 'CRANIAL TREPANATION ', size: 0},
{id:178, general: 'EAR', specific: 'HEAD / NECK', name: 'MASTOID ANTROTOMY', size: 0},
{id:179, general: 'EAR', specific: 'HEAD / NECK', name: 'EXTERNAL EAR BIOPSY', size: 0},
{id:180, general: 'EAR', specific: 'HEAD / NECK', name: 'EAR BIOPSY', size: 0},
{id:181, general: 'EAR', specific: 'HEAD / NECK', name: 'BONE-ANCHORED HEARING AID', size: 0},
{id:182, general: 'EAR', specific: 'HEAD / NECK', name: 'ENDOLYMPHATIC SAC DRAINAGE', size: 0},
{id:183, general: 'EAR', specific: 'HEAD / NECK', name: 'INTRATEMPORAL FACIAL NERVE TOTAL GRAFT', size: 0},
{id:184, general: 'EAR', specific: 'HEAD / NECK', name: 'STAPEDECTOMY OR STAPEDOTOMY', size: 0},
{id:185, general: 'EAR', specific: 'HEAD / NECK', name: 'FACIAL NERVE EXPLORATION AND DECOMPRESSION', size: 0},
{id:186, general: 'EAR', specific: 'HEAD / NECK', name: 'COCHLEAR IMPLANT', size: 0},
{id:187, general: 'EAR', specific: 'HEAD / NECK', name: 'LABYRINTECTOMY ', size: 0},
{id:188, general: 'EAR', specific: 'HEAD / NECK', name: 'RADICAL MASTOIDECTOMY', size: 1},
{id:189, general: 'EAR', specific: 'HEAD / NECK', name: 'SUBTOTAL MASTOIDECTOMY', size: 1},
{id:190, general: 'EAR', specific: 'HEAD / NECK', name: 'OTOLOGICAL MICROSURGERY', size: 0},
{id:191, general: 'EAR', specific: 'HEAD / NECK', name: 'MYRINGOTOMY / TYMPANOPLASTY', size: 0},
{id:192, general: 'EAR', specific: 'HEAD / NECK', name: 'VESTIBULAR NEURECTOMY', size: 0},
{id:193, general: 'EAR', specific: 'HEAD / NECK', name: 'OTOPLASTY', size: 0},
{id:194, general: 'EAR', specific: 'HEAD / NECK', name: 'MYRINGOTOMY', size: 0},
{id:195, general: 'EAR', specific: 'HEAD / NECK', name: 'EARLOBE RECONSTRUCTION', size: 0},
{id:196, general: 'EAR', specific: 'HEAD / NECK', name: 'RECONSTRUCTION OF THE SUPERIOR HELIX', size: 0},
{id:197, general: 'EAR', specific: 'HEAD / NECK', name: 'TOTAL EAR RECONSTRUCTION', size: 0},
{id:198, general: 'EAR', specific: 'HEAD / NECK', name: 'SURGERY OF GLOMUS JUGULARE ', size: 1},
{id:199, general: 'EAR', specific: 'HEAD / NECK', name: 'GLOMUS TYMPANICUM SURGERY', size: 1},
{id:200, general: 'EAR', specific: 'HEAD / NECK', name: 'EXTERNAL EAR RESSECTION', size: 0},
{id:201, general: 'EAR', specific: 'HEAD / NECK', name: 'ACOUSTIC NEUROMA SURGERY', size: 0},
{id:202, general: 'EAR', specific: 'HEAD / NECK', name: 'ACOUSTIC NEUROMA SURGERY', size: 0},
{id:203, general: 'EAR', specific: 'HEAD / NECK', name: 'GLOMIC TUMOR RESECTION', size: 1},
{id:204, general: 'EAR', specific: 'HEAD / NECK', name: 'TYMPANOMASTOIDECTOMY', size: 1},
{id:205, general: 'EAR', specific: 'HEAD / NECK', name: 'TYMPANOSTOMY TUBE INSERTION', size: 0},
{id:206, general: 'EAR', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF STENOSIS OF THE EXTERNAL AUDITORY CANAL', size: 0},
{id:207, general: 'EAR', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF THE PREAURICULAR SINUS', size: 0},
{id:208, general: 'EAR', specific: 'HEAD / NECK', name: 'ENDOLYMPHATIC SAC SURGERY FOR MENIERES DISEASE', size: 0},
{id:209, general: 'EAR', specific: 'HEAD / NECK', name: 'NON-AESTHETIC SURGICAL TREATMENT OF THE EAR', size: 0},
{id:210, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'INTRANASAL MAXILLARY ANTROSTOMY', size: 0},
{id:211, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'ENDOSCOPIC MAXILLARY ANTROSTOMY', size: 0},
{id:212, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'NASAL PYRAMID BIOPSY', size: 0},
{id:213, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'PARANASAL SINUS BIOPSY', size: 0},
{id:214, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'CAUTERY OF INFERIOR TURBINATES', size: 0},
{id:215, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'DRAINAGE OF A NASAL SEPTUM HEMATOMA OR ABSCESS', size: 0},
{id:216, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'ENDOSCTOPIC CAUTERIZATION OF THE ETHMOID/SPHENOPALATINE ARTERIES', size: 0},
{id:217, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'ARTERIOR ETHMOIDAL ARTERY LIGATION', size: 0},
{id:218, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'ETHMOIDECTOMY', size: 0},
{id:219, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'ENDOSCOPIC EXCISION OF FRONTAL LESIONS', size: 0},
{id:220, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'ENDOSCOPIC EXCISION OF PARANASAL SINUS TUMORS', size: 0},
{id:221, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'INFILTRATION OF INFERIOR TURBINATES', size: 0},
{id:222, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'TOTAL OR PARTIAL NOSE RECONSTRUCTION', size: 0},
{id:223, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'NASAL FRACTURE REDUCTION', size: 0},
{id:224, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'NASAL FRACTURE REDUCTION', size: 0},
{id:225, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'RESECTION OF TRANSNASAL MALIGNANT TUMORS', size: 0},
{id:226, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'FRONTAL MUCOCELE RESECTION', size: 0},
{id:227, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'RHINOPLASTY', size: 0},
{id:228, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'RHINOSEPTOPLASTY', size: 0},
{id:229, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'SEPTOPLASTY', size: 0},
{id:230, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'FRONTOETHMOIDAL SINUSECTOMY', size: 0},
{id:231, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'MAXILLARY SINUSECTOMY', size: 0},
{id:232, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'BILATERAL SINUSOTOMY', size: 0},
{id:233, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'SPHENOID SINUSOTOMY', size: 0},
{id:234, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'FRONTAL SINUSOTOMY', size: 0},
{id:235, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'TRANSMAXILLARY SINUSOTOMY', size: 0},
{id:236, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'NASAL PACKING', size: 0},
{id:237, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF CHOANAL ATRESIA', size: 0},
{id:238, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'NASAL SEPTUM PERFORARION SURGERY', size: 0},
{id:239, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'SURGICAL TREATMENT OF RHINOPHYMA', size: 0},
{id:240, general: 'NOSE / PARANASAL SINUSES', specific: 'HEAD / NECK', name: 'TURBINECTOMY', size: 0},
{id:241, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BIOPSY OF SOFT-TISSUE MASSES', size: 0},
{id:242, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BIOPSY OF SOFT-TISSUE MASSES', size: 0},
{id:243, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SKIN BIOPSY', size: 0},
{id:244, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT IN HIDRADENITIS SUPPURATIVA', size: 0},
{id:245, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CORRECTION OF MULTI-STAGE CICATRICIAL RETRACTION', size: 0},
{id:246, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL DRESSING WITH OR WITHOUT DEBRIDEMENT', size: 0},
{id:247, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL DRESSING WITH OR WITHOUT DEBRIDEMENT', size: 0},
{id:248, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'WOUND DEBRIDEMENT/DEVITALIZED TISSUES', size: 0},
{id:249, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ABSCESS DRAINAGE', size: 0},
{id:250, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SEROMA DRAINAGE AND/OR ASPIRATION', size: 0},
{id:251, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ELECTROSURGERY FOR THE SKIN', size: 0},
{id:252, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'COMPOSITE GRAFT', size: 0},
{id:253, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'DERMO-EPIDERMAL GRAFT', size: 0},
{id:254, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'EXCISION OF SKIN LESIONS', size: 0},
{id:255, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SKIN EXCISION AND GRAFTING', size: 0},
{id:256, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'Z-PLASTY', size: 0},
{id:257, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'HEMANGIOMA EXCISION AND SUTURE', size: 0},
{id:258, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LYMPHANGIOMA/NEVUS EXCISION AND SUTURE', size: 0},
{id:259, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'EPIDERMOID CYST EXERESIS', size: 0},
{id:260, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SACRO-COCCYGEAL CYST EXERESIS', size: 0},
{id:261, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SKIN TUMOR EXERESIS', size: 0},
{id:262, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TISSUE EXPANDER', size: 0},
{id:263, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SKIN OR SUBCUTANEOUS LESION EXCISION', size: 0},
{id:264, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'HAIR TRANSPLANT', size: 0},
{id:265, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SKIN AND MUCOSAL GRAFTS', size: 0},
{id:266, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'GRAFT PREPARATION', size: 0},
{id:267, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RECONSTRUTIVE MICROSURGERY', size: 0},
{id:268, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SUBCUTANEOUS FOREIGN BODY REMOVAL', size: 0},
{id:269, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'GRAFT TRANSFER', size: 0},
{id:270, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SCALP RECONSTRUCTIVE FLAPS', size: 0},
{id:271, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'EXTENSIVE SKIN LESIONS SURGICAL TREATMENT', size: 0},
{id:272, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF SCARS', size: 0},
{id:273, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FURUNCULAR MYIASIS', size: 0},
{id:274, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CUTANEOUS FISTULA TREATMENT', size: 0},
{id:275, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SEVERE BURN TREATMENT', size: 1},
{id:276, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SECOND-DEGREE BURN TREATMENT', size: 0},
{id:277, general: 'CUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'Z-PLASTY', size: 0},
{id:278, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENDON SURGICAL LENGTHENING ', size: 0},
{id:279, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'AUTONOMIZATION OF FREE FLAPS', size: 0},
{id:280, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MUSCLE BIOPSY', size: 0},
{id:281, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'DEBRIDEMENT OF NECROTIZING FASCIITIS', size: 0},
{id:282, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'DEBRIDEMENT OF ULCER / NECROSIS', size: 0},
{id:283, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL DRAINAGE OF ILIOPSOAS', size: 0},
{id:284, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SACROCOCCYGEAL CYST EXERESIS', size: 0},
{id:285, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FASCIECTOMY', size: 0},
{id:286, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FASCIOTOMY FOR DECOMPRESSION', size: 0},
{id:287, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BUTTOCK LIPOFILLING', size: 0},
{id:288, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RECONSTRUCTION WITH MYOCUTANEOUS FLAP (ANY PART)', size: 0},
{id:289, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RECONSTRUCTION WITH OSTEOMYOCUTANEOUS FLAP', size: 0},
{id:290, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MUSCLE REINSERTION', size: 0},
{id:291, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ROTATOR CUFF TEAR REPAIR', size: 0},
{id:292, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'EXTENDED RESECTION OF SOFT TISSUE TUMORS', size: 0},
{id:293, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RESECTION OF SOFT TISSUES OF THE EXTREMITIES WITH RECONSTRUCTION', size: 0},
{id:294, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RESECTION OF SOFT TISSUE TUMORS', size: 0},
{id:295, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TUMOR RESECTION AND RECONSTRUCTION WITH FLAP OR GRAFT', size: 0},
{id:296, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MUSCLE RESECTION', size: 0},
{id:297, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ILIOPSOAS MYOTENDINOUS TRANSPOSITION / STRETCHING IN NEUROMUSCULAR DISEASE', size: 0},
{id:298, general: 'MUSCULOCUTANEOUS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ULCERATIONS WITH MYOCUTANEOUS FLAP', size: 0},
{id:299, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHROCENTHESIS', size: 0},
{id:300, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHRODESIS OF THE SHOULDER', size: 0},
{id:301, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SCAPULOTHORACIC ARTHRODESIS', size: 0},
{id:302, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SCAPULOHUMERAL ARTHRODESIS', size: 0},
{id:303, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHRODESIS OF THE JOINTS OF THE LOWER EXTREMITY', size: 0},
{id:304, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHRODESIS OF THE JOINTS OF THE UPPER EXTREMITY', size: 0},
{id:305, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SCAPULOHUMERAL ARTHROPLASTY', size: 0},
{id:306, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHROSCOPY', size: 0},
{id:307, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SCAPULOTHORACIC DISARTICULATION ', size: 1},
{id:308, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTICULAR EXPLORATION OF MEDIUM/LARGE JOINTS', size: 0},
{id:309, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTICULAR EXPLORATION OF SMALL JOINTS', size: 0},
{id:310, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTICULAR MANIPULATION', size: 0},
{id:311, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SMALL JOINT REPLACEMENT WITH PROSTHESIS', size: 0},
{id:312, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'INTRA-ARTICULAR FOREIGN BODY REMOVAL', size: 0},
{id:313, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REMOVAL OF LARGE JOINT PROSTHESIS (SHOULDER / ELBOW / HIP / KNEE)', size: 0},
{id:314, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REMOVAL OF SMALL/MEDIUM JOINT PROSTHESIS', size: 0},
{id:315, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF SEPTIC ARTHRITIS', size: 0},
{id:316, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF JOINT DEFORMITY', size: 0},
{id:317, general: 'JOINTS', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHROSCOPY', size: 0},
{id:318, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FOREARM LENGTHENING SURGERY: EXTERNAL FIXATOR', size: 0},
{id:319, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FINGER AMPUTATION (HAND)', size: 0},
{id:320, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'UPPER LIMB AMPUTATION ', size: 0},
{id:321, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHRODESIS OF SMALL JOINTS', size: 0},
{id:322, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RADIAL HEAD ARTHROPLASTY', size: 0},
{id:323, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MEDIUM / LARGE JOINT RESECTION ARTHROPLASTY', size: 0},
{id:324, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SMALL JOINT RESECTION ARTHROPLASTY', size: 0},
{id:325, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BRACHIAL DERMOLIPECTOMY - BRACHIOPLASTY', size: 0},
{id:326, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'DISARTICULATION OF THE SCAPULOHUMERAL JOINT', size: 1},
{id:327, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'UPPER EXTREMITY FASCIOTOMY', size: 0},
{id:328, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'UPPER LIMB SKELETAL TRACTION', size: 0},
{id:329, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ARM/FOREARM OSTEOMYELITIS', size: 0},
{id:330, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REIMPLANTATION OF THE SHOULDER UP TO THE MIDDLE THIRD OF THE FOREARM / SURGICAL CLEANING OF THE SHOULDER', size: 0},
{id:331, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RESECTION OF THE OLECRAN AND/OR RADIAL HEAD', size: 0},
{id:332, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'UPPER LIMB TENOSINOVECTOMY', size: 0},
{id:333, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TRANSPOSITION OF THE ULNA UPON THE RADIUS', size: 0},
{id:334, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF HUMERAL FRACTURE', size: 0},
{id:335, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF PROXIMAL HUMERUS PHYSARY FRACTURE/INJURY', size: 0},
{id:336, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FRACTURE OF THE DISTAL END / METAPHYSIS OF THE FOREARM', size: 0},
{id:337, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ULNA/RADIUS FRACTURE', size: 0},
{id:338, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FOREARM FRACTURES', size: 0},
{id:339, general: 'ARM / FOREARM', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF GALEAZZI / MONTEGGIA AND ESSEX-LOPRESTI INJURIES', size: 0},
{id:340, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'HAND AMPUTATION', size: 0},
{id:341, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'WRIST ARTHRODESIS', size: 0},
{id:342, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'HAND JOINT ARTHROPLASTY', size: 0},
{id:343, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'WRIST ARTHROPLASTY', size: 0},
{id:344, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TOTAL ELBOW ARTHROPLASTY', size: 0},
{id:345, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ELBOW ARTHROTOMY', size: 0},
{id:346, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL EXPLORATION OF HAND TENDON', size: 0},
{id:347, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ELBOW LIGAMENT INJURIES', size: 0},
{id:348, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOTOMY OF HAND AND/OR FOOT BONES', size: 0},
{id:349, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF HAND OSTEOMYELITIS', size: 0},
{id:350, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REALIGNMENT OF THE EXTENSIVE FINGERS MECHANISM', size: 0},
{id:351, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CAPSULO-LIGAMENTAIRE RECONSTRUCTION OF ELBOW /WRIST', size: 0},
{id:352, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FLEXOR PULLEY RECONSTRUCTION', size: 0},
{id:353, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CARPAL BONE SURGERY', size: 0},
{id:354, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FINGER REPLANTATION', size: 0},
{id:355, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PALM APONEUROSE RETRACTION (DUPUYTREN)', size: 0},
{id:356, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FINGER AMPUTATION ', size: 0},
{id:357, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENOSYNOVECTOMY OF THE HAND OR WRIST', size: 0},
{id:358, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FINGER TRANSPLANTATION', size: 0},
{id:359, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF TRIGGER FINGER', size: 0},
{id:360, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF MALLET FINGER', size: 0},
{id:361, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENTOF PHALANGEAL FRACTURES', size: 0},
{id:362, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FRACTURES OR DISLOCATIONS OF THE WRIST', size: 0},
{id:363, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ACUTE CAPSULO-LIGAMENTARY INJURY OF THE UPPER LIMB: ELBOW / WRIST', size: 0},
{id:364, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF INTRINSIC HAND MUSCULATURE INJURY', size: 0},
{id:365, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ELBOW DISLOCATION / FRACTURE', size: 0},
{id:366, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF POLYDACTYLY', size: 0},
{id:367, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF RUPTURE / DISINSERTION / CAPSULO-TENO-LIGAMENTAR REPLACEMENT IN THE HAND', size: 0},
{id:368, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF HAND LIGAMENT INJURY', size: 0},
{id:369, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF EXTENSOR TENDON INJURIES', size: 0},
{id:370, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF HAND SYNDACTYLY', size: 0},
{id:371, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF CARPAL TUNNEL SYNDROME', size: 0},
{id:372, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT FOR SOFT TISSUE DEFFECTS IN THE HAND', size: 0},
{id:373, general: 'ELBOW / WRIST / HAND', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CARPAL TUNNEL RELEASE', size: 0},
{id:374, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LOWER LIMB AMPUTATION ', size: 0},
{id:375, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'COXOFEMORAL ARTHRODESIS', size: 0},
{id:376, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHRODESIS OF THE PUBIC SYMPHYSIS', size: 1},
{id:377, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SACROILIAC JOINT FUSION', size: 0},
{id:378, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'COXOFEMORAL ARTHROPLASTY', size: 0},
{id:379, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ARTHROPLASTY FOR HIP REVISION OR RECONSTRUCTION ', size: 1},
{id:380, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PARTIAL HIP REPLACEMENT', size: 0},
{id:381, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PRIMARY TOTAL HIP ARTHROPLASTY', size: 0},
{id:382, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FEMUR BIOPSY', size: 0},
{id:383, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'INGROWN TOENAIL SURGERY', size: 0},
{id:384, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'DERMOLIPECTOMY OF THE LOWER LIMBS - POST-BARIATRIC', size: 0},
{id:385, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'HIP DISARTICULATION', size: 1},
{id:386, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'INTERILIO-ABDOMINAL DISARTICULATION', size: 1},
{id:387, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FEMORAL SHORTENING - SURGICAL TREATMENT', size: 0},
{id:388, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'GREATER TROCHANTERIC EPIPHYSIODESIS', size: 0},
{id:389, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'HIP LABRAL TEAR', size: 0},
{id:390, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOMYELITIS OF THE PELVIC BONES - SURGICAL TREATMENT', size: 0},
{id:391, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PELVIC OSTEOTOMY', size: 0},
{id:392, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOPLASTIC RECONSTRUCTION OF THE HIP', size: 0},
{id:393, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'AUTOGENOUS ILIAC GRAFT REMOVAL', size: 0},
{id:394, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'AVULSION INJURIES OF THE PELVIS AND HIP', size: 0},
{id:395, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'AVULSION INJURIES OF THE PELVIS AND HIP', size: 0},
{id:396, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF PUBIC DISJUNCTION', size: 1},
{id:397, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FRACTURE / PROXIMAL PHYSARY FEMORAL NECK INJURY', size: 0},
{id:398, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF HIP FRACTURE / DISLOCATION', size: 0},
{id:399, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ACETABULAR FRACTURE', size: 0},
{id:400, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF INTERTROCHANTERIC FRACTURE', size: 0},
{id:401, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF TRAUMATIC HIP-FEMORAL LUXATION / POST-ARTHROPLASTY', size: 0},
{id:402, general: 'THIGH / FEMUR / PELVIS / HIP', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FEMORAL FRACTURE', size: 0},
{id:403, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LEG LENGTHENING ', size: 0},
{id:404, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LOWER LIMB AMPUTATION ', size: 0},
{id:405, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'KNEE ARTHRODESIS - SURGICAL TREATMENT', size: 0},
{id:406, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'KNEE ARTHROPLASTY', size: 0},
{id:407, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TOTAL KNEE ARTHROPLASTY', size: 0},
{id:408, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CHONDROPLASTY', size: 0},
{id:409, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FASCIOTOMY OF LOWER LIMBS', size: 0},
{id:410, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FRACTURES AND/OR DISLOCATIONS OF THE KNEE - SURGICAL TREATMENT', size: 0},
{id:411, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LOCKED FEMUR INTRAMEDULLARY NAIL', size: 0},
{id:412, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LOCKED TIBIA INTRAMEDULLARY NAIL', size: 0},
{id:413, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LOWER LIMB SKELETAL TRACTION', size: 0},
{id:414, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'KNEE LIGAMENT INJURIES', size: 0},
{id:415, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF LOWER LIMB OSTEOMYELITIS', size: 0},
{id:416, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOTOMY OF THE KNEE', size: 0},
{id:417, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TOTAL OR PARTIAL PATELLECTOMY', size: 0},
{id:418, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'EXTENSOR MECHANISM REALIGNMENT OF THE KNEE', size: 0},
{id:419, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PATELLAR TENDON RECONSTRUCTION', size: 0},
{id:420, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL REVISION OF LOWER LIMB AMPUTATION STUMP', size: 0},
{id:421, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LOWER LIMB TENOSYNOVECTOMY', size: 0},
{id:422, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MUSCLE/TENDON TRANSFER IN THE LOWER LIMB', size: 0},
{id:423, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MENISCUS TRANSPLANTATION', size: 0},
{id:424, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FIBULAR TRANSPOSITION IN TIBIAL NONUNION', size: 0},
{id:425, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'INTERNAL FIXATION SURGERY OF PATELLAR FRACTURE', size: 0},
{id:426, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF TIBIAL FRACTURE', size: 0},
{id:427, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FEMORAL FRACTURE', size: 0},
{id:428, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF TIBIAL PILON FRACTURE', size: 0},
{id:429, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF CAPSULAR LIGAMENT INJURY OF THE KNEE JOINT', size: 0},
{id:430, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF PSEUDARTHROSIS OF THE TIBIA', size: 0},
{id:431, general: 'KNEE / LEG', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MENISCUS SURGERY', size: 0},
{id:432, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANKLE-LEVEL AMPUTATION ', size: 0},
{id:433, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FOOT AND TARSUS AMPUTATION ', size: 0},
{id:434, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TOE AMPUTATION', size: 0},
{id:435, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANKLE ARTHRODESIS', size: 0},
{id:436, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MIDFOOT ARTHRODESIS', size: 0},
{id:437, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANKLE ARTHROPLASTY', size: 0},
{id:438, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PLANTAR FASCIOTOMY', size: 0},
{id:439, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOMYELITIS OF THE FOOT  - SURGICAL TREATMENT', size: 0},
{id:440, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANKLE LIGAMENT RECONSTRUCTION', size: 0},
{id:441, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENDON SHEATH REPAIR OF THE ANKLE', size: 0},
{id:442, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BONE RESSECTION OF THE FOOT', size: 0},
{id:443, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF CONGENITAL CLUBFOOT', size: 0},
{id:444, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TALECTOMY', size: 0},
{id:445, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF TARSAL COALITION', size: 0},
{id:446, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF PHYSARY FRACTURE/INJURY OF MIDFOOT', size: 0},
{id:447, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF METACARPAL PHYSARY FRACTURE/INJURY', size: 0},
{id:448, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FOOT FRACTURE SURGERY', size: 0},
{id:449, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CALCANEAL FRACTURE SURGERY', size: 0},
{id:450, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TALAR FRACTURE SURGERY', size: 0},
{id:451, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF UNIMALEOLAR ANKLE FRACTURE', size: 0},
{id:452, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ANKLE FRACTURE AND/OR DISLOCATION', size: 0},
{id:453, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF HALUX VALGUS', size: 0},
{id:454, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CAVUS FOOT SURGERY', size: 0},
{id:455, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FLAT VALGUS FOOT SURGERY', size: 0},
{id:456, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF CONGENITAL CLUBFOOT', size: 0},
{id:457, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF HALUX RIGIDUS', size: 0},
{id:458, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF MALUM PERFORANS', size: 0},
{id:459, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ANKLE OSTEOCHONDRITIS', size: 0},
{id:460, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ACHILLES TENDON RUPTURE TREATMENT', size: 0},
{id:461, general: 'ANKLE / FOOT', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TREATMENT OF BIMALEOLAR / TRIMALEOLAR FRACTURE / ANKLE FRACTURE-LUXATION', size: 0},
{id:462, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR CERVICAL / CERVICOTHORACIC ARTHRODESIS OF FIVE OR MORE LEVELS', size: 1},
{id:463, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR CERVICAL / CERVICOTHORACIC ARTHRODESIS OF ONE TO FOUR LEVELS', size: 0},
{id:464, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANTERIOR CERVICAL ARTHRODESIS', size: 0},
{id:465, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TRANS-ORAL ANTERIOR CERVICAL ARTHRODESIS OF C1-C2 ', size: 0},
{id:466, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR OCCIPTO-CERVICAL ARTHRODESIS', size: 0},
{id:467, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANTERIOR THORACOLUMBOSACRAL ARTHRODESIS', size: 1},
{id:468, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR THORACOLUMBOSACRAL ARTHRODESIS OF ONE TO THREE LEVELS', size: 0},
{id:469, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR THORACOLUMBOSACRAL ARTHRODESIS OF FOUR OR MORE LEVELS', size: 1},
{id:470, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'VERTEBRAL BIOPSY', size: 0},
{id:471, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ENDOSCOPIC SPINAL SURGERY', size: 0},
{id:472, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MEDULLARY DECOMPRESSION', size: 1},
{id:473, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CRANIOCERVICAL DECOMPRESSION', size: 0},
{id:474, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR CERVICAL/ LUMBAR / LUMBOSACRAL DISCECTOMY  (UP TO TWO LEVELS)', size: 0},
{id:475, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR CERVICAL/ LUMBAR / LUMBOSACRAL DISCECTOMY THREE OF MORE LEVELS', size: 1},
{id:476, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANTERIOR CERVICAL DISCECTOMY ', size: 0},
{id:477, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ANTERIOR THORACOLUMBOSACRAL DISCECTOMY ', size: 1},
{id:478, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGERY FOR SACROCOCCYGEAL TERATOMA ', size: 0},
{id:479, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LAMINECTOMY OR LAMINOTOMY', size: 0},
{id:480, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'COCCYGECTOMY', size: 0},
{id:481, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'RESECTION OF 2 OR MORE THORACOLUMBOSACRAL VERTEBRAL BODIES', size: 0},
{id:482, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR VERTEBRAL COLUMN RESECTION', size: 0},
{id:483, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'POSTERIOR / POSTEROLATERAL VERTEBRAL COLUMN RESECTION (UP TO 2 SEGMENTS)', size: 0},
{id:484, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CERVICAL CORPECTOMY', size: 0},
{id:485, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'THORACOLUMBOSACRAL CORPECTOMY', size: 1},
{id:486, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REMOVAL OF A FOREIGN BODY FROM THE CERVICAL SPINE', size: 0},
{id:487, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FOREIGN BODY REMOVAL FROM THE THORACOLUMBOSACRAL SPINE -  ANTERIOR APPROACH', size: 1},
{id:488, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'FOREIGN BODY REMOVAL FROM THE THORACOLUMBOSACRAL SPINE -  POSTERIOR APPROACH', size: 0},
{id:489, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REVIEW OF ARTHRODESIS / SURGICAL TREATMENT OF PSEUDARTHROSIS OF THE THORACOLUMBOSACRAL SPINE -  ANTERIOR APPROACH', size: 1},
{id:490, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REVIEW OF ARTHRODESIS / SURGICAL TREATMENT OF PSEUDARTHROSIS OF THE CERVICAL SPINE -  POSTERIOR APPROACH', size: 0},
{id:491, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REVIEW OF ARTHRODESIS / SURGICAL TREATMENT OF PSEUDARTHROSIS OF THE THORACOLUMBOSACRAL SPINE - POSTERIOR APPROACH', size: 1},
{id:492, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REVIEW OF ARTHRODESIS / SURGICAL TREATMENT OF PSEUDARTHROSIS OF THE CERVICAL SPINE -  ANTERIOR APPROACH', size: 0},
{id:493, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'THORACOTOMY FOR SPINAL ORTHOPEDIC PROCEDURES', size: 1},
{id:494, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SACRO-COCCYGEAL CYST SURGICAL TREATMENT', size: 0},
{id:495, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF  SPINAL DEFORMITY - ANTERIOR APPROACH', size: 1},
{id:496, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF  SPINAL DEFORMITY - POSTERIOR APPROACH (UP TO THREE LEVELS)', size: 0},
{id:497, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF  SPINAL DEFORMITY - POSTERIOR APPROACH (FOUR OR MORE LEVELS)', size: 1},
{id:498, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF SPONDYLOLISTESIS', size: 0},
{id:499, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF COCCYX FRACTURE', size: 0},
{id:500, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF SACRAL FRACTURE', size: 0},
{id:501, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF FRACTURE LEVEL C1 - C2', size: 0},
{id:502, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF DISK HERNIATION', size: 0},
{id:503, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF VERTEBRAL BONE TUMOR (RESECTION WITH REPLACEMENT)', size: 1},
{id:504, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF DYSRAPHISM', size: 0},
{id:505, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF SPINAL OSTEOMYELITIS', size: 0},
{id:506, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TREATMENT OF SPINAL FRACTURE WITH SPINAL CORD INJURY', size: 1},
{id:507, general: 'SPINE', specific: 'MUSCULOSKELETAL SYSTEM', name: 'VERTEBROPLASTY IN ONE LEVEL', size: 0},
{id:508, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'ACROMIOPLASTY', size: 0},
{id:509, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LONG BONE STRETCHING AND/OR BONE TRANSPORT', size: 0},
{id:510, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BONE/CARTILAGE BIOPSY OF THE SHOULDER OR PELVIC GIRDLE', size: 0},
{id:511, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BONE/CARTILAGE BIOPSY OF UPPER OR LOWER LIMB', size: 0},
{id:512, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PLACEMENT OF INTRA-OSSEOUS WIRES, PINS, SCREWS OR METAL RODS', size: 0},
{id:513, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'CIRCULAR / SEMI-CIRCULAR EXTERNAL FIXATOR', size: 0},
{id:514, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LINEAR EXTERNAL FIXATOR', size: 0},
{id:515, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTECTOMY OF THE CLAVICLE OR SCAPULA', size: 0},
{id:516, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTECTOMY OF LONG BONES', size: 0},
{id:517, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SHOULDER GIRL OSTEOMYELITIS  - SURGICAL TREATMENT', size: 0},
{id:518, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOTOMY OF THE CLAVICLE OR SCAPULA', size: 0},
{id:519, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOTOMY OF LONG BONES', size: 0},
{id:520, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'OSTEOTOMY OF  LONG AND SHORT BONES OF THE HAND AND FOOT', size: 0},
{id:521, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'EXOSTOSIS RESECTION', size: 0},
{id:522, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TUMOR RESECTION AND RECONSTRUCTION WITH BONE TRANSPORT', size: 0},
{id:523, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BONE TUMOR RESECTION WITH REPLACEMENT (ENDOPROSTHESIS)', size: 0},
{id:524, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'LATERAL CLAVICLE RESECTION', size: 0},
{id:525, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SIMPLE BONE / SOFT TISSUE TUMOR RESECTION', size: 0},
{id:526, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'INTRA-OSSEOUS FOREIGN BODY REMOVAL', size: 0},
{id:527, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REMOVAL OF SPACERS / OTHER MATERIALS', size: 0},
{id:528, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'REMOVAL OF INTRA-OSSEOUS WIRE OR PIN', size: 0},
{id:529, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'EXTERNAL FIXATOR REMOVAL', size: 0},
{id:530, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PLATE AND/OR SCREW REMOVAL', size: 0},
{id:531, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF CLAVICLE FRACTURE', size: 0},
{id:532, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF SCAPULAR FRACTURES', size: 0},
{id:533, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF SCAPULAR FRACTURES', size: 0},
{id:534, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF VICIOUSLY CONSOLIDATED FRACTURES OF THE LONG BONES', size: 0},
{id:535, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ACROMIOCLAVICULAR DISLOCATION / FRACTURE', size: 0},
{id:536, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF ACUTE SCAPULOHUMERAL DISLOCATION / FRACTURE', size: 0},
{id:537, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF STERNOCLAVICULAR DISLOCATION/ FRACTURE', size: 0},
{id:538, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF RECURRENT / USUAL DISLOCATION OF THE SCAPULOHUMERAL JOINT', size: 0},
{id:539, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF LUXATIONS / ARTHRODESIS / CONTRACTURES WITH EXTERNAL FIXATOR', size: 0},
{id:540, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT OF DELAYED CONSOLIDATION OF PSEUDARTHROSIS OF THE CLAVICLE / SCAPULA', size: 0},
{id:541, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL TREATMENT IN POLYTRAUMATISM', size: 1},
{id:542, general: 'BONES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TREATMENT OF MALFORMATIONS/DEFORMITIES OF THE OSTEOMUSCULAR SYSTEM', size: 0},
{id:543, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENDON LENGTHENING / SHORTENING', size: 0},
{id:544, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SURGICAL BIOPSY OF TENDONS, BURSAS AND SYNOVIA', size: 0},
{id:545, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'BURSECTOMY', size: 0},
{id:546, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENDON PULLEY RECONSTRUCTION (HAND)', size: 0},
{id:547, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'PATELLAR TENDON / QUADRICEPITAL TENDON RECONSTRUCTION', size: 0},
{id:548, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'INTRA-ARTICULAR LIGAMENTARY RECONSTRUCTION OF THE KNEE', size: 0},
{id:549, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SYNOVIAL CYST RESECTION', size: 0},
{id:550, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TOTAL OR PARTIAL SYNOVECTOMY', size: 0},
{id:551, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENODESIS', size: 0},
{id:552, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENOLYSIS', size: 0},
{id:553, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENOMIORRAPHY', size: 0},
{id:554, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENOMYOTOMY / DISINSERTION', size: 0},
{id:555, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'SINGLE TENOPLASTY OR GRAFT', size: 0},
{id:556, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'INFECTIOUS TENOSYNOVITIS - DRAINAGE', size: 0},
{id:557, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'MYOTENDINOUS TRANSPOSITION / TRANSFER', size: 0},
{id:558, general: 'TENDONS / BURSAE AND SYNOVIAL MEMBRANES', specific: 'MUSCULOSKELETAL SYSTEM', name: 'TENDON OR SYNOVIAL TUMORS - SURGICAL TREATMENT', size: 0},
{id:559, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'MEDIASTINAL LESIONS BIOPSY', size: 0},
{id:560, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'BRONCHOPLASTY', size: 1},
{id:561, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'BRONCHOSCOPY FOR TRACHEAL STENOSIS', size: 0},
{id:562, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'BRONCHOSCOPY', size: 0},
{id:563, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'BRONCHOTOMY AND/OR BRONCORRAPHY', size: 1},
{id:564, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'BRONCHIAL OR ESOPHAGEAL CYST OR DUPLICATION - SURGICAL TREATMENT', size: 1},
{id:565, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'BRONCHIAL OR ESOPHAGEAL CYST OR DUPLICATION - SURGICAL TREATMENT BY VIDEOTHORACOSCOPY', size: 1},
{id:566, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'PLACEMENT OF TRACHEAL/TRACHEOBRONCHIAL LARYNGO PROSTHESIS ', size: 0},
{id:567, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'ENDOSCOPIC PLACEMENT OF LARYNGO-TRACHEAL, TRACHEAL, TRACHEO-BRONCHIC, BRONCHIAL PROSTHESIS', size: 0},
{id:568, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'EXERISIS OF UPPER AIRWAYS FACE AND NECK TUMOR ', size: 1},
{id:569, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TRACHEO-CUTANEOUS FISTULA CLOSURE', size: 0},
{id:570, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'MEDIASTINAL LYMPHADENECTOMY', size: 0},
{id:571, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'MEDIASTINOSCOPY', size: 0},
{id:572, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'MEDIASTINOTOMY', size: 0},
{id:573, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TRACHEAL TUMOR RESECTION WITH ANASTOMOSIS', size: 1},
{id:574, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'MEDIASTINAL TUMOR RESECTION', size: 1},
{id:575, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'VIDEO RESECTION OF MEDIASTINAL TUMOR', size: 1},
{id:576, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TYMECTOMY', size: 1},
{id:577, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TRACHEOPLASTY AND/OR LARYNGOTRACHEOPLASTY', size: 1},
{id:578, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TRACHEOPLASTY BY THORACIC ACCESS', size: 1},
{id:579, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TRACHEORRAPHY AND/OR CLOSURE OF TRACHEO-CUTANEOUS FISTULA', size: 0},
{id:580, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TRACHEOSCOPY', size: 0},
{id:581, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'TRACHEOSTOMY', size: 0},
{id:582, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'SURGICAL TREATMENT OF BRONCHOPLEURAL FISTULA', size: 1},
{id:583, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'SURGICAL TREATMENT OF TRACHEOESOPHAGEAL FISTULA', size: 1},
{id:584, general: 'MEDIASTINUM / TRACHEA / BRONCHI', specific: 'CHEST', name: 'MEDIASTINITIS TREATMENT', size: 1},
{id:585, general: 'BREAST', specific: 'CHEST', name: 'BREAST BIOPSY', size: 0},
{id:586, general: 'BREAST', specific: 'CHEST', name: 'BREAST ABSCESS DRAINAGE', size: 0},
{id:587, general: 'BREAST', specific: 'CHEST', name: 'PERCUTANEOUS BREAST CYST ASPIRATION', size: 0},
{id:588, general: 'BREAST', specific: 'CHEST', name: 'SUPERNUMERARY BREAST EXCISION', size: 0},
{id:589, general: 'BREAST', specific: 'CHEST', name: 'BREAST FITULECTOMY ', size: 0},
{id:590, general: 'BREAST', specific: 'CHEST', name: 'MAMMOPLASTY AFTER BARIATRIC SURGERY', size: 0},
{id:591, general: 'BREAST', specific: 'CHEST', name: 'REDUCTION MAMMOPLASTY', size: 0},
{id:592, general: 'BREAST', specific: 'CHEST', name: 'RADICAL MASTECTOMY WITH LYMPHADENECTOMY ', size: 0},
{id:593, general: 'BREAST', specific: 'CHEST', name: 'MASTECTOMY', size: 0},
{id:594, general: 'BREAST', specific: 'CHEST', name: 'MASTOPEXY', size: 0},
{id:595, general: 'BREAST', specific: 'CHEST', name: 'NON-AESTHETIC FEMALE BREAST PLASTIC', size: 0},
{id:596, general: 'BREAST', specific: 'CHEST', name: 'MALE BREAST PLASTIC', size: 0},
{id:597, general: 'BREAST', specific: 'CHEST', name: 'RECONSTRUCTIVE BREAST PLASTICS - POS MASTECTOMY W/ PROSTHESIS IMPLANT', size: 0},
{id:598, general: 'BREAST', specific: 'CHEST', name: 'QUADRANTECTOMY', size: 0},
{id:599, general: 'BREAST', specific: 'CHEST', name: 'QUADRANTECTOMY WITH AXILLARY LYMPHADENECTOMY', size: 0},
{id:600, general: 'BREAST', specific: 'CHEST', name: 'MAMMILLARY AREOLA RECONSTRUCTION', size: 0},
{id:601, general: 'BREAST', specific: 'CHEST', name: 'BREAST RECONSTRUCTION WITH MUSCLE OR MYOCUTANEOUS FLAP', size: 0},
{id:602, general: 'BREAST', specific: 'CHEST', name: 'BREAST LESION RESECTION WITH MARKING', size: 0},
{id:603, general: 'BREAST', specific: 'CHEST', name: 'RESECTION OF THE MAIN DUCTS OF THE BREAST', size: 0},
{id:604, general: 'BREAST', specific: 'CHEST', name: 'REMOVAL / REPLACEMENT OF BREAST PROSTHESIS', size: 0},
{id:605, general: 'BREAST', specific: 'CHEST', name: 'SECTORECTOMY / QUADRANTECTOMY', size: 0},
{id:606, general: 'BREAST', specific: 'CHEST', name: 'SECTORECTOMY / QUADRANTECTOMY W/ GANGLION EMPTYING', size: 0},
{id:607, general: 'BREAST', specific: 'CHEST', name: 'GINECOMASTIA TREATMENT', size: 0},
{id:608, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'STERNUM/RIB BIOPSY', size: 0},
{id:609, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'LUNG BIOPSY', size: 0},
{id:610, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PERCUTANEOUS LUNG BIOPSY', size: 0},
{id:611, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'BULECTOMY', size: 0},
{id:612, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'BULECTOMY BY VIDEOTHORACOSCOPY', size: 0},
{id:613, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'LUNG VOLUME REDUCTION SURGERY', size: 1},
{id:614, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'BRONCHOPLEURAL FISTULA CORRECTION', size: 1},
{id:615, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'COSTECTOMY', size: 0},
{id:616, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PULMONARY DECORTICATION', size: 1},
{id:617, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PULMONARY DECORTICATION BY VIDEOTHORACOSCOPY', size: 1},
{id:618, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PLEURAL DRAINAGE', size: 0},
{id:619, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'OPEN PLEURAL TUBE DRAINAGE', size: 0},
{id:620, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'STERNECTOMY WITH OR WITHOUT PROSTHESIS', size: 1},
{id:621, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PLEUROSTOMY CLOSURE', size: 0},
{id:622, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'FRACTURE, LUXATION OF STERNAL OR RIB - SURGICAL TREATMENT', size: 0},
{id:623, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PULMONARY LOBECTOMY', size: 1},
{id:624, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'VIDEOTHORACOSCOPIC PULMONARY LOBECTOMY', size: 1},
{id:625, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PULMONARY METASTAECTOMY', size: 1},
{id:626, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PLEURECTOMY', size: 1},
{id:627, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PLEURODESE', size: 0},
{id:628, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PLEUROSCOPY', size: 0},
{id:629, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PLEUROSTOMY', size: 0},
{id:630, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PNEUMONECTOMY', size: 1},
{id:631, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'RADICAL PNEUMONECTOMY', size: 1},
{id:632, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PNEUMORRAPHY', size: 0},
{id:633, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PNEUMOTOMY WITH COSTAL RESECTION FOR CAVITY DRAINAGE', size: 0},
{id:634, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'WEDGE RESECTION, LUNG TUMORECTOMY', size: 0},
{id:635, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'LOCALIZED PLEURAL TUMOR RESECTION', size: 0},
{id:636, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'FOREIGN BODY REMOVAL FROM THE CHEST WALL', size: 0},
{id:637, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PULMONARY SEGMENTECTOMY', size: 1},
{id:638, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'SYMPATHECTOMY BY VIDEOTHORACOSCOPY', size: 0},
{id:639, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'THORACECTOMY WITH PARIETAL RECONSTRUCTION (WITH PROSTHESIS)', size: 1},
{id:640, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'COMPLEX THORACECTOMY', size: 1},
{id:641, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'THORACENTESIS', size: 0},
{id:642, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'THORACOPLASTY', size: 1},
{id:643, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'THORACOSTOMY WITH CLOSED PLEURAL DRAINAGE', size: 0},
{id:644, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'EXPLORATORY THORACOTOMY', size: 1},
{id:645, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'SURGICAL TREATMENT OF CHEST WALL DEFORMITIES', size: 1},
{id:646, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'SURGICAL TREATMENT OF STERNAL FRACTURE, NECROSIS OR INFECTION', size: 0},
{id:647, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'SURGICAL TREATMENT OF RIB FRACTURES', size: 0},
{id:648, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'SURGICAL TREATMENT OF INTRAPLEURAL HEMORRHAGE', size: 1},
{id:649, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'PULMONARY THROMBOENDARTERECTOMY', size: 1},
{id:650, general: 'LUNG / PLEURA /CHEST WAL', specific: 'CHEST', name: 'VIDEOTHORACOSCOPY', size: 0},
{id:651, general: 'DIAPHRAGM', specific: 'CHEST', name: 'SUBPHRENIC ABSCESS DRAINAGE', size: 0},
{id:652, general: 'DIAPHRAGM', specific: 'CHEST', name: 'DIAPHRAGMATIC HERNIOPLASTY', size: 1},
{id:653, general: 'DIAPHRAGM', specific: 'CHEST', name: 'DIAPHRAGMATIC PACEMAKER IMPLANT', size: 0},
{id:654, general: 'DIAPHRAGM', specific: 'CHEST', name: 'DIAFRAGMATIC TUMOR RESECTION AND RECONSTRUCTION', size: 1},
{id:655, general: 'DIAPHRAGM', specific: 'CHEST', name: 'SURGICAL TREATMENT OF DIAPHRAGMATIC EVENTRATION', size: 1},
{id:656, general: 'DIAPHRAGM', specific: 'CHEST', name: 'SURGICAL TREATMENT OF DIAPHRAGMATIC HERNIA', size: 1},
{id:657, general: 'DIAPHRAGM', specific: 'CHEST', name: 'LAPAROSCOPIC TREATMENT OF DIAPHRAGMATIC HERNIA', size: 1},
{id:658, general: 'PERICARDIUM', specific: 'CHEST', name: 'DRAINAGE WITH PERICARDIAL BIOPSY', size: 0},
{id:659, general: 'PERICARDIUM', specific: 'CHEST', name: 'PERICARDIAL CYST EXERISIS', size: 1},
{id:660, general: 'PERICARDIUM', specific: 'CHEST', name: 'PERICARDIECTOMY', size: 1},
{id:661, general: 'PERICARDIUM', specific: 'CHEST', name: 'PERICARDIOCENTHESIS', size: 0},
{id:662, general: 'PERICARDIUM', specific: 'CHEST', name: 'PERICARDIOTOMY WITH PLEURO-PERICARDIAL OPENING', size: 1},
{id:663, general: 'HEART', specific: 'CHEST', name: 'INTERATRIAL COMMUNICATION OPENING', size: 1},
{id:664, general: 'HEART', specific: 'CHEST', name: 'AORTIC VALVE STENOSIS OPENING', size: 1},
{id:665, general: 'HEART', specific: 'CHEST', name: 'PULMONARY VALVE STENOSIS OPENING', size: 1},
{id:666, general: 'HEART', specific: 'CHEST', name: 'ENLARGEMENT OF THE RIGHT VENTRICLE OUTLET AND/OR PULMONARY BRANCHES', size: 1},
{id:667, general: 'HEART', specific: 'CHEST', name: 'ENLARGEMENT OF VALVE RING', size: 1},
{id:668, general: 'HEART', specific: 'CHEST', name: 'THORACIC AORTA ANEURYSMECTOMY ', size: 1},
{id:669, general: 'HEART', specific: 'CHEST', name: 'LEFT VENTRICULAR ANEURYSMECTOMY', size: 1},
{id:670, general: 'HEART', specific: 'CHEST', name: 'CARDIORRHAPHY', size: 1},
{id:671, general: 'HEART', specific: 'CHEST', name: 'CARDIOTOMY FOR FOREIGN BODY REMOVAL', size: 1},
{id:672, general: 'HEART', specific: 'CHEST', name: 'COARCTATION OF THE AORTA REPAIR', size: 1},
{id:673, general: 'HEART', specific: 'CHEST', name: 'CORRECTION OF INTERVENTRICULAR COMMUNICATION', size: 1},
{id:674, general: 'HEART', specific: 'CHEST', name: 'CORRECTION OF CONGENITAL MITRAL STENOSIS', size: 1},
{id:675, general: 'HEART', specific: 'CHEST', name: 'CORRECTION OF CONGENITAL MITRAL INSUFFICIENCY', size: 1},
{id:676, general: 'HEART', specific: 'CHEST', name: 'CORRECTION OF TETRALOGY OF FALLOT AND VARIANTS', size: 1},
{id:677, general: 'HEART', specific: 'CHEST', name: 'SINGLE ATRIUM CORRECTION', size: 1},
{id:678, general: 'HEART', specific: 'CHEST', name: 'SUPRA-AORTIC STENOSIS CORRECTION', size: 1},
{id:679, general: 'HEART', specific: 'CHEST', name: 'CAVO-ATRIAL BYPASS', size: 1},
{id:680, general: 'HEART', specific: 'CHEST', name: 'INTERATRIAL COMMUNICATION CLOSURE', size: 1},
{id:681, general: 'HEART', specific: 'CHEST', name: 'DEFIBRILLATOR CARDIOVERSOR IMPLANT', size: 0},
{id:682, general: 'HEART', specific: 'CHEST', name: 'TRANSVENOUS DEFIBRILLATOR CARDIOVERSOR IMPLANT', size: 0},
{id:683, general: 'HEART', specific: 'CHEST', name: 'CARDIAC PACEMAKER IMPLANT', size: 0},
{id:684, general: 'HEART', specific: 'CHEST', name: 'MULTI-SITE CARDIAC PACEMAKER EPICARDIAL PLACEMENT BY THORACOTOMY', size: 1},
{id:685, general: 'HEART', specific: 'CHEST', name: 'TRANSVENOUS TEMPORARY PACEMAKER IMPLANT', size: 0},
{id:686, general: 'HEART', specific: 'CHEST', name: 'VALVE PROSTHESIS IMPLANT', size: 1},
{id:687, general: 'HEART', specific: 'CHEST', name: 'CARDIAC MAPPING', size: 0},
{id:688, general: 'HEART', specific: 'CHEST', name: 'TRICUSPID VALVE REPAIR / REPLACEMENT', size: 1},
{id:689, general: 'HEART', specific: 'CHEST', name: 'VALVE REPAIR', size: 1},
{id:690, general: 'HEART', specific: 'CHEST', name: 'CARDIAC PACEMAKER POCKET REPAIR', size: 0},
{id:691, general: 'HEART', specific: 'CHEST', name: 'AORTA ROOT RECONSTRUCTION', size: 1},
{id:692, general: 'HEART', specific: 'CHEST', name: 'AORTA ROOT RECONSTRUCTION WITH COMPOSITE VALVE GRAFT', size: 1},
{id:693, general: 'HEART', specific: 'CHEST', name: 'REPOSITIONING OF ICD / PACEMAKER ELECTRODES', size: 0},
{id:694, general: 'HEART', specific: 'CHEST', name: 'INTRACARDIAC TUMOR RESECTION', size: 1},
{id:695, general: 'HEART', specific: 'CHEST', name: 'ENDOMYOCARDIOFIBROSIS RESECTION', size: 1},
{id:696, general: 'HEART', specific: 'CHEST', name: 'FOREIGN BODY REMOVAL FROM THE CARDIOVASCULAR SYSTEM BY HEMODYNAMIC TECHNIQUES', size: 0},
{id:697, general: 'HEART', specific: 'CHEST', name: 'MYOCARDIAL REVASCULARIZATION ASSOCIATED WITH VALVE SURGERY', size: 1},
{id:698, general: 'HEART', specific: 'CHEST', name: 'MYOCARDIAL REVASCULARIZATION WITH OR WITHOUT EXTRACORPOREAL CIRCULATION', size: 1},
{id:699, general: 'HEART', specific: 'CHEST', name: 'BYPASS REVASCULARIZATION / DISTAL FEMUROPOPLITEAL THROMBOENDARTERECTOMY', size: 1},
{id:700, general: 'HEART', specific: 'CHEST', name: 'TREATMENT OF COMPLICATIONS OF PROSTHETIC DEVICES, IMPLANTS AND CARDIAC AND VALVE GRAFTS', size: 1},
{id:701, general: 'HEART', specific: 'CHEST', name: 'PACEMAKER ELECTRODES REPLACEMENT', size: 0},
{id:702, general: 'HEART', specific: 'CHEST', name: 'ICD / PACEMAKER GENERATOR REPLACEMENT', size: 0},
{id:703, general: 'HEART', specific: 'CHEST', name: 'VALVE REPLACEMENT WITH MYOCARDIAL REVASCULARIZATION', size: 1},
{id:704, general: 'HEART', specific: 'CHEST', name: 'PERCUTANEOUS AORTIC VALVULOPLASTY', size: 0},
{id:705, general: 'HEART', specific: 'CHEST', name: 'PERCUTANEOUS MITRAL VALVEPLASTY', size: 0},
{id:706, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF VISCERAL ARTERY ANEURYSM', size: 1},
{id:707, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF AXILLARY, FEMORAL, POPLITEAL ANEURYSM', size: 1},
{id:708, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF CAROTID, SUBCLAVIA, ILIAC ANEURYSM ', size: 1},
{id:709, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF RUPTURED OR THROMBOSED AORTIC ANEURYSM', size: 1},
{id:710, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF RUPTURED OR THROMBOSED ANEURYSMS OF VISCERAL ARTERIES', size: 1},
{id:711, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'INFRARENAL ABDOMINAL ANEURYSMECTOMY', size: 1},
{id:712, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SUPRARENAL ABDOMINAL ANEURYSMECTOMY', size: 1},
{id:713, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'THORACO-ABDOMINAL ANEURYSMECTOMY', size: 1},
{id:714, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'DEEP FEMORAL ARTERIOPLASTY', size: 1},
{id:715, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'INTRA-AORTIC BALLOON PLACEMENT', size: 0},
{id:716, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'PULMONARY ARTERY BANDING', size: 1},
{id:717, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'ARTERIOVENOUS FISTULA CONSTRUCTION', size: 0},
{id:718, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AORTIC DISSECTION CORRECTION', size: 1},
{id:719, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'CORRECTION OF THORACOABDOMINAL AORTIC DISSECTION/ANEURYSM', size: 1},
{id:720, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'CORRECTION OF PATENT DUCTUS ARTERIOSUS', size: 1},
{id:721, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'CORRECTIONS OF AORTIC ARC ABNORMALITIES', size: 1},
{id:722, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'VEIN / ARTERY DISSECTION', size: 0},
{id:723, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'ARTERIAL EMBOLECTOMY OR THROMBOEMBOLECTOMY', size: 1},
{id:724, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AORTO-ILIAC ENDARTERECTOMY', size: 1},
{id:725, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AORTO-CAVAL, RENAL OR ILIO-ILIAC FISTULA', size: 1},
{id:726, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'CIRCUIT INSTALLATION FOR CIRCULATORY MECHANICAL ASSISTANCE', size: 1},
{id:727, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'CERVICAL AND CERVICOTHORACIC VASCULAR INJURIES', size: 1},
{id:728, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'VASCULAR INJURIES OF THE LOWER OR UPPER LIMB', size: 1},
{id:729, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'TRAUMATIC INTRAABDOMINAL VASCULAR INJURIES', size: 1},
{id:730, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'TRAUMATIC INTRATHORACIC VASCULAR INJURIES', size: 1},
{id:731, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'ARTERIOVENOUS FISTULA LIGATION', size: 0},
{id:732, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'HYPOGASTRIC ARTERY PLASTY', size: 1},
{id:733, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'INFERIOR MESENTERIC ARTERY PLASTY', size: 1},
{id:734, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SUPERIOR MESENTERIC ARTERY PLASTY', size: 1},
{id:735, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'ARTERIAL PLASTY', size: 1},
{id:736, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AORTOBIFEMORAL BYPASS', size: 1},
{id:737, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AORTOCERVICAL BYPASS', size: 1},
{id:738, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AORTOILIAC BYPASS', size: 1},
{id:739, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AXILLOFEMORAL BYPASS', size: 1},
{id:740, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SUPRA-AORTIC TRUNKS BYPASS', size: 1},
{id:741, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'FEMOROFEMORAL BYPASS', size: 1},
{id:742, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'FEMORAL POPLITEAL BYPASS', size: 1},
{id:743, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SUBCLAVIAN-FEMORAL BYPASS', size: 1},
{id:744, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'CAROTID THROMBOENDARTERECTOMY BYPASS', size: 0},
{id:745, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'ILIACO-FEMORAL THROMBOENDARTERECTOMY BYPASS', size: 1},
{id:746, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'ABDOMINAL AORTA REOPERATION', size: 1},
{id:747, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'REMOVAL OF INFECTED PROSTHESIS IN AORTIC POSITION', size: 1},
{id:748, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'REMOVAL OF INFECTED PROSTHESIS IN NON-AORTIC POSITION', size: 1},
{id:749, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'REMOVAL OF ARTIFICIAL CARDIAC STIMULATION SYSTEM', size: 0},
{id:750, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'REVASCULARIZATION OF THE UPPER LIMB', size: 1},
{id:751, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'VALVE VENOUS SEGMENT TRANSPLANTATION', size: 0},
{id:752, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF AORTIC ANEURYSM', size: 1},
{id:753, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF VISCERAL ARTERIES ANEURYSMS', size: 1},
{id:754, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF ARTERIOVENOUS FISTULA OF THE GREAT INTRATHORACIC VESSELS', size: 1},
{id:755, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF TRAUMATIC VASCULAR INJURIES OF THE CERVICAL REGION', size: 0},
{id:756, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF TRAUMATIC VASCULAR INJURIES OF THE LOWER LIMB', size: 1},
{id:757, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF TRAUMATIC VASCULAR INJURIES OF THE UPPER LIMB', size: 0},
{id:758, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF TRAUMATIC VASCULAR INJURIES OF THE ABDOMEN', size: 1},
{id:759, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'DESCENDING AORTA REPLACEMENT', size: 1},
{id:760, general: 'ARTERIAL SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AORTIC ARC REPLACEMENT', size: 1},
{id:761, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'PERCUTANEOUS ALCOHOLIZATION OF HEMANGIOMA AND VENOUS MALFORMATIONS', size: 0},
{id:762, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'BIDIRECTIONAL CAVO-PULMONARY ANASTOSMOSIS', size: 1},
{id:763, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'LYMPHOVENOUS ANASTOMOSIS', size: 0},
{id:764, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'PERCUTANEOUS PLACEMENT OF VENA CAVA FILTER', size: 0},
{id:765, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'PORTOSYSTEMIC BYPASS', size: 1},
{id:766, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'AZYGOPORTAL DISCONNECTION WITHOUT SPLENECTOMY', size: 0},
{id:767, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'LAPAROSCOPIC AZYGOPORTAL DISCONNECTION WITHOUT SPLENECTOMY', size: 0},
{id:768, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'VARICOSE VEINS SURGERY', size: 0},
{id:769, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'VARICOSE VEINS SURGERY - LOWER LIMBS', size: 0},
{id:770, general: 'VENOUS SURGERY', specific: 'CIRCULATORY SYSTEM', name: 'THROMBECTOMY OF THE VENOUS SYSTEM', size: 0},
{id:771, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ANGIOGRAPHY OF THE AORTIC ARC AND SUPRA-AORTIC TRUNKS', size: 0},
{id:772, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'RENAL ARTERY ANGIOPLASTY', size: 0},
{id:773, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'CORONARY ANGIOPLASTY AND STENTING', size: 0},
{id:774, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'CAROTID ANGIOPLASTY', size: 0},
{id:775, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ANGIOPLASTY IN CORONARY GRAFT', size: 0},
{id:776, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'INTRALUMINAL ANGIOPLASTY OF AORTA, CAVA VEIN OR ILIAC VESSELS', size: 0},
{id:777, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'INTRALUMINAL ANGIOPLASTY OF EXTREMITY VESSELS', size: 0},
{id:778, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'INTRALUMINAL ANGIOPLASTY OF NECK VESSELS / SUPRA-AORTIC TRUNKS', size: 0},
{id:779, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'BALLOON ATRIAL SEPTOSTOMY', size: 0},
{id:780, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'CARDIAC CATHETERIZATION', size: 0},
{id:781, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ENDOVASCULAR CORRECTION OF ANEURYSM / ABDOMINAL AORTA DISSECTION W/ ENDOPROSTHESIS', size: 0},
{id:782, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ENDOVASCULAR CORRECTION OF ANEURYSM / DISSECTION OF THE ABDOMINAL AND ILIAC AORTA W/ BIFURCATED PROSTHESIS', size: 0},
{id:783, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ENDOVASCULAR CORRECTION OF ANEURYSM / DISSECTION OF THE THORACIC AORTA WITH ENDOPROSTHESIS', size: 0},
{id:784, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ENDOVASCULAR CORRECTION OF ILIAC AEURYSM / DISSECTION WITH ENDOPROSTHESIS', size: 0},
{id:785, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ARTERIAL EMBOLIZATION OF GI HEMORRHAGE', size: 0},
{id:786, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'BRONCHIAL EMBOLIZATION FOR HEMOPTYSIS TREATMENT', size: 0},
{id:787, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION OF CEREBRAL ANEURYSM', size: 0},
{id:788, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION OF ARTERIOVENOUS FISTULA OF THE HEAD AND NECK', size: 0},
{id:789, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION OF DURAL ARTERIOVENOUS MALFORMATION', size: 0},
{id:790, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION OF MALFORMATION BY DIRECT PUNCTURE', size: 0},
{id:791, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION OF ARTERIOVENOUS VASCULAR MALFORMATION', size: 0},
{id:792, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ENDOVASCULAR EMBOLIZATION CEREBRAL ANEURYSM', size: 0},
{id:793, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ENDOVASCULAR EMBOLIZATION OF INTRACRANIAL TUMOR', size: 0},
{id:794, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'PERCUTANEOUS CLOSURE OF ARTERIOVENOUS FISTULA WITH COIL RELEASE', size: 0},
{id:795, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'CATHETER INTRAVASCULAR FIBRINOLYSIS', size: 0},
{id:796, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'IMPLANTATION OF INTRAVASCULAR PROSTHESIS IN LUNG VESSELS OR BRANCHES', size: 0},
{id:797, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'CORONARY STENT IMPLANTATION WITH OR WITHOUT BALLOON ANGIOPLASTY', size: 0},
{id:798, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'AORTIC VALVE PROSTHESIS TRANSCATHETER IMPLANT (TAVI)', size: 0},
{id:799, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'LEFT ATRIAL APPENDAGE CLOSURE', size: 0},
{id:800, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'PERCUTANEOUS CLOSURE OF FISTULA AND/OR SYSTEMIC PULMONARY CONNECTIONS', size: 0},
{id:801, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'PERCUTANEOUS CLOSURE OF INTRACARDIAC SHUNTS', size: 0},
{id:802, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'TREATMENT OF GIANT ANEURYSM', size: 0},
{id:803, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION FOR EPISTAXIS ', size: 0},
{id:804, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION FOR TREATMENT OF HEMATURIA ', size: 0},
{id:805, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'EMBOLIZATION FOR TREATMENT OF HEMOPTYSIS', size: 0},
{id:806, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'ENDOVASCULAR TREATMENT OF PSEUDOAEURYSM', size: 0},
{id:807, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'PERCUTANEOUS VALVULOPLASTY BY ARTERIAL OR VENOUS APPROACH', size: 0},
{id:808, general: 'ENDOVASCULAR', specific: 'CIRCULATORY SYSTEM', name: 'PERCUTANEOUS TRANSSEPTAL BALLOON VALVULOPLASTY', size: 1},
{id:809, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'LYMPH NODE BIOPSY', size: 0},
{id:810, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'AZYGOPORTAL DISCONNECTION WITH SPLENECTOMY', size: 0},
{id:811, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'PARTIAL SPLENECTOMY', size: 0},
{id:812, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'VIDEOLAPAROSCOPIC PARTIAL SPLENECTOMY', size: 0},
{id:813, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'TOTAL SPLENECTOMY', size: 0},
{id:814, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'VIDEOLAPAROSCOPIC TOTAL SPLENECTOMY', size: 0},
{id:815, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'LYMPH NODE EXCISION', size: 0},
{id:816, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'CYSTIC HYGROMA EXCISION', size: 0},
{id:817, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'LAPAROSCOPIC LIGATION OF THE THORACIC DUCT', size: 0},
{id:818, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'AXILLARY LYMPHADENECTOMY', size: 0},
{id:819, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'CELIAC TRUNK LYMPHADENECTOMY', size: 1},
{id:820, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'INGUINAL LYMPHADENECTOMY', size: 0},
{id:821, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'INGUINO-ILIAC LYMPHADENECTOMY', size: 0},
{id:822, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'MEDIASTINAL LYMPHADENECTOMY', size: 0},
{id:823, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'DEEP LYMPHADENECTOMY', size: 0},
{id:824, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'AXILLARY RADICAL LYMPHADENECTOMY', size: 0},
{id:825, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'BILATERAL CERVICAL RADICAL LYMPHADENECTOMY', size: 1},
{id:826, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'UNILATERAL CERVICAL RADICAL LYMPHADENECTOMY', size: 0},
{id:827, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'INGUINAL RADICAL LYMPHADENECTOMY', size: 0},
{id:828, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'RETROPERITONIAL LYMPHADENECTOMY', size: 1},
{id:829, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'GUIDED SELECTIVE LYMPHADENECTOMY (SENTINEL LYMPH NODE)', size: 0},
{id:830, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'LINFADENECTOMIA SUPERFICIAL', size: 0},
{id:831, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'SUPRACLAVICULAR LYMPHADENECTOMY', size: 0},
{id:832, general: 'LYMPHATIC SYSTEM / SPLEEN', specific: 'CIRCULATORY SYSTEM', name: 'SURGICAL TREATMENT OF LYMPHOEDEMA', size: 0},
{id:833, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGUS ATRESIA WITH TRACHEAL FISTULA - SURGICAL TREATMENT', size: 1},
{id:834, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGUS ATRESIA WITHOUT FISTULA', size: 1},
{id:835, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'BARIATRIC SURGERY BY VIDEOLAPAROSCOPY', size: 1},
{id:836, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'DEGASTRECTOMY WITH VAGOTOMY', size: 1},
{id:837, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'DEGASTRECTOMY WITHOUT VAGOTOMY', size: 1},
{id:838, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'DISSECTION OF THE THORACIC ESOPHAGUS', size: 1},
{id:839, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'DISTAL ESOPHAGECTOMY', size: 1},
{id:840, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC ESOPHAGECTOMY', size: 1},
{id:841, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGOCOLOPLASTY', size: 1},
{id:842, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGOCOLOPLASTY OR ESOPHAGOGASTROPLASTY', size: 1},
{id:843, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGOGASTRECTOMY', size: 1},
{id:844, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGOGASTRECTOMY', size: 1},
{id:845, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGOPLASTY / GASTROPLASTY', size: 1},
{id:846, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CERVICAL ESOPHAGORRAPHY', size: 0},
{id:847, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'THORACIC ESOPHAGORRAPHY', size: 1},
{id:848, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGOSTOMY', size: 0},
{id:849, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ESOPHAGUS STENOSIS - THORACIC SURGICAL TREATMENT ', size: 1},
{id:850, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TOTAL PHARINGO-LARYNGO-ESOPHAGECTOMY', size: 1},
{id:851, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PARTIAL GASTRECTOMY', size: 1},
{id:852, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TOTAL GASTRECTOMY', size: 1},
{id:853, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VERTICAL SLEEVE GASTRECTOMY', size: 1},
{id:854, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC GASTRECTOMY', size: 1},
{id:855, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'GASTROENTEROANASTOMOSIS', size: 1},
{id:856, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'GASTROPLASTY WITH INTESTINAL BYPASS', size: 1},
{id:857, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VERTICAL BANDED GASTROPLASTY', size: 1},
{id:858, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'GASTRORAPHY', size: 1},
{id:859, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'GASTROSTOMY', size: 0},
{id:860, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC GASTROSTOMY', size: 0},
{id:861, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ENDOSCOPIC GI BANDING LIGATION', size: 0},
{id:862, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PYLOROMIOTOMY', size: 0},
{id:863, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC PYLOROMYOTOMY', size: 0},
{id:864, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PYLOROPLASTY', size: 0},
{id:865, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC PYLOROPLASTY', size: 0},
{id:866, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RECONSTRUCTION OF CERVICAL AND/OR THORACIC ESOPHAGUS WITH SEGMENTAL BOWEL TRANSPLANTATION', size: 1},
{id:867, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'REINTERVENTION ON ESOPHAGO-GASTRIC TRANSITION', size: 1},
{id:868, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC REINTERVENTION ON ESOPHAGO-GASTRIC TRANSITION', size: 0},
{id:869, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CERVICAL AND/OR THORACIC ESOPHAGUS RESECTION', size: 1},
{id:870, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF ACHALASIA (CARDIOMYOPLASTY)', size: 0},
{id:871, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF GASTROESOPHAGEAL REFLUX', size: 1},
{id:872, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC SURGICAL TREATMENT OF GASTROESOPHAGEAL REFLUX', size: 0},
{id:873, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF ESOPHAGEAL VARICES', size: 0},
{id:874, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF ESOPHAGEAL DIVERTICULUS', size: 0},
{id:875, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC SURGICAL TREATMENT OF ESOPHAGEAL DIVERTICULUS', size: 0},
{id:876, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF PHARINGOESOPHAGEAL DIVERTICULUS', size: 0},
{id:877, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TREATMENT OF  INTERCURRENCES POST-BARIATRIC SURGERY', size: 1},
{id:878, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF MEGAESOPHAGUS WITHOUT RESECTION / CONSERVATIVE', size: 0},
{id:879, general: 'ESOPHAGUS / STOMACH', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PROXIMAL GASTRIC VAGOTOMY', size: 0},
{id:880, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'APENDICECTOMY', size: 0},
{id:881, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC APPENDICECTOMY', size: 0},
{id:882, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'COLON ATRESIA - SURGICAL TREATMENT', size: 1},
{id:883, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'COLORECTAL SURGERY ', size: 1},
{id:884, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC COLORECTAL SURGERY', size: 1},
{id:885, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PARTIAL COLECTOMY (HEMICOLECTOMY)', size: 1},
{id:886, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC PARTIAL COLECTOMY (HEMICOLECTOMY)', size: 1},
{id:887, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TOTAL COLECTOMY', size: 1},
{id:888, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC TOTAL COLECTOMY', size: 1},
{id:889, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL COLORRAPHY', size: 1},
{id:890, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'COLOSTOMY', size: 0},
{id:891, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'DUODENOPANCREATECTOMY', size: 1},
{id:892, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ENTERECTOMY', size: 1},
{id:893, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ENTEROANASTOMOSIS', size: 1},
{id:894, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ENTEROPEXY', size: 1},
{id:895, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ENTEROTOMY AND/OR ENTERORRAPHY WITH SUTURE / RESECTION', size: 1},
{id:896, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EXCISION OF LOCALIZED INTESTINAL/MESENTERIC LESION', size: 0},
{id:897, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'COLOSTOMY OR ENTEROSTOMY CLOSURE', size: 0},
{id:898, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'COLON FISTULA CLOSURE', size: 0},
{id:899, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'JEJUNOSTOMY / ILEOSTOMY', size: 0},
{id:900, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EXPLORATORY LAPAROTOMY', size: 1},
{id:901, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC LAPAROTOMY FOR DRAINAGE AND/OR BIOPSY', size: 0},
{id:902, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'LIBERAÇÃO DE ADERÊNCIAS INTESTINAIS', size: 1},
{id:903, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'LAPAROSCOPIC LYSIS OF ADHESIONS', size: 0},
{id:904, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CONGENITAL MEGACOLON - SURGICAL TREATMENT', size: 1},
{id:905, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CONGENITAL MEGACOLON - VIDEOLAPAROSCOPIC SURGICAL TREATMENT', size: 1},
{id:906, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TOTAL PROCTOCOLECTOMY', size: 1},
{id:907, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TOTAL PROCTOCOLECTOMY WITH ILEAL POUCH-ANAL ANASTOMOSIS', size: 1},
{id:908, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC TOTAL PROCTOCOLECTOMY WITH ILEAL POUCH-ANAL ANASTOMOSIS', size: 1},
{id:909, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL PROCTOPEXY', size: 0},
{id:910, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGERY FOR SIGMOID VOLVULUS', size: 1},
{id:911, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'BOWEL TUMOR EXTENDED RESECTION', size: 1},
{id:912, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL RECTOSIGMOIDECTOMY', size: 1},
{id:913, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC ABDOMINAL RECTOSIGMOIDECTOMY', size: 1},
{id:914, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINO-PERINEAL RECTOSIGMOIDECTOMY', size: 1},
{id:915, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'REVASCULARIZATION OF INTESTINAL ARTERIES', size: 1},
{id:916, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF DUODENAL AND/OR JEJUNAL ATRESIA ', size: 1},
{id:917, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF DIGESTIVE TUBE DIVERTICULUS', size: 0},
{id:918, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF ALIMENTARY TRACT DUPLICATIONS', size: 1},
{id:919, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT DUODENAL OR SMALL INTESTINE PERFORATION', size: 1},
{id:920, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL MANAGEMENT OF INTESTINAL INTUSSUSCEPTION', size: 1},
{id:921, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TREATMENT OF CONGENITAL MALFORMATIONS OF THE DIGESTIVE SYSTEM', size: 1},
{id:922, general: 'INTESTINES', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANORRECTAL TUMOR - ANORRECTAL RESECTION, SURGICAL TREATMENT', size: 1},
{id:923, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINOPERINEAL RESECTION', size: 1},
{id:924, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC ABDOMINOPERINEAL RESECTION', size: 1},
{id:925, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RECTAL PROLAPSE AMPUTATION', size: 1},
{id:926, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL BIOPSY', size: 0},
{id:927, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL CERCLAGE', size: 0},
{id:928, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL AND/OR RECTAL DIGITAL / INSTRUMENTAL DILATION ', size: 0},
{id:929, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANORRECTAL ABSCESS DRAINAGE', size: 0},
{id:930, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ISCHIORECTAL ABSCESS DRAINAGE', size: 0},
{id:931, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RETRORECTAL HEMATOMA / ABSCESS DRAINAGE', size: 0},
{id:932, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL ELECTROCAUTERIZATION', size: 0},
{id:933, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL ELECTROCAUTERIZATION', size: 0},
{id:934, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL SPHINCTEROPLASTY', size: 0},
{id:935, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'INTERNAL SPHYNCTEROTOMY AND TREATMENT OF ANAL FISSURE', size: 0},
{id:936, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EXCISION OF ANORRECTAL TUMOR/LESION', size: 0},
{id:937, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL SKIN TAG EXCISION', size: 0},
{id:938, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RECTAL FISTULA TREATMENT', size: 0},
{id:939, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'FISSURECTOMY WITH OR WITHOUT SPHINCTEROTOMY', size: 0},
{id:940, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANAL FISTULECTOMY / FISTULOTOMY', size: 0},
{id:941, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RECTAL FIXATION', size: 1},
{id:942, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC RECTAL FIXATION', size: 1},
{id:943, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'HEMORRHOIDECTOMY', size: 0},
{id:944, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANORRECTAL LACERATION - SURGICAL TREATMENT (PERINEAL ROUTE)', size: 0},
{id:945, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RUBBER BAND LIGATION FOR HEMORRHOIDS', size: 0},
{id:946, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL REMOVAL OF FECALOMA', size: 0},
{id:947, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RECTAL POLYPS/FOREIGN BODY REMOVAL', size: 0},
{id:948, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF ANAL/RECTAL CONGENITAL ABNORMALITIES', size: 0},
{id:949, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF RECTAL ABSENCE', size: 1},
{id:950, general: 'ANUS / RECTUM', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF RECTAL / ANAL PROLAPSE', size: 0},
{id:951, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PERCUTANEOUS ALCOHOL ABLATION OF LIVER TUMOR', size: 0},
{id:952, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'BILIODIGESTIVE ANASTOMOSIS ', size: 1},
{id:953, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'OPEN LIVER BIOPSY', size: 0},
{id:954, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PERCUTANEOS LIVER BIOPSY', size: 0},
{id:955, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'OPEN PANCREATIC BIOPSY', size: 1},
{id:956, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'GUIDED PERCUTANEOUS PANCREATIC BIOPSY', size: 0},
{id:957, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC BIOPSY OF THE PANCREAS', size: 0},
{id:958, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PERCUTANEOUS CHOLANGIOGRAPHY', size: 0},
{id:959, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ENDOSCOPIC RETROGRADE CHOLANGIOPANCREATOGRAPHY', size: 0},
{id:960, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CHOLECYSTECTOMY', size: 0},
{id:961, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC CHOLECYSTECTOMY WITH CHOLANGIOGRAPHY', size: 0},
{id:962, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC CHOLECYSTECTOMY WITHOUT CHOLANGIOGRAPHY', size: 0},
{id:963, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CHOLECYST-JEJUNOSTOMY', size: 1},
{id:964, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC CHOLECYST-JEJUNOSTOMY', size: 1},
{id:965, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CHOLECYSTOSTOMY', size: 0},
{id:966, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'COLEDOCO-DUODENOSTOMY', size: 1},
{id:967, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC COLEDOCO-DUODENOSTOMY', size: 1},
{id:968, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CHOLEDOCOPLASTY', size: 1},
{id:969, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CHOLEDOCOSTOMY WITH OR WITHOUT CHOLECYSTECTOMY', size: 0},
{id:970, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CHOLEDOCOTOMY WITH OR WITHOUT CHOLECYSTECTOMY', size: 0},
{id:971, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC CHOLEDOCOTOMY WITH OR WITHOUT CHOLECYSTECTOMY', size: 0},
{id:972, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'HEPATIC DEVASCULARIZATION', size: 1},
{id:973, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PERCUTANEOUS DILATION OF BILIARY STRICTURES AND ANASTOMOSIS', size: 0},
{id:974, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EXTERNAL PERCUTANEOUS BILIARY DRAINAGE', size: 0},
{id:975, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EXTERNAL DRAINAGE OF PANCREATIC PSEUDOCYSTS', size: 0},
{id:976, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'INTERNAL DRAINAGE OF PANCREATIC PSEUDOCYSTS', size: 0},
{id:977, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ENUCLEATION OF PANCREATIC TUMORS', size: 1},
{id:978, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC ENUCLEATION OF PANCREATIC TUMORS', size: 1},
{id:979, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PARTIAL HEPATECTOMY', size: 1},
{id:980, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'HEPATORRHAPHY WITH BILIARY TRACT INJURY', size: 1},
{id:981, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'HEPATOTOMY AND DRAINAGE OF ABSCESS / CYST', size: 0},
{id:982, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'IMPLANTATION OF TRANSJUGULAR INTRAHEPATIC PORTOSYSTEMIC SHUNT (TIPS)', size: 0},
{id:983, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'MARSUPIALIZATION FOR ABSCESS/CYST', size: 0},
{id:984, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'LIVER METASTASECTOMY', size: 1},
{id:985, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PARTIAL PANCREATECTOMY', size: 1},
{id:986, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC PANCREATECTOMY', size: 1},
{id:987, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PANCREATODUODENECTOMY', size: 1},
{id:988, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PANCREATICO-ENTEROSTOMY', size: 1},
{id:989, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PANCREATOTOMY FOR DRAINAGE', size: 1},
{id:990, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TRANSDUODENAL PAPILLOTOMY', size: 0},
{id:991, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RADIOFREQUENCY ABLATION OF LIVER TUMOR', size: 0},
{id:992, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EXTRAHEPATIC BILE DUCT RESECTION', size: 1},
{id:993, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RESECTION OF BLADDER OR BILIARY TUMOR (WITH OR WITHOUT HEPATECTOMY)', size: 1},
{id:994, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF BILIARY ATRESIA', size: 1},
{id:995, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF PANCREATIC CYSTS', size: 1},
{id:996, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF CICATRICAL BILIARY STRICTURES', size: 1},
{id:997, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ANNULAR PANCREAS SURGICAL TREATMENT', size: 1},
{id:998, general: 'LIVER AND BILIARY TRACT / PANCREAS', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCPIC TREATMENT OF ANNULAR PANCREAS', size: 1},
{id:999, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINOPLASTY', size: 0},
{id:1000, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL WALL BIOPSY', size: 0},
{id:1001, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SPLENIC BIOPSY', size: 0},
{id:1002, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'INTRA-ABDOMINAL BIOPSY', size: 0},
{id:1003, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL DERMOLIPECTOMY', size: 0},
{id:1004, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL RECTUS DIASTASIS - SURGICAL TREATMENT', size: 0},
{id:1005, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PRE-PERITONEAL HEMATOMA / ABSCESS DRAINAGE', size: 0},
{id:1006, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EPIPLOPLASTY', size: 0},
{id:1007, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EPIGASTRIC HERNIOPLASTY', size: 0},
{id:1008, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC EPIGASTRIC HERNIOPLASTY', size: 0},
{id:1009, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'INCISIONAL HERNIOPLASTY', size: 0},
{id:1010, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'INGUINAL HERNIOPLASTY', size: 0},
{id:1011, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RECURRENT HERNIOPLASTY', size: 0},
{id:1012, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'UMBILICAL HERNIOPLASTY', size: 0},
{id:1013, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'HERNIORRHAPHY W/ INTESTINAL RESECTION (STRANGULATED)', size: 1},
{id:1014, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'CRURAL HERNIORRHAPY', size: 0},
{id:1015, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'INCARCERATED FEMORAL HERNIA HERNIORRHAPHY', size: 0},
{id:1016, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC INGUINAL HERNIORRHAPHY', size: 0},
{id:1017, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'LUMBAR HERNIORRHAPY', size: 0},
{id:1018, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'HERNIORRHAPHY WITHOUT INTESTINAL RESECTION', size: 0},
{id:1019, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIC UMBILICAL HERNIORRHAPHY', size: 0},
{id:1020, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL WALL OR BACK LIPOSUCTION', size: 0},
{id:1021, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'OMENTECTOMY', size: 1},
{id:1022, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL PARACENTHESIS', size: 0},
{id:1023, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'PERITONEOSTOMY WITH INORGANIC MESH', size: 0},
{id:1024, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RECONSTRUÇÃO DA PAREDE ABDOMINAL COM RETALHO MUSCULAR OU MIOCUTÂNEO', size: 0},
{id:1025, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'HERNIA REPAIR', size: 0},
{id:1026, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'EXTENDED RESECTION OF SOFT TISSUE TUMOR OF THE ABDOMINAL WALL', size: 0},
{id:1027, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL NEUROBLASTOMA RESECTION', size: 1},
{id:1028, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RETROPERITONIAL TUMOR RESECTION', size: 1},
{id:1029, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'RESECTION OF THE OMENTUM', size: 1},
{id:1030, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'ABDOMINAL WALL RESUTURE (BY TOTAL DEHISCENCE / EVISCERATION)', size: 1},
{id:1031, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'SURGICAL TREATMENT OF PERITONITIS', size: 1},
{id:1032, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'TREATMENT OF PERITONEAL DISEASES', size: 1},
{id:1033, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPIA', size: 0},
{id:1034, general: 'PERITONEUM / ABDOMINAL WALL AND CAVITY', specific: 'DIGESTIVE SYSTEM AND APPENDAGES', name: 'VIDEOLAPAROSCOPY (WITHOUT INTESTINAL RESECTION)', size: 0},
{id:1035, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'BILATERAL ADRENALECTOMY ', size: 1},
{id:1036, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'UNILATERAL ADRENALECTOMY ', size: 1},
{id:1037, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'VIDEOLAPAROSCOPIC ADRENALECTOMY', size: 0},
{id:1038, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PERCUTANEOUS BIOPSY OF KIDNEY', size: 0},
{id:1039, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'OPEN BIOPSY OF KIDNEY', size: 0},
{id:1040, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'DECAPSULATION OF THE KIDNEY', size: 0},
{id:1041, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PERCUTANEOUS SCLEROTHERAPY OF RENAL CYST', size: 0},
{id:1042, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'DRAINAGE OF PYONEPHROSIS', size: 0},
{id:1043, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'KIDNEY CANCER SURGERY', size: 1},
{id:1044, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'ENDOSCOPIC KIDNEY STONE TREATMENT ', size: 0},
{id:1045, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'EXTRACORPOREAL SHOCK WAVE LITHOTRIPSY', size: 0},
{id:1046, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'LUMBOTOMY', size: 1},
{id:1047, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'OPEN PARTIAL NEPHRECTOMY', size: 1},
{id:1048, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'LAPAROSCOPIC PARTIAL NEPHRECTOMY', size: 1},
{id:1049, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'RADICAL NEPHRECTOMY', size: 1},
{id:1050, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'LAPAROSCOPIC RADICAL NEPHRECTOMY', size: 1},
{id:1051, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'NEPHROLITHOTOMY', size: 0},
{id:1052, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PERCUTANEOUS NEPHROLITHOTOMY', size: 0},
{id:1053, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PERCUTANEOUS NEPHROLITHOTRIPSY', size: 0},
{id:1054, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PYELOSTOMY', size: 0},
{id:1055, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'REPAIR OF KIDNEY WOUND', size: 0},
{id:1056, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PERCUTANEOUS INSERTION OF NEPHROSTOMY TUBE', size: 0},
{id:1057, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'TOTAL NEPHROURETERECTOMY ', size: 1},
{id:1058, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'ENTEROCYSTOSTOMY', size: 0},
{id:1059, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PERCUTSNEOUS ANTEGRADE PYELOGRAM', size: 0},
{id:1060, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'ANTEGRADE PYELOGRAM', size: 0},
{id:1061, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PYELOLITHOTOMY', size: 0},
{id:1062, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PYELOPLASTY', size: 0},
{id:1063, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'PYELOTOMY', size: 0},
{id:1064, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'RENAL ARTERY DENERVATION', size: 1},
{id:1065, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'SURGICAL TREALMENT OF HORSESHOE KIDNEY', size: 0},
{id:1066, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'KIDNEY TRANSPLANT', size: 1},
{id:1067, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'RENAL CYST ASPIRATION', size: 0},
{id:1068, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'SURGICAL REPAIR OF URETEROPELVIC JUNCTION OBSTRUCTION', size: 0},
{id:1069, general: 'KIDNEY / ADRENAL GLAND', specific: 'URINARY SYSTEM', name: 'SURGICAL TREATMENTO OF NEPHROCUTANEOUS FISTULA', size: 0},
{id:1070, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETERAL BIOPSY', size: 0},
{id:1071, general: 'URETER', specific: 'URINARY SYSTEM', name: 'PERCUTANEOUS BALLOON DILATION OF URETERAL STRICTURES', size: 0},
{id:1072, general: 'URETER', specific: 'URINARY SYSTEM', name: 'ENDOSCOPIC REMOVAL OF URETERAL STONE', size: 0},
{id:1073, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETERAL CATHETERIZATION USING FLEXIBLE CYSTOSCOPE ', size: 0},
{id:1074, general: 'URETER', specific: 'URINARY SYSTEM', name: 'LAPAROSCOPIC URETEROINTESTINAL ANASTOMOSIS', size: 0},
{id:1075, general: 'URETER', specific: 'URINARY SYSTEM', name: 'ENDOSCOPIC DOUBLE-J  CATHETER PLACEMENT', size: 0},
{id:1076, general: 'URETER', specific: 'URINARY SYSTEM', name: 'LITHOTRIPSY', size: 0},
{id:1077, general: 'URETER', specific: 'URINARY SYSTEM', name: 'TRANSURETHRAL RESECTION OF THE DISTAL END OF THE URETER', size: 0},
{id:1078, general: 'URETER', specific: 'URINARY SYSTEM', name: 'PERCUTANEOUS REMOVAL OF URETERAL CALCULUS', size: 0},
{id:1079, general: 'URETER', specific: 'URINARY SYSTEM', name: 'SURGICAL TREATMENT OF URETERAL FISTULAS', size: 0},
{id:1080, general: 'URETER', specific: 'URINARY SYSTEM', name: 'SURGICAL TREATMENT OF URETEROCELE', size: 0},
{id:1081, general: 'URETER', specific: 'URINARY SYSTEM', name: 'ENDOSCOPIC TREATMENT OF URETEROCELE ', size: 0},
{id:1082, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETERECTOMY', size: 0},
{id:1083, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETERONEOCYSTOSTOMY', size: 0},
{id:1084, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETEROENTEROPLASTY', size: 0},
{id:1085, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETEROENTEROSTOMY', size: 0},
{id:1086, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETEROILEOCYSTOSTOMY', size: 0},
{id:1087, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETEROLYSIS', size: 0},
{id:1088, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETEROLITHOTOMY', size: 0},
{id:1089, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETEROPLASTY', size: 0},
{id:1090, general: 'URETER', specific: 'URINARY SYSTEM', name: 'URETEROSIGMOIDOSTOMY', size: 0},
{id:1091, general: 'URETER', specific: 'URINARY SYSTEM', name: 'CUTANEOUS URETEROSTOMY ', size: 0},
{id:1092, general: 'URETER', specific: 'URINARY SYSTEM', name: 'UNILATERAL URETEROURETEROSTOMY ', size: 0},
{id:1093, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'BLADDER BIOPSY', size: 0},
{id:1094, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CHEMICAL CAUTERIZATION OF THE BLADDER ', size: 0},
{id:1095, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'PARTIAL CYSTECTOMY', size: 1},
{id:1096, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'LAPAROSCOPIC PARTIAL CYSTECTOMY', size: 1},
{id:1097, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'TOTAL CYSTECTOMY', size: 1},
{id:1098, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTECTOMY AND URINARY DIVERSION', size: 1},
{id:1099, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'LAPAROSCOPIC TOTAL CISTECTOMY', size: 1},
{id:1100, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'ILEOCYSTOPLASTY / ', size: 1},
{id:1101, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTOLITHOTOMY', size: 0},
{id:1102, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTOLITHOTRIPSY', size: 0},
{id:1103, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'BLADDER EXSTROPHY REPAIR', size: 1},
{id:1104, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'REDUCTION CYSTOPLASTY', size: 1},
{id:1105, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTORRHAPHY', size: 0},
{id:1106, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTOSCOPY ', size: 0},
{id:1107, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTOSTOMY', size: 0},
{id:1108, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'LAPAROSCOPIC BLADDER DIVERTICULECTOMY', size: 0},
{id:1109, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'BLADDER DIVERTICULECTOMY', size: 0},
{id:1110, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTOLITHOLAPAXY ', size: 0},
{id:1111, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'INTRAVESICAL INJECTION OF BOTULINUM TOXIN', size: 0},
{id:1112, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'BLADDER ABERRANT VESSELS SURGICAL REPAIR', size: 0},
{id:1113, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'BLADDER NECK INCISION', size: 0},
{id:1114, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'ENDOSCOPIC RESECTION OF LESION OF BLADDER', size: 0},
{id:1115, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'TRANSURETHRAL RESECTION OF BLADDER TUMORS', size: 0},
{id:1116, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'AUGMENTATION CYSTOPLASTY IN NEUROGENIC BLADDER', size: 1},
{id:1117, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'CYSTOCELE REPAIR', size: 0},
{id:1118, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'SURGICAL TREATMENT OF VESICOCUTANEOUS FISTULA', size: 0},
{id:1119, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'SURGICAL TREATMENT OF ENTEROVESICAL FISTULA', size: 0},
{id:1120, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'SURGICAL TREATMENT OF VESICOUTERINE FISTULA', size: 0},
{id:1121, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'BLADDER WASHOUT', size: 0},
{id:1122, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'VESICOURETERAL REFLUX SURGICAL REPAIR ', size: 0},
{id:1123, general: 'BLADDER', specific: 'URINARY SYSTEM', name: 'LASER PHOTOCOAGULATION OF BLADDER TUMOR ', size: 0},
{id:1124, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'URETHRAL BIOPSY', size: 0},
{id:1125, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'ENDOSCOPIC REMOVAL OF FOREIGN BODY FROM URETHRA', size: 0},
{id:1126, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'MEATOPLASTY', size: 0},
{id:1127, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'MEATOPLASTY WITH FLAP', size: 0},
{id:1128, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'CLOSURE OF FISTULA OF URETHRA', size: 0},
{id:1129, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'EXCISION OF DIVERTICULUM OF URETHRA', size: 0},
{id:1130, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'URETHROPLASTY', size: 0},
{id:1131, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'INTERNAL URETHROTOMY', size: 0},
{id:1132, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'REMOVAL OF A FOREIGN BODY/STONE FROM THE URETHRA', size: 0},
{id:1133, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'ENDOSCOPIC EXAMINATION OF URETHRA', size: 0},
{id:1134, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'URETHROPLASTY.', size: 0},
{id:1135, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'TOTAL URETHRECTOMY', size: 0},
{id:1136, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'EXCISION OF URETHRAL CARUNCLE', size: 0},
{id:1137, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'URETHRAL VALVE RESECTION', size: 0},
{id:1138, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'REPAIR OF URETHROCELE', size: 0},
{id:1139, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'PERIURETHRAL ABSCESS DRAINAGE', size: 0},
{id:1140, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'URETHROCUTANEOUS FISTULA SURGICAL REPAIR', size: 0},
{id:1141, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'REPAIR OF URETHRORECTAL FISTULA', size: 0},
{id:1142, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'URETHRAL CANCER SURGICAL REPAIR', size: 0},
{id:1143, general: 'URETHRA', specific: 'URINARY SYSTEM', name: 'SURGICAL TREATMENT OF URINARY INCONTINENCE', size: 0},
{id:1144, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'PHOTOSELECTIVE VAPORISATION OF PROSTATE', size: 0},
{id:1145, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'EPIDIDYMAL BIOPSY', size: 0},
{id:1146, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'PROSTATE BIOPSY', size: 0},
{id:1147, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'DRAINAGE OF PROSTATIC ABSCESS', size: 0},
{id:1148, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'DRAINAGE OF EPIDIDYMAL AND/OR VAS DEFERENS ABSCESS', size: 0},
{id:1149, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'EPIDIDYMECTOMY', size: 0},
{id:1150, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'SPERMATOCELECTOMY', size: 0},
{id:1151, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'EXCISION OF EPIDIDYMAL CYST', size: 0},
{id:1152, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'SEMINAL VESICULECTOMY', size: 0},
{id:1153, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'VAS DEFERENS SURGICAL REPAIR', size: 0},
{id:1154, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'SUPRAPUBIC PROSTATECTOMY', size: 1},
{id:1155, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'RADICAL PROSTATECTOMY', size: 1},
{id:1156, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'LAPAROSCOPIC RADICAL PROSTATECTOMY', size: 1},
{id:1157, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'EJACULATORY DUCT RECANALIZATION', size: 0},
{id:1158, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'ENDOSCOPIC RESECTION OF PROSTATE (TUR)', size: 0},
{id:1159, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'ENDOSCOPIC REVISION OF PROSTATIC BED BLEEDING', size: 0},
{id:1160, general: 'PROSTATE / SPERMATIC CORD', specific: 'REPRODUCTIVE SYSTEM', name: 'VASECTOMY', size: 0},
{id:1161, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'PENIS AMPUTATION', size: 0},
{id:1162, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'PENILE BIOPSY', size: 0},
{id:1163, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'PREPUTIOPLASTY', size: 0},
{id:1164, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'REPAIR OF EPISPADIAS/HYPOSPADIAS', size: 0},
{id:1165, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'PENILE PROSTHESIS IMPLANTATION', size: 0},
{id:1166, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'FRENULOPLASTY OF PENIS', size: 0},
{id:1167, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'TOTAL PENILE RECONSTRUCTION', size: 0},
{id:1168, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'CIRCUMCISION', size: 0},
{id:1169, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'FEMINISING GENITAL SURGERY ', size: 0},
{id:1170, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'PENIS REPLANTATION', size: 0},
{id:1171, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'PENILE REVASCULARIZATION SURGERY', size: 0},
{id:1172, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGICAL TREATMENT OF PRIAPISM', size: 0},
{id:1173, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'OPERATION FOR PEYRPNIES DISEASE', size: 0},
{id:1174, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGICAL REPAIR OF PENILE FRACTURE', size: 0},
{id:1175, general: 'PENIS', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGERY FOR MALE INCONTINENCE', size: 0},
{id:1176, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'TESTICULAR AUTOTRANSPLANTATION', size: 0},
{id:1177, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'TESTICULAR BIOPSY', size: 0},
{id:1178, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'TESTICULAR BIOPSY', size: 0},
{id:1179, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'SCROTAL EXPLORATION', size: 0},
{id:1180, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'SCROTAL CYST REMOVAL', size: 0},
{id:1181, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'SCROTAL EXPLORATION', size: 0},
{id:1182, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'PROTHESIS OF TESTIS (INSERTION)', size: 0},
{id:1183, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'ORCHIOPEXY', size: 0},
{id:1184, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'ORCHIDECTOMY', size: 0},
{id:1185, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'SCROTAL RECONSTRUCTION', size: 0},
{id:1186, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'SCROTAL RECONSTRUCTION WITH PEDICLED INGUINAL FLAP', size: 0},
{id:1187, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'SCROTAL PARTIAL EXCISION', size: 0},
{id:1188, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'TESTICULAR TUMOR REMOVAL', size: 0},
{id:1189, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'HYDROCELECTOMY', size: 0},
{id:1190, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'TESTICULAR TORSION SURGERY', size: 0},
{id:1191, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'OPERATION ON VARICOLCELE', size: 0},
{id:1192, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGICAL TREATMENT OF GENITAL ELEPHANTIASIS', size: 0},
{id:1193, general: 'SCROTUM / TESTICLE', specific: 'REPRODUCTIVE SYSTEM', name: 'VARICOCELECTOMY', size: 0},
{id:1194, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'INCISION OF INTROITUS OF VAGINA', size: 0},
{id:1195, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'VAGINAL BIOPSY', size: 0},
{id:1196, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'VULVAR BIOPSY', size: 0},
{id:1197, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGERY FOR VAGINAL VAULT PROLAPSE', size: 0},
{id:1198, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'CLITORIDECTOMY / CLITOROPLASTY', size: 0},
{id:1199, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'COLPOCLEISIS (LE FORT SURGERY)', size: 0},
{id:1200, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'COLPOPERINEOCLEISIS', size: 0},
{id:1201, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'ANTERIOR AND POSTERIOR VAGINAL WALL REPAIR', size: 0},
{id:1202, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'VAGINOPLASTY', size: 0},
{id:1203, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'COLPORRHAPHY', size: 0},
{id:1204, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'CULDOCENTESIS', size: 0},
{id:1205, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'RECONSTRUCTION OF VAGINA', size: 0},
{id:1206, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'EXCISION OF EXCESS LABIAL TISSUE', size: 0},
{id:1207, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'EPISIOTOMY / EPISIOTOMY REPAIR', size: 0},
{id:1208, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'EXCISION OF LESION OF VAGINA (CYSTS)', size: 0},
{id:1209, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'BARTHOLIN`S CYST EXCISION', size: 0},
{id:1210, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'EXCISION OF LESION OF VULVA/PERINEUM', size: 0},
{id:1211, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'REMOVAL OF A FOREIGN BODY FROM THE VAGINA', size: 0},
{id:1212, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'HYMENECTOMY', size: 0},
{id:1213, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'MARSUPIALISATION OF BARTHOLIN CYST', size: 0},
{id:1214, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'BURCH COLPOSUSPENSION', size: 0},
{id:1215, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'MYOCUTANEOUS FLAPS FOR PERINEAL RECONSTRUCTION ', size: 0},
{id:1216, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'RESECTION OF A RECTOVAGINAL SEPTUM TUMOR', size: 0},
{id:1217, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'SUTURE OF PERINEAL LACERATIONS', size: 0},
{id:1218, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'VAGINAL LABIAPLASTY', size: 0},
{id:1219, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'REPAIR OF RECTOVAGINAL FISTULA', size: 0},
{id:1220, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'REPAIR OF URETHROVAGINAL FISTULA', size: 0},
{id:1221, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'REPAIR OF VESICOVAGINAL FISTULA', size: 0},
{id:1222, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'VAGINAL LABIAPLASTY', size: 0},
{id:1223, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'VAGINAL SEPTUM RESECTION', size: 0},
{id:1224, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'RADICAL VULVECTOMY', size: 0},
{id:1225, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'PARTIAL VULVECTOMY', size: 0},
{id:1226, general: 'VULVA / VAGINA', specific: 'REPRODUCTIVE SYSTEM', name: 'SIMPLE VULVECTOMY', size: 0},
{id:1227, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'AMNIOCENTESIS', size: 0},
{id:1228, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'AMPUTATION OF CERVIX UTERI', size: 0},
{id:1229, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'NORMAL DELIVERY', size: 0},
{id:1230, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'ENDOMETRIAL BIOPSY', size: 0},
{id:1231, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'UTERINE CERVIX BIOPSY', size: 0},
{id:1232, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'CERCLAGE FOR CERVICAL INCOMPETENCE', size: 0},
{id:1233, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'CAESAREAN DELIVERY', size: 0},
{id:1234, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'OPEN FETAL SURGERY', size: 1},
{id:1235, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'ENDOSCOPIC FETAL SURGERY', size: 0},
{id:1236, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'COLPECTOMY', size: 0},
{id:1237, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'PELVIC ORGAN PROLAPSE SURGICAL REPAIR', size: 0},
{id:1238, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'CURETTAGE OF RETAINED PRODUCTS OF CONCEPTION', size: 0},
{id:1239, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'DILATION AND CURTTAGE', size: 0},
{id:1240, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'CURETTAGE FOR MOLAR PREGNANCY TREATMENT', size: 0},
{id:1241, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'DILATATION OF CERVIX UTERI', size: 0},
{id:1242, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'MANUAL VACUUM ASPIRATION (MVA)', size: 0},
{id:1243, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'CERVIX UTERI POLYPECTOMY', size: 0},
{id:1244, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'CONIZATION OF CERVIX', size: 0},
{id:1245, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'UTERINE POLYP REMOVAL', size: 0},
{id:1246, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'VAGINAL HYSTERECTOMY', size: 0},
{id:1247, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'HYSTERECTOMY WITH BILATERAL SALPINGO-OOPHORECTOMY', size: 0},
{id:1248, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'RADICAL HYSTERECTOMY ', size: 1},
{id:1249, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'POST-PARTUM HYSTERECTOMY', size: 0},
{id:1250, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'SUBTOTAL HYSTERECTOMY', size: 0},
{id:1251, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'TOTAL HYSTERECTOMY', size: 0},
{id:1252, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'RADICAL HYSTERECTOMY AND LYMPHADENECTOMY (WERTHEIMS)', size: 1},
{id:1253, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'LAPAROSCOPIC HYSTERECTOMY', size: 0},
{id:1254, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'RECONSTRUCTION OF UTERUS', size: 0},
{id:1255, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'HYSTEROSCOPY (DIAGNOSTIC)', size: 0},
{id:1256, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGICAL HYSTEROSCOPY', size: 0},
{id:1257, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'ARTIFICIAL INSEMINATION.', size: 0},
{id:1258, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'INSERTION AND REMOVAL OF INTRAUTERINE DEVICES', size: 0},
{id:1259, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGICAL MANAGEMENT OF UTERINE INVERSION', size: 0},
{id:1260, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'FEMALE STERILISATION (TUBAL OCCLUSION)', size: 0},
{id:1261, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'METROPLASTY (UTERINE SEPTUM SURGERY)', size: 0},
{id:1262, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'MYOMECTOMY', size: 0},
{id:1263, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'LAPAROSCOPIC MYOMECTOMY', size: 0},
{id:1264, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'TRACHELECTOMY', size: 0},
{id:1265, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'RADICAL TRACHELECTOMY', size: 0},
{id:1266, general: 'UTERUS', specific: 'REPRODUCTIVE SYSTEM', name: 'RECONSTRUCTION OF THE UTERINE CERVIX', size: 0},
{id:1267, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'ANEXECTOMY', size: 0},
{id:1268, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'FOLLICULAR ASPIRATION', size: 0},
{id:1269, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'FEMALE STERILIZATION ', size: 0},
{id:1270, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'OVARIAN VEIN LIGATION', size: 0},
{id:1271, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'OVARIAN CANCER DEBULKING SURGERY', size: 1},
{id:1272, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'OVARIAN CANCER DEBULKING SURGERY (LAPAROSCOPIC)', size: 1},
{id:1273, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'NEOSALPINGOSTOMY FOR DISTAL TUBAL OBSTRUCTION', size: 0},
{id:1274, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'OOPHORECTOMY / OOPHOROPLASTY', size: 0},
{id:1275, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'ENDOSCOPIC REVERSAL OF FEMALE STERILISATION', size: 0},
{id:1276, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'SALPINGECTOMY', size: 0},
{id:1277, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'LAPAROSCOPIC SALPINGECTOMY', size: 0},
{id:1278, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'FALLOPIAN TUBE RECANALIZATION', size: 0},
{id:1279, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'LAPAROSCOPIC FALLOPIAN TUBE RECANALIZATION', size: 0},
{id:1280, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'OVARIAN TRANSPOSITION', size: 0},
{id:1281, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'ECTOPIC PREGNANCY SURGICAL TREATMENT', size: 0},
{id:1282, general: 'FALLOPIAN TUBES / OVARIES', specific: 'REPRODUCTIVE SYSTEM', name: 'ECTOPIC PREGNANCY SURGICAL TREATMENT (LAPAROSCOPIC)', size: 0},
{id:1283, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'PELVIC ABSCESS DRAINAGE', size: 0},
{id:1284, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'TOTAL EXENTERATION OF PELVIS', size: 1},
{id:1285, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'HEMIPELVECTOMY', size: 1},
{id:1286, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'PELVIC ADHESIONS SURGERY', size: 0},
{id:1287, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'UTEROSACRAL LIGAMENT SUSPENSION', size: 0},
{id:1288, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'PELVIC LYMPHADENECTOMY', size: 0},
{id:1289, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'PELVIC VARICOSE VEINS SURGICAL TREATMENT', size: 0},
{id:1290, general: 'PELVIC CAVITY', specific: 'REPRODUCTIVE SYSTEM', name: 'SURGICAL TREATMENT OF PERITONEAL ENDOMETRIOSIS', size: 0},
{id:1291, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'STEREOTACTIC BIOPSY', size: 0},
{id:1292, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'UNILATERAL CINGULOTOMY / CAPSULOTOMY', size: 0},
{id:1293, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'CRANIOTOMY FOR BRAIN TUMOR', size: 1},
{id:1294, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'EXTERNAL VENTRICULAR DRAINAGE (EVD)', size: 0},
{id:1295, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'CEREBROSPINAL FLUID SHUNTING', size: 0},
{id:1296, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'BRAIN ABSCESS DRAINAGE', size: 1},
{id:1297, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'INTRACRANIAL HEMATOMA EVACUATION', size: 1},
{id:1298, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'HEAD & NECK TUMOR EMBOLIZATION', size: 0},
{id:1299, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'TRANSSPHENOIDAL APPROACH TO PITUITARY TUMORS', size: 1},
{id:1300, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'DEEP BRAIN STIMULATION', size: 0},
{id:1301, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'ENDOSCOPIC BRAIN SURGERY', size: 0},
{id:1302, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'SKULL BASE TUMOR SURGERY', size: 1},
{id:1303, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'MICROSURGERY FOR CEREBRAL ANEURYSMS', size: 1},
{id:1304, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'TEMPORAL LOBE RESECTION / CORTICO-AMYGDALOHIPPOCAMPECTOMY (CAH)', size: 1},
{id:1305, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'MICROSURGERY FOR CEREBRAL ARTERIOVENOUS MALFORMATIONS', size: 1},
{id:1306, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'BRAIN TUMOR SURGERY', size: 1},
{id:1307, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'CEREBROVASCULAR NEUROSURGERY', size: 1},
{id:1308, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'VENTRICULOPERITONEAL SHUNT REVISION/REMOVAL', size: 0},
{id:1309, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'ENDOSCOPIC THIRD VENTRICULOSTOMY', size: 0},
{id:1310, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'SURGICAL TREATMENT OF EPILEPSY', size: 0},
{id:1311, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'EPIDURAL HEMATOMA SURGICAL TREATMENT', size: 1},
{id:1312, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'SURGERY FOR INTRACEREBRAL HEMORRHAGE', size: 1},
{id:1313, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'SUBDURAL HEMATOMA SURGICAL MANAGEMENT', size: 1},
{id:1314, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'SURGICAL TREATMENT OF ARNOLD-CHIARI MALFORMATION', size: 1},
{id:1315, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'STEREOTACTIC RADIOSURGERY FOR MOVEMENT DISORDERS', size: 0},
{id:1316, general: 'BRAIN', specific: 'NERVOUS SYSTEM', name: 'SURGICAL REPLACEMENT OF IMPLANTABLE PULSE GENERATORS IN DEEP BRAIN STIMULATION', size: 0},
{id:1317, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'CORDOTOMY AND MYELOTOMY', size: 0},
{id:1318, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'MYELOMENINGOCELE SURGICAL REPAIR', size: 0},
{id:1319, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'LUMBOPERITONEAL SHUNT SURGERY', size: 0},
{id:1320, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'LUMBAR DECOMPRESSION SURGERY', size: 1},
{id:1321, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'SURGICAL DIAGNOSTIC EXPLORATION FOR BILATERAL SUBDURAL ELECTRODE IMPLANTATION ', size: 0},
{id:1322, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'INTRATHECAL PUMP SURGERY', size: 0},
{id:1323, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'SYRINGOMYELIA SURGICAL TREATMENT', size: 1},
{id:1324, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'RESECTION OF INTRADURAL EXTRAMEDULLARY SPINAL TUMORS', size: 0},
{id:1325, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'MICROSURGICAL SPINAL CORD TUMOR RESECTION', size: 0},
{id:1326, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'SPINAL CORD BIOPSY', size: 0},
{id:1327, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'SURGICAL TREATMENT OF SPINAL ARTERIOVENOUS MALFORMATIONS', size: 1},
{id:1328, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'LUMBAR PUNCTURE', size: 0},
{id:1329, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'PERCUTANEOUS RHIZOTOMY', size: 0},
{id:1330, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'LUMBAR SYMPATHECTOMY', size: 0},
{id:1331, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'SURGICAL MANAGEMENT OF BONY MALFORMATIONS OF THE CRANIOVERTEBRAL JUNCTION', size: 1},
{id:1332, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'SURGICAL TREATMENT OF CEREBROSPINAL FLUID (CSF) F', size: 0},
{id:1333, general: 'SPINAL CORD', specific: 'NERVOUS SYSTEM', name: 'SPINAL CORD INJURY SURGICAL TREATMENT', size: 1},
{id:1334, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'CRANIAL NERVE BLOCK WITH ALCOHOL', size: 0},
{id:1335, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'NERVE BIOPSY', size: 0},
{id:1336, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'PERCUTANEOUS FACET JOINT DENERVATION', size: 0},
{id:1337, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'MICROVASCULAR DECOMPRESSION OF CRANIAL NERVES', size: 0},
{id:1338, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'INTERFASCICULAR NERVE GRAFTING', size: 0},
{id:1339, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'NERVE GRAFT MICROSURGERY', size: 0},
{id:1340, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'BRACHIAL PLEXUS SURGERY', size: 0},
{id:1341, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'MICROSURGICAL NEUROLYSIS', size: 0},
{id:1342, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'NERVE REPAIR MICROSURGERY', size: 0},
{id:1343, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'MORTONS NEUROMA RESECTION', size: 0},
{id:1344, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'NERVE TRANSPOSITION', size: 0},
{id:1345, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'SURGICAL TREATMENT OF COMPRESSIVE NEUROPATHY', size: 0},
{id:1346, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'DECOMPRESSION SURGERY FOR THORACIC OUTLET SYNDROME', size: 0},
{id:1347, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'TREATMENT OF NEUROVEGETATIVE SYSTEM INJURY', size: 0},
{id:1348, general: 'NERVES', specific: 'NERVOUS SYSTEM', name: 'MICROSURGICAL RESECTION OF PERIPHERAL NERVE SHEATH TUMORS', size: 0},
{id:1349, general: '', specific: 'ENDOSCOPIC PROCEDURES', name: 'UPPER GI ENDOSCOPY WITH BIOPSY', size: 0},
{id:1350, general: '', specific: 'ENDOSCOPIC PROCEDURES', name: 'COLONOSCOPY (WITH BIOPSY)', size: 0},
{id:1351, general: '', specific: 'ENDOSCOPIC PROCEDURES', name: 'ENDOSCOPIC DILATION WITH BOUGIES', size: 0},
{id:1352, general: '', specific: 'ENDOSCOPIC PROCEDURES', name: 'ENDOSCOPIC ELECTROCOAGULATION', size: 0},
{id:1353, general: '', specific: 'ENDOSCOPIC PROCEDURES', name: 'ENDOSCOPIC REMOVAL OF FOREIGN BODIES', size: 0},
{id:1354, general: '', specific: 'ENDOSCOPIC PROCEDURES', name: 'ENDOSCOPIC REMOVAL OF POLYPS IN THE GASTROINTESTINAL TRACT', size: 0},
{id:1355, general: '', specific: 'ENDOSCOPIC PROCEDURES', name: 'SIGMOIDOSCOPY (WITH BIOPSY)', size: 0},
{id:1356, general: '', specific: 'OTHER', name: 'BONE MARROW BIOPSY', size: 0},
{id:1357, general: '', specific: 'OTHER', name: 'CT GUIDED PERCUTANEOUS BIOPSY', size: 0},
{id:1358, general: '', specific: 'OTHER', name: 'BIOPSIES FOR CANCER STAGING', size: 0},
{id:1359, general: '', specific: 'OTHER', name: 'CHEMICAL CAUTERIZATION', size: 0},
{id:1360, general: '', specific: 'OTHER', name: 'LONG-TERM CATHETER PLACEMENT', size: 0},
{id:1361, general: '', specific: 'OTHER', name: 'TENCKHOFF CATHETER PLACEMENT (OR REMOVAL)', size: 0},
{id:1362, general: '', specific: 'OTHER', name: 'INSERTION OF CENTRAL VENOUS CATHETER', size: 0},
{id:1363, general: '', specific: 'OTHER', name: 'LONG-TERM CATHETER REMOVAL', size: 0},
]};
