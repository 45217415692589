import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { loginGuard, webGuard } from './guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'score',
    pathMatch: 'full'
  },
  {
    path: 'score',
    loadChildren: () => import('./pages/score/score.module').then( m => m.ScorePageModule),
    canActivate: [loginGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [webGuard]
  },
  // {
  //   path: 'initial',
  //   loadChildren: () => import('./pages/initial/initial.module').then( m => m.InitialPageModule),
  //   canActivate: [loginGuard]
  // },
  {
    path: 'graph',
    loadChildren: () => import('./pages/graph/graph.module').then( m => m.GraphPageModule),
    canActivate: [loginGuard]
  },
  {
    path: 'scorelist',
    loadChildren: () => import('./pages/score-list/score-list.module').then( m => m.ScoreListPageModule),
    canActivate: [loginGuard]
  },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule),
  //   canActivate: [loginGuard]
  // },
  {
    path: 'profile',
    loadChildren: () => import('./pages/hosp-profile/hosp-profile.module').then( m => m.HospProfilePageModule),
    canActivate: [loginGuard]
  },
  {
    path: 'profileList',
    loadChildren: () => import('./pages/profile-list/profile-list.module').then( m => m.ProfileListPageModule),
    canActivate: [loginGuard]
  },
  // {
  //   path: 'cover',
  //   loadChildren: () => import('./pages/cover/cover.module').then( m => m.CoverPageModule)
  // },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule),
    canActivate: [loginGuard]
  },
  // {
  //   path: 'tou',
  //   loadChildren: () => import('./pages/term-of-use/term-of-use.module').then( m => m.TermOfUsePageModule),
  //   canActivate: [loginGuard]
  // },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule),
    canActivate: [loginGuard]
  },
  {
    path: 'badges',
    loadChildren: () => import('./pages/access-web/access-web.module').then( m => m.AccessWebPageModule)
  },
  {
    path: 'web',
    loadChildren: () => import('./pages/webpage/webpage.module').then( m => m.WebpagePageModule)
  },
  // {
  //   path: 'calendar',
  //   loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
