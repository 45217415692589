import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SCORE_KEY } from 'src/environments/environment';
import { UserScore } from '../classes.interfaces';
import { FirebaseService } from './firebase.service';
import {
  Firestore,
  collection,
  collectionData,
  getDocs,
  query,
  where,
} from '@angular/fire/firestore';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ScoreListService {
  private firestore: Firestore = inject(Firestore); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT
  private auth: Auth = inject(Auth); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT
  private scores: BehaviorSubject<UserScore[]> = new BehaviorSubject<
    UserScore[]
  >([]);

  private scoreSub!: Subscription;

  // #####################################################
  // #####################################################
  // ################    CONSTRUCTOR    ##################
  constructor(private fire: FirebaseService) {
    console.log('Constructor - ScoreListService'); // ------- CONSOLE PRINT ----------  //
  }

  init() {
    console.log('init - ScoreListService'); // ------- CONSOLE PRINT ----------  //

    onAuthStateChanged(this.auth, (user) => {
      //console.log('ScoreListService - user.email', user?.email);
      console.log('onAuthStateChanged - ScoreListService'); // ------- CONSOLE PRINT ----------  //
      let colData$: any;

      if (user) {
        const path = this.fire.getPathCol(SCORE_KEY, false);
        try {
          const scoreQuery = query(
          // GET FIRE REFERENCE FOR THE WHOLE SCORE COLLECTION
          collection(this.firestore, path),
          where('userId', '==', user.email)
        );
        //
        colData$ = collectionData(scoreQuery, {
          // $ sign - convention to make it easier to identify oberservables
          idField: 'id', //DEFINES THE NAME OF THE FIELD WHICH THE FUNCTION WILL PLACE THE DOC ID BE
        }) as Observable<UserScore[]>;
        } catch (e) {
          console.error(  // --------------------- CONSOLE PRINT ----  //
            'score-list.service.ts - MESSAGE:',
             e.message,
             'CODE:',
             e.code
          );
        };

        //
        this.scoreSub = colData$.subscribe((score) => {
          const scoreOrder = [
            ...score.sort(
              (a, b) =>
                new Date(b.surgery.surgeryDateTime).getTime() -
                new Date(a.surgery.surgeryDateTime).getTime()
            ),
          ];
          this.scores.next(scoreOrder);
        });
      } else {
        this.scores.next([]);
        if (!this.scoreSub?.closed) {
          this.scoreSub?.unsubscribe();
        }
      }
    });
  }

  getObservable(): Observable<UserScore[]> {
    return this.scores.asObservable();
  }

  async addScore(score: UserScore) {
    //const ts = new Date();
    // eslint-disable-next-line @typescript-eslint/quotes
    score.timeStamp = format(new Date(), "yyyy-MM-dd'T'HH:mm");

    const surgDate = score.surgery?.surgeryDateTime;
    // DATE OF SURGERY
    const day = new Date(surgDate).getDate();
    const month = new Date(surgDate).getMonth();
    const year = new Date(surgDate).getFullYear();
    score.dateMonthYear = `${day}/${month + 1}/${year}`;
    score.monthYear = `${month + 1}/${year}`;

    // USER CURRENT ID
    score.userId = this.auth.currentUser.email;
    try {
      return await this.fire.addToCollection<UserScore>(score, SCORE_KEY, false);
    } catch (e) {
      console.error(  // --------------------- CONSOLE PRINT ----  //
        'score-list.service.ts - addScore - MESSAGE:',
         e.message,
         'CODE:',
         e.code
      );
    return null;
    };

  }

  async removeScore(us: UserScore): Promise<void |boolean> {
    return await this.fire.removeByDocID(us.id, SCORE_KEY, false);
  }

  async udpateScore(us: UserScore, obsUpdate: string) {
    this.fire.updateDocByID(us.id, SCORE_KEY, false, { obs: obsUpdate });
  }

async getScoreList(){
  const path = this.fire.getPathCol(SCORE_KEY, false);
  return await this.fire.getCollectionByQuery<UserScore>(path,'userId','==',this.auth.currentUser.email);
}

fullMonth(month:string){



}

  // update(): void {
  //   this.scoreList.next(this.sl);
  // }

  // getScores(): Subject<UserScore[]> {
  //   return this.scoreList;
  // }

  // removeScore(us: UserScore): void {
  //   this.fire.removeScoresByScoreID(us.id);
  //   const index = this.sl.indexOf(us);
  //   this.sl.splice(index, 1);
  //   this.scoreList.next(this.sl);
  // }

  //public async init(): Promise<void> {
  // //console.log('ScoreListService - init');
  // this.scoreList = new Subject<UserScore[]>();
  //this.sl = await this.fire.getScores();
  // this.scoreList.next(this.sl);
  //this.userP = await this.dblocal.get(USER_DATA_KEY);
  //}
}
