import { Injectable, inject } from '@angular/core';
import { HospProfile } from '../classes.interfaces';
import { FirebaseService } from './firebase.service';

import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import {
  Firestore,
  collection,
  collectionData,
} from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HOSP_KEY, USER_KEY } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HProfileService {

  private firestore: Firestore = inject(Firestore); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT
  private auth: Auth = inject(Auth); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT
  private hospProfeSub: Subscription;
  private hospProfiles: BehaviorSubject<HospProfile[]> = new BehaviorSubject<
    HospProfile[]
  >([]);
  private hospProfile: BehaviorSubject<HospProfile> =
    new BehaviorSubject<HospProfile>({});

  constructor(private fire: FirebaseService) {
    console.log('Constructor - HProfileService'); // ------- CONSOLE PRINT ----------  //
  }

  init(){
    console.log('init - HProfileService'); // ------- CONSOLE PRINT ----------  //

    onAuthStateChanged(this.auth, (user) => {

      console.log('onAuthStateChanged - HProfileService'); // ------- CONSOLE PRINT ----------  //

      if (user) {
        const path = this.fire.getPathCol(USER_KEY, true, HOSP_KEY);
        let colData$: any;
       try {
          const colRef = collection(this.firestore, path);
        colData$ = collectionData(colRef, {
          // $ sign - convention to make it easier to identify oberservables
          idField: 'id', //DEFINES THE NAME OF THE FIELD WHICH THE FUNCTION WILL PLACE THE DOC ID BE
        }) as Observable<HospProfile[]>;

       } catch (e) {
         console.error(  // --------------------- CONSOLE PRINT ----  //
           'h-profile.service.ts - onAuthStateChanged - MESSAGE:',
            e.message,
            'CODE:',
            e.code
         );
       };

        //
        this.hospProfeSub = colData$.subscribe((hProfs) => {
          // const hProfOrder = hProfs.sort((a, b): number =>
          //   b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1
          // );
          const hProfOrder = hProfs.sort(
            (a, b) =>
              new Date(b.timeStamp).getTime() -
              new Date(a.timeStamp).getTime()
          );

          this.hospProfiles.next(hProfOrder);
        });
      } else {
        this.hospProfiles.next([]);
        if(!this.hospProfeSub?.closed) { this.hospProfeSub?.unsubscribe();};
      }
    });
  }

  getObservableList(): Observable<HospProfile[]> {
    return this.hospProfiles.asObservable();
  }

  getObservableSelProf(): Observable<HospProfile> {
    return this.hospProfile.asObservable();
  }

  setSelProfile(hp: HospProfile) {
    this.hospProfile.next(hp);
  }

  async addProfile(hProfile: HospProfile) {

    const ts = new Date();
    hProfile.timeStamp = ts.toString();

    return await this.fire.addToCollection<HospProfile>(
      hProfile,
      USER_KEY,
      true,
      HOSP_KEY
    );
  }

  async removeProfile(hp: HospProfile): Promise<void | boolean> {
    return await this.fire.removeByDocID(hp.id, USER_KEY, true, HOSP_KEY);
  }

  // async update(profiles: HospProfile[]): Promise<void> {

  //   try {
  //     await this.fire.updateHospProfile(profiles);
  //   } catch (error) {
  //     //console.log('HProfileService ERROR: fire.addHospProfile: error');
  //   }

  //   // try {
  //   //   await this.localDB.set('profiles',JSON.stringify(this.hospProfiles));
  //   // } catch (error) {
  //   //   //console.log('HProfileService ERROR: localDB.set: ',error);
  //   // }
  // }

  // public async get(email: string): Promise<Observable<HospProfile[]>> {
  //   const docRef = await collection(
  //     this.firestore,
  //     `${USER_KEY}/${email}/hProfile`
  //   );
  //   return (await collectionData(docRef)) as Observable<HospProfile[]>;
  //   // docSub.subscribe((hospProf) => {
  //   //   //console.log(hospProf);
  //   //   this.hospProfiles = hospProf;
  //   // });
  //   //return (await getDoc(ref)).data();
  // }

  // async remove(pName: string) {
  //   this.hospProfiles = this.hospProfiles.filter((p) => p.name !== pName);

  //   try {
  //     await this.fire.addHospProfile(this.hospProfiles);
  //   } catch (error) {
  //     //console.log('HProfileService ERROR: fire.addProfile: error');
  //   }

  //   try {
  //     await this.localDB.set('profile', JSON.stringify(this.hospProfiles));
  //   } catch (error) {
  //     //console.log('HProfileService ERROR: localDB.set: ', error);
  //   }
  // }
}
