import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { firebaseConfig } from 'src/environments/environment';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { initializeApp, provideFirebaseApp, getApp } from '@angular/fire/app';
import { provideAuth, getAuth, browserPopupRedirectResolver } from '@angular/fire/auth';

import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import {
  initializeAuth,
  indexedDBLocalPersistence,
  // browserPopupRedirectResolver,
  // inMemoryPersistence,
} from '@angular/fire/auth';

import * as cordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Capacitor } from '@capacitor/core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    //provideAuth(() => getAuth()),
    provideAuth(() => {
      console.log('isCapacitorNativePlatform:', Capacitor.isNativePlatform());
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
          //persistence: inMemoryPersistence,
          //popupRedirectResolver: browserPopupRedirectResolver,
        });
      } else {
        //return getAuth();
        return initializeAuth(getApp(), {
          //persistence: inMemoryPersistence,
          persistence: indexedDBLocalPersistence,
          popupRedirectResolver: browserPopupRedirectResolver,
         });
        //const auth = getAuth();
        //if (environment.useFirebaseEmulator) {
        //connectAuthEmulator(auth, 'http://localhost:9099');
        //}
        //return auth;
      }
    }),
    provideStorage(() => getStorage()),
    provideFirestore(() => getFirestore()),
    // // }}),
    IonicStorageModule.forRoot({
      name: 'excarebr',
      driverOrder: [
        // eslint-disable-next-line no-underscore-dangle
        cordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
  ],
  providers: [{ provide: [RouteReuseStrategy], useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
