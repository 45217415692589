import { Injectable, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import  { of, tap } from 'rxjs';
import { PROD } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
//import { AuthService } from './auth.service';



@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  //inject(Auth) IS AN ANGULAR TOOL THAT ALLOWS TO INJECT A SERVICE WHIOUT HAVING THE NEED OF A
  // CONSTRUCTION FUNCTION
  // IT REPLACES contruct(private auth: Auth)
  private auth = inject(Auth);
  //private authServ = inject(AuthService);

  isAvailable() {
    const isLoged = this.auth.currentUser !== null;
    console.log('isAvailable - PermissionsService:',isLoged); // ------- CONSOLE PRINT ----------  //

    return of(isLoged).pipe(
      tap((v) => v )
    );
  }

  isNative(){
    const isWEB = !Capacitor.isNativePlatform();
    const r = PROD && isWEB;
    console.log('isAvailable - PermissionsService - PRO/isWEB/r:',PROD,isWEB,r); // ------- CONSOLE PRINT ----------  //

    return of(!r).pipe(
      tap((v) => v )
    );
  }
}

