<ion-app>
  <ion-menu side="start" contentId="main-content" type="reveal" class="menu">
    <ion-content>
      <ion-list class="menuList">
        <!-- <ion-list-header> -->
        <ion-item>
          <ion-label class="menuLabel"> Menu </ion-label>
        </ion-item>
        <!-- <ion-avatar>
            <img [src]="photoURL" class="my-photo"/>
          </ion-avatar> -->
        <!-- </ion-list-header> -->
        <ion-menu-toggle auto-hide="false">
          <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="goTo('score')"
            ><ion-icon slot="start" name="calculator-outline"></ion-icon>
            {{ lang === "pt" ? "Calculadora" : "Calculator" }}
          </ion-item>

          <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="goTo('scorelist')"
            ><ion-icon slot="start" name="people-outline"></ion-icon>
            {{ lang === "pt" ? "Meus pacientes" : "My patients" }}
          </ion-item>

          <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="goTo('profileList')"
            ><ion-icon slot="start" name="medkit-outline"></ion-icon>
            {{ lang === "pt" ? "Meus hospitais" : "My hospitals" }}
          </ion-item>

          <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="goTo('graph')"
          >
            <ion-icon slot="start" name="bar-chart-outline"></ion-icon>
            {{ lang === "pt" ? "Estatísticas" : "Statistics" }}
          </ion-item>
          <!-- <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="goTo('about')"
            ><ion-icon slot="start" name="cloud-download-outline"></ion-icon>
            Download
          </ion-item> -->
          <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="goTo('account')"
            ><ion-icon slot="start" name="person-outline"></ion-icon>
            {{ lang === "pt" ? "Conta" : "Account" }}
          </ion-item>
          <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="goTo('about')"
            ><ion-icon
              slot="start"
              name="information-circle-outline"
            ></ion-icon>
            {{ lang === "pt" ? "Informações" : "About" }}
          </ion-item>

          <ion-item
            button="true"
            class="itemMenu"
            detail="false"
            (click)="logout()"
            ><ion-icon slot="start" name="log-out-outline"></ion-icon>
            {{ lang === "pt" ? "Sair" : "Log out" }}
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
