//https://www.positronx.io/ionic-firebase-authentication-tutorial-with-examples/
//https://www.positronx.io/angular-angularfire2-tutorial-store-user-state-in-local-storage/
//https://medium.com/identity-beyond-borders/how-to-configure-sign-in-with-apple-77c61e336003
//https://ionicthemes.com/tutorials/firebase-authentication-in-ionic-framework-apps
//https://firebase.google.com/docs/firestore/manage-data/add-data
//https://jsmobiledev.com/article/firebase-authentication-capacitor/
// APPLE SIGNIN - https://www.youtube.com/watch?v=nlk54-QkGk4

import { Injectable, inject } from '@angular/core';
import { ExUser, HospProfile } from 'src/app/classes.interfaces';
import { Router } from '@angular/router';

// ######## CONSTANTS #############
import {
  HOSP_KEY,
  SCORE_KEY,
  USER_KEY,
  appleSignInOptions,
  googleWebSignInOptions,
} from 'src/environments/environment';

// ######## INTERFACES #############
import { userConverter } from 'src/app/classes.interfaces';

// ######## MINE SERVICES #############
import { FirebaseService } from './firebase.service';

// ######## AUTHENTICATION #############
// ### WEB AUTH #####
// import {

// } from 'firebase/auth';
import {
  Auth,
  UserCredential,
  reauthenticateWithRedirect,
  //User,
  AuthCredential,
  AuthProvider,
  OAuthCredential,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  reauthenticateWithCredential,
  signInWithRedirect,
  signInWithCredential,
  signInWithEmailAndPassword,
  getRedirectResult,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  signOut,
  signInWithPopup,
} from '@angular/fire/auth';

// ### NATIVE AUTH - APPLE #####
import {
  SignInWithApple,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';

// ### NATIVE AUTH - GOOGLE #####
// import { google } from 'googleapis';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';

// ######## CAPACITOR #########
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { format } from 'date-fns';

// export declare interface UserMine extends User {
//   displayName: string | null;
//   photoURL: string | null;
// }
// export declare interface UserCredentialMine extends UserCredential {
//   user: UserMine;
// }

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT
  private router: Router = inject(Router); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT

  //private photoURL: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private exUser$: BehaviorSubject<ExUser> = new BehaviorSubject<ExUser>({});
  private exUserSub: any;
  private language: string;

  // #####################################################
  // #####################################################
  // ################    CONSTRUCTOR    ##################
  constructor(private fire: FirebaseService) {
    console.log('Constructor - AuthService'); // ------- CONSOLE PRINT ----------  //
    console.log(
      'Constructor - AuthService - platform:', // ------- CONSOLE PRINT ----------  //
      Capacitor.getPlatform()
    );
  }

  async init(lang: string) {
    console.log('init - AuthService'); // ------- CONSOLE PRINT ----------  //

    this.language = lang;
    console.log(
      'init - AuthService - get lang from init - app.component',
      lang
    );

    GoogleAuth.initialize();

    // ###################################################
    // ############ AUTH STATE OBSERVABLE ################
    onAuthStateChanged(this.auth, (user) => {
      console.log('onAuthStateChanged - AuthService'); // ------- CONSOLE PRINT ----------  //
      console.log('onAuthStateChanged - AuthService - USER: ', user); // ------- CONSOLE PRINT ----------  //

      if (user) {
        try {
          this.setUserData(user).then(() => {
            if (Capacitor.isNativePlatform()) {
              Device.getInfo().then((info) => {
                console.log('INFO:', info);
                this.updateExUser<string>({
                  osVersion: info.osVersion !== undefined ? info.osVersion : '',
                  phoneModel: info.model !== undefined ? info.model : '',
                  manufacturer:
                    info.manufacturer !== undefined ? info.manufacturer : '',
                });
              });
            }

            //try {
            getRedirectResult(this.auth).then((result) => {
              console.log('getRedirectResult - isRESULT', result !== null);
              if (result) {
                const idToken =
                  OAuthProvider.credentialFromResult(result).idToken;
                this.updateExUser({
                  idT: idToken !== undefined ? idToken : '',
                  signInProvider:
                    result.providerId !== undefined ? result.providerId : '',
                });
              }
            });
            //} catch (e) {
            //   console.error(
            //     // --------------------- CONSOLE PRINT ----  //
            //     'auth.service.ts - getRedirectResult - MESSAGE:',
            //     e.message,
            //     'CODE:',
            //     e.code
            //   );
            //   return null;
            // }
          });
        } catch (e) {
          console.error(
            // --------------------- CONSOLE PRINT ----  //
            'auth.service.ts - UPDATE INFON - MESSAGE:',
            e.message,
            'CODE:',
            e.code
          );
          return null;
        }

        localStorage.setItem('user', JSON.stringify(user));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));

        this.exUser$.next({});
        if (!this.exUserSub?.closed) {
          this.exUserSub?.unsubscribe();
        }
        //this.router.navigateByUrl('/login', { replaceUrl: true });
      }
    });
    //return this.photoURL;
  }

  public getInstance() {
    return this.auth;
  }

  public getLanguage() {
    const l = this.language;
    return l;
  }

  // #########################################################
  // ##############       VERIFICATION       #################
  public async isRegistered(): Promise<boolean> {
    const aux = await this.fire.getDocByID(USER_KEY, true);
    const snap = typeof aux !== 'boolean' ? aux : {};
    return snap.exists();
  }

  public async isRegNoAuth(email: string): Promise<boolean> {
    const aux = await this.fire.getDocByID(USER_KEY, false, email);
    const snap = typeof aux !== 'boolean' ? aux : {};
    return snap.exists();
  }

  // #########################################################
  // ############ APPLE SIGNIN - NATIVE VS WEB ###############
  async loginFireApple() {
    if (Capacitor.getPlatform() === 'web') {
      return await this.signInWithAppleWeb();
    } else {
      return await this.signInWithAppleNative();
    }
  }
  // #########     APPLE - NATIVE   #############
  async signInWithAppleNative(): Promise<UserCredential | boolean> {
    console.log('ENTERED - signInWithAppleNative');
    try {
      const result: SignInWithAppleResponse = await SignInWithApple.authorize(
        appleSignInOptions
      );
      console.log('SignInWithApple.authorize', result);
      const provider = new OAuthProvider('apple.com');
      const credentials = provider.credential({
        idToken: result.response.identityToken,
      });
      const userCredential = await signInWithCredential(this.auth, credentials);

      this.updateExUser({
        credentials:
          credentials !== undefined ? JSON.stringify(credentials) : '',
      });

      return userCredential;
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - signInWithAppleNative - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return false;
    }
  }
  // #########     APPLE - WEB     #############
  async signInWithAppleWeb() {
    console.log('ENTERED - signInWithAppleWeb');
    try {
      const provider = new OAuthProvider('apple.com');
      await signInWithRedirect(this.auth, provider);
      const result = await getRedirectResult(this.auth);
      console.log('RESULT - signInWithAppleWeb', result);
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - signInWithAppleWeb - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return null;
    }
  }
  // ##########################################################
  // #######     GOOGLE SIGNIN - NATIVE VS WEB     ############
  public async loginGoogle() {
    try {
      if (Capacitor.getPlatform() === 'web') {
        return await this.signInWithGoogleWeb();
      } else {
        return await this.signInWithGoogleNative();
      }
    } catch (e) {
      console.error(
        'signInWithGoogleWeb: MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return false;
    }
  }
  // #########     GOOGLE - WEB     #############
  async signInWithGoogleWeb() {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(this.auth, provider);
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - register - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
    }
  }

  // #########     GOOGLE - NATIVE     #############
  async signInWithGoogleNative(): Promise<UserCredential | boolean> {
    try {
      // await GoogleAuth.initialize({
      //   clientId: googleSignInOptions.androidClientId,
      //   scopes: ['profile', 'email'],
      // });
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - GoogleAuth.initialize - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return null;
    }

    try {
      const user: User = await GoogleAuth.signIn();
      if (user) {
        const provider = new OAuthProvider('google.com');
        const credentials = provider.credential({
          idToken: user.authentication.idToken,
        });
        const userCredential = await signInWithCredential(
          this.auth,
          credentials
        );

        this.updateExUser({
          credentials:
            credentials !== undefined ? JSON.stringify(credentials) : '',
        });
        // const userRefresh = await GoogleAuth.refresh();
        return userCredential;
      }
    } catch (e) {
      console.error(
        'auth.service.ts - signInWithGoogleNative: MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return false;
    }
  }

  // ########################################################
  // ##############     FACEBOOK SIGNIN     #################
  //TODO get loginFacebook working
  public async loginFacebook() {
    try {
      const credentials = await signInWithRedirect(
        this.auth,
        new FacebookAuthProvider()
      );

      return credentials;
    } catch (e) {
      return null;
    }
  }

  // #####################################################################
  // ################     USERNAME AND PASSWORD     ######################
  // ################     REGISTER     ########################
  // TODO FIX THIS!
  async register({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }): Promise<UserCredential | boolean> {
    console.log('AuthService - register');

    try {
      const credentials = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      ).then(async (cred) => {
        await this.updateExUser({
          signInProvider: 'firebase',
          name: name !== undefined ? name : '',
        });
        return cred;
      });
      return credentials;
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - register - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
    }
  }

  // ##############    LOGIN    #################
  public async loginUserPassword({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<UserCredential | string> {
    try {
      const credentials = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );

      this.fire.updateDocByID(email, USER_KEY, false, {
        signInProvider: 'firebase',
      });

      return credentials;
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - register - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      if (e.code === 'auth/user-not-found') {
        return 'user-not-found';
      } else {
        return 'email-password-incorrect';
      }
    }
  }

  // #########     RESET PASSWORD    #################
  public async resetPw(email: string): Promise<void | boolean> {
    try {
      return await sendPasswordResetEmail(this.auth, email);
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - resetPw - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return false;
    }
  }

  // ###################################################################
  // ################     REAUTHENTICATE USER     ######################
  async reAuthUser(user: ExUser) {
    //console.log(`reAuthUser - signInProvider: ${user.signInProvider} - idT: ${user.idT}`);
    let cred: any;
    try {
      if (user.signInProvider !== 'firebase') {
        if (Capacitor.isNativePlatform()) {
          const aux = await this.getExUser();
          console.log('reAuthUser - Native - ExUser:', aux);
          const exUser = typeof aux !== 'boolean' ? aux : {};
          console.log('reAuthUser - Native - UserCRED:', exUser.credentials);
          //console.log('reAuthUser - Native - CRED:',cred);
          cred = JSON.parse(exUser.credentials) as OAuthCredential;
          return await reauthenticateWithCredential(
            this.auth.currentUser,
            cred
          );
        } else {
          const provider = new OAuthProvider(user.signInProvider);
          return signInWithRedirect(this.auth, provider).then(async () => {
            const result = await getRedirectResult(this.auth);
            cred = OAuthProvider.credentialFromResult(result);
            return await reauthenticateWithCredential(
              this.auth.currentUser,
              cred
            );
          });
        }
        // } else {
        //   cred = new OAuthProvider(user.signInProvider).credential({
        //     idToken: user.idT,
        //   });
        //   console.log(`reAuthUser - cred: ${cred} `);
        // }
      }
      //const cred = await
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - reAuthUser - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
    }
  }

  // ###################################################################
  // ################     USER MANIPULATION     ########################

  public getExUserObs() {
    return this.exUser$;
  }

  public async setUserData(user: any): Promise<void> {
    try {
      const isThereUser = await this.isThereFireUser();
      const areThereHosps = await this.areThereHosps();
      const hosps = areThereHosps.hosps;

      console.log('isThereUser', isThereUser);
      console.log('areThereHosps.trueFalse', areThereHosps.trueFalse);
      console.log('HOSPS:', hosps);
      console.log('USER ON AUTH - SET USER DATA:', user);

      const userName =
        user?.displayName !== null && user?.displayName !== undefined
          ? user.displayName.replace('+', ' ')
          : '';
      if (!isThereUser && user !== undefined) {
        const newExUser: ExUser = {
          name: userName,
          email: user?.email,
          platform: Capacitor.getPlatform(),
          uid: user?.uid,
          // eslint-disable-next-line @typescript-eslint/quotes
          joinDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        };
        await this.fire.setDocByID<ExUser>(
          newExUser,
          USER_KEY,
          true,
          undefined,
          userConverter
        );

        if (areThereHosps.trueFalse) {
          hosps.forEach(async (h) => {
            await this.fire.addToCollection(h, USER_KEY, true, HOSP_KEY);
          });
        }
      }
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - SET USER DATA - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return null;
    }
  }

  public async getExUser(): Promise<ExUser | boolean> {
    const user = await this.fire.getDocByID(
      USER_KEY,
      true,
      undefined,
      userConverter
    );
    console.log('USER', user);
    return user;
  }

  public async areThereHosps(): Promise<{
    trueFalse: boolean;
    hosps: HospProfile[];
  }> {
    const aux = await this.getHosps();
    const hps = typeof aux !== 'boolean' ? aux : undefined;
    const verify = hps !== undefined ? true : false;
    return { trueFalse: verify, hosps: hps };
  }

  async isThereFireUser(): Promise<boolean> {
    const user = await this.getExUser();
    return user !== undefined ? true : false;
  }

  public async updateExUser<TyColData>(updatedFields: {
    [key: string]: TyColData;
  }): Promise<void | boolean> {
    return await this.fire.updateDocByID(
      this.auth.currentUser.email,
      USER_KEY,
      false,
      updatedFields
    );
  }

  async getHosps() {
    return await this.fire.getFromCollection<HospProfile>(
      USER_KEY,
      true,
      HOSP_KEY
    );
  }

  public async confirmUseTerms(): Promise<void | boolean> {
    return await this.updateExUser({ termOfUse: true });
  }

  public async isUseTermsAccepted(): Promise<boolean> {
    const aux = await this.getExUser();
    const isAccepted = typeof aux !== 'boolean' ? aux : undefined;
    console.log('isThereUser:', isAccepted !== undefined ? true : false);
    console.log('isUseTermsAccepted:', isAccepted?.termOfUse);
    return isAccepted?.termOfUse;
  }

  public async removeUserAndData() {
    try {
      const user = (await this.fire.getDocByID(USER_KEY, true)) as ExUser;

      this.reAuthUser(user);

      // #### DELETE - HOSP PROFILES
      await this.fire.emptyCollection(USER_KEY, true, 'hospProfile');
      // #### DELETE - SCORES
      await this.fire.removeByFieldValue(
        'userId',
        this.auth.currentUser.email,
        SCORE_KEY,
        false
      );
      // #### DELETE USER
      await this.fire.removeByDocID(
        this.auth.currentUser.email,
        USER_KEY,
        false
      );
      //await this.reAuthUser(user);

      await this.auth.currentUser.delete();
    } catch (e) {
      this.fire.addErrorMessage(e, 'AuthPage - removeUserAndData');
    }
  }

  // ####################   EMAIL VERIFICATION ################

  public sendVerMail() {
    if (this.auth.currentUser) {
      return sendEmailVerification(this.auth.currentUser).then(() =>
        this.router.navigate(['login'])
      );
    } else {
      return null;
    }
  }

  // ###################################################################
  // ######################     LOG OUT    #############################

  public logout() {
    try {
      return signOut(this.auth);
    } catch (e) {
      console.error(
        // --------------------- CONSOLE PRINT ----  //
        'auth.service.ts - logout - MESSAGE:',
        e.message,
        'CODE:',
        e.code
      );
      return null;
    }
  }

  // ###################################################################
}
