//https://www.positronx.io/ionic-firebase-authentication-tutorial-with-examples/
//https://www.positronx.io/angular-angularfire2-tutorial-store-user-state-in-local-storage/
//https://medium.com/identity-beyond-borders/how-to-configure-sign-in-with-apple-77c61e336003
//https://ionicthemes.com/tutorials/firebase-authentication-in-ionic-framework-apps


// TODO ERROS NEED A PROPOR RESPONSE. NOT JUST TO CONTINUE WITH EMPTY VALUE.

import { Injectable, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDocs,
  getDoc,
  query,
  updateDoc,
  setDoc,
  where,
  WhereFilterOp,
  DocumentData,
  DocumentReference,
} from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { USER_KEY } from 'src/environments/environment';
import { userConverter } from '../classes.interfaces';
import { format } from 'date-fns';
// import { Firestore } from 'firebase-tools';

// const client = require('firebase-tools');
// await client.firestore
//       .delete(collectionPath, {
//         project: process.env.GCLOUD_PROJECT,
//         recursive: true,
//         yes
@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  //public db: Database
  //public scoreList: Subject<UserScore[]>;

  private firestore: Firestore = inject(Firestore); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT
  private auth: Auth = inject(Auth); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT
  private storage: Storage = inject(Storage); // INSTEAD OF ADDING INJECRING VIA CONTRUCTOR ARTUMENT

  // #####################################################
  // #####################################################
  // ################    CONSTRUCTOR    ##################
  constructor() {
    console.log('Constructor - FirebaseService'); // ------- CONSOLE PRINT ----------  //
  }

  public getPathCol(
    key: string,
    isIndentifyUser: boolean,
    varible?: string
  ): string {
    const basePath =
      isIndentifyUser === true
        ? `${key}/${this.auth?.currentUser.email}`
        : `${key}`;
    const path = varible !== undefined ? `${basePath}/${varible}` : basePath;
    return path;
  }

  public async addToCollection<TyColData>(
    colData: TyColData,
    key: string,
    isIndentifyUser: boolean,
    variable?: string
  ): Promise<DocumentReference<DocumentData> | boolean> {
    try {
      const path = this.getPathCol(key, isIndentifyUser, variable);
      const colRef = collection(this.firestore, path);
      return await addDoc(colRef, colData);
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: addToCollection');
      return false;
    }
  }

  public async setDocByID<TyColData>(
    colData: TyColData,
    key: string,
    isIndentifyUser: boolean,
    varible?: string,
    customConverter?: any
  ): Promise<void | boolean> {
    try {
      console.log('setDocByID', colData);
      const path = this.getPathCol(key, isIndentifyUser, varible);
      let colRef = doc(this.firestore, path);
      if (customConverter !== undefined) {
        colRef = colRef.withConverter(customConverter);
      }
      return await setDoc(colRef, colData);
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: setDocByID');
      return false;
    }
  }

  public async getDocByID(
    key: string,
    isIndentifyUser: boolean,
    varible?: string,
    customConverter?: any
  ): Promise<DocumentData | boolean> {
    let colRef:DocumentReference<DocumentData>
    try {
      const path = this.getPathCol(key, isIndentifyUser, varible);
      console.log('PATH:',path);
      colRef = doc(this.firestore, path);
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: getDocByID - doc');
    return false;
    };

    try {
      if (customConverter !== undefined) {
        colRef = colRef.withConverter(customConverter);
      }
      return (await getDoc(colRef)).data();
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: getDocByID - getDoc');
      return false;
    }
  }

  public async getFromCollection<TyColData>(
    key: string,
    isIndentifyUser: boolean,
    varible?: string
  ): Promise<TyColData[] | boolean> {
    try {
      const d: TyColData[] = [];
      const path = this.getPathCol(key, isIndentifyUser, varible);
      const colRef = collection(this.firestore, path);
      const colData = await getDocs(colRef);
      colData.forEach((document) => d.push(document.data() as TyColData));
      return d;
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: getFromCollection');
      return false;
    }
  }

  async getCollectionByQuery<TyColData>(
    path: string,
    field: string,
    comparison: WhereFilterOp,
    value: string
  ): Promise<TyColData[] | boolean> {
    try {
      const scoreQuery = query(
        // GET FIRE REFERENCE FOR THE WHOLE SCORE COLLECTION
        collection(this.firestore, path),
        where(field, comparison, value)
      );
      return (await getDocs(scoreQuery)).docs.map(
        (data) => data.data() as TyColData
      );
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: getCollectionByQuery');
      return false;
    }
  }

  public async removeByDocID(
    id: string,
    key: string,
    isIndentifyUser: boolean,
    varible?: string
  ): Promise <void | boolean>{
    try {
      const path = this.getPathCol(key, isIndentifyUser, varible);
      const dbRef = doc(this.firestore, `${path}/${id}`);
      return await deleteDoc(dbRef);
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: removeByDocID');
      return false;
    }
  }

  public async emptyCollection(
    key: string,
    isIndentifyUser: boolean,
    colectionName: string
  ): Promise <void | boolean>  {
    try {
      const path = this.getPathCol(key, isIndentifyUser, colectionName);
      const colRef = collection(this.firestore, path);
      const querySnapshot = await getDocs(colRef);
      querySnapshot.forEach((queryDoc) => {
        deleteDoc(queryDoc.ref);
      });
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: emptyCollection');
      return false;
    }
  }

  public async removeByFieldValue(
    field: string,
    value: string,
    key: string,
    isIndentifyUser: boolean,
    variable?: string
  ): Promise<void | boolean> {
    try {
      const path = this.getPathCol(key, isIndentifyUser, variable);
      const colRef = collection(this.firestore, path);
      const colQuery = query(colRef, where(`${field}`, '==', value));
      const querySnapshot = await getDocs(colQuery);
      //
      querySnapshot.forEach((queryDoc) => {
        deleteDoc(queryDoc.ref);
      });
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: removeByFieldValue');
      return false;
    }
  }

  public async updateDocByID(
    id: string,
    key: string,
    isIndentifyUser: boolean,
    updatedFields: { [x: string]: any },
    variable?: string
  ): Promise<void | boolean> {
    try {
      console.log('updateDocByID - UPDATED FIELDS:', updatedFields);
      const path = this.getPathCol(key, isIndentifyUser, variable);
      console.log('PATH:', `${path}/${id}`);
      const dbRef = doc(this.firestore, `${path}/${id}`);
      return await updateDoc(dbRef, updatedFields);
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: updateDocByID');
      return false;
    }
  }

  public async addToUserFieldArray<TyColData>(parameters: {
    arrayName: string;
    data: TyColData;
  }): Promise<void | boolean> {
    try {
      const user = await this.getDocByID(
        USER_KEY,
        true,
        undefined,
        userConverter
      );
      console.log(user);
      let field: TyColData[] = [];
      if (user[parameters.arrayName] !== undefined) {
        field = user[parameters.arrayName] as TyColData[];
      }
      field.push(parameters.data);
      await this.updateDocByID(this.auth.currentUser.email, USER_KEY, false, {
        [parameters.arrayName]: field,
      });
    } catch (e) {
      this.addErrorMessage(e, 'FIREBASE: addToUserFieldArray');
      return false;
    }
  }

  async addErrorMessage(error: any, source: string): Promise<boolean> {
    try {
    //   await this.addToUserFieldArray<string>({
    //   arrayName: 'error',
    //   // eslint-disable-next-line @typescript-eslint/quotes
    //   data: (`Date: ${format(new Date(), "yyyy-MM-dd'T'HH:mm")} - ` +
    //     `${source} - ` +
    //     `message: ${error.message} - ` +
    //     `code: ${error.code}`) as string,
    // });
    console.error(
      // --------------------- CONSOLE PRINT ----  //
      `${source} - ` + `message: ${error.message} - ` + `code: ${error.code}`
    );
    } catch (e) {
      console.error(  // --------------------- CONSOLE PRINT ----  //
        'firebase.service.ts - addErrorMessage - MESSAGE:',
         e.message,
         'CODE:',
         e.code
      );
    return false;
    };
  }

  // public async uploadFile(fileToUpload: File) {
  //   const storageRef = ref(
  //     this.storage,
  //     `files/${this.auth.currentUser?.uid}/${fileToUpload.name}`
  //   );
  //   await uploadBytes(storageRef, fileToUpload);
  //   return getDownloadURL(storageRef);
  // }

  // updateScores(score: UserScore) {
  //   const dbRef = doc(this.firestore, `score/${score.id}`);
  //   return updateDoc(dbRef, { scores: score });
  // }

  // updateHospProfile(hProf: HospProfile[]): Promise<void> {
  //   const dbRef = doc(this.firestore, `${USER_KEY}/${this.auth.currentUser.email}`);
  //   return updateDoc(dbRef, { hProfile: hProf });
  // }

  // updateUser(user: ExUser) {
  //   const dbRef = doc(this.firestore, `score/${user.email}`);
  //   return updateDoc(dbRef, { completed: user });
  // }

  // deleteScore(score: UserScore) {
  //   const dbRef = doc(this.firestore, `score/${score.id}`);
  //   return deleteDoc(dbRef);
  // }

  // public getDatabase() {
  //   return getFirestore();
  // }

  // public async getScores() {
  //   const db = getFirestore();
  //   const scoreCol = collection(db, USER_SCORE_KEY);
  //   const scoreSnapshot = await getDocs(scoreCol);
  //   const sl = scoreSnapshot.docs.map((_doc) => _doc.data());

  //   // RETYPE FROM TIMESTAMP TO DATE - FIREBASE CONVERTS DATE TO TIMESTAMP//
  //   sl.map((data) => {
  //     data.timeStamp = new Timestamp(
  //       data.timeStamp.seconds,
  //       data.timeStamp.nanoseconds
  //     ).toDate();
  //   });

  //   // //console.log('firebase.service - sl:',sl)
  //   // // ------- CONSOLE PRINT ----------  //
  //   // //console.log('firebase.service - sl:',sl.map((data) => {
  //   //   return data.timeStamp.getDate()
  //   // }));

  //   sl.sort(
  //     (objA, objB) => objB.timeStamp.getTime() - objA.timeStamp.getTime()
  //   );
  //   return sl;
  //   //this.scoreList.next(sl);
  // }

  // // getScores(){
  // //   return this.scoreList;`  1QA
  // // }

  // public async getUser(email: string): Promise<UserProfile | boolean> {
  //   const db = getFirestore();
  //   const q = query(collection(db, USER_DATA_KEY), where('email', '==', email));
  //   const querySnapshot = await getDocs(q);
  //   const uProfile = querySnapshot.docs.map((_doc) =>
  //     _doc.data()
  //   ) as UserProfile[];

  //   // ------- CONSOLE PRINT ----------  //
  //   //console.log('firebase.service - uProfile[0]:', uProfile);

  //   if (uProfile.length === 0) {
  //     return false;
  //   } else {
  //     return uProfile[0];
  //   }
  // }

  //FIRESTONE
  // public async setUser(userData: UserProfile): Promise<DocumentReference> {
  //   //userData.timeStamp = Timestamp.now().toDate();
  //   const db = getFirestore();
  //   const docRef = await addDoc(collection(db, USER_DATA_KEY), userData);

  //   //console.log('firebase.service - docRef', docRef);

  //   return docRef;
  // }

  // public async addUserScore(score: UserScore): Promise<UserScore> {
  //   //const ts = Timestamp.now().toDate();
  //   const ts = new Date();
  //   const db = getFirestore();
  //   score.timeStamp = ts;
  //   score.dateMonthYear = ts.toDateString();
  //   const month = ts.getMonth() + 1;
  //   score.monthYear = month.toString().concat('/', ts.getFullYear().toString());
  //   score.id = score.userId + '_' + ts.getTime().toString();
  //   const docRef = await addDoc(collection(db, USER_SCORE_KEY), score);
  //   return score;
  // }

  // public async removeScoresByScoreID(id: string): Promise<void> {
  //   const db = getFirestore();
  //   const q = query(collection(db, 'userScore'), where('id', '==', id));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((_doc) => {
  //     deleteDoc(_doc.ref);
  //   });
  // }

  // public async removeScoresByID(id: string): Promise<void> {
  //   const db = getFirestore();
  //   //console.log('userData/' + id);
  //   await deleteDoc(doc(db, 'userData/', id));
  // }

  // public async addAccess(vAccess: UserAccess): Promise<DocumentReference> {
  //   //userData.timeStamp = Timestamp.now().toDate();
  //   //console.log('firebase.service - addAccess', vAccess);
  //   const db = getFirestore();
  //   const docRef = await addDoc(collection(db, USER_ACCESS_KEY), vAccess);

  //   ////console.log('firebase.service - vAccess - docRef', docRef);

  //   return docRef;
  // }

  //DATABASE
  // async setUser(userData: User) {
  //   //console.log(userData);
  //   const db = getDatabase();
  //   set(ref(db, 'users/' + userData.id), {
  //     username: userData.name,
  //     email: userData.email,
  //     profile_picture: userData.imageUrl
  //   });

  //}

  // getUserByEmail(email: string): Observable<User> {

  //   const db = getFirestore();
  //   const scoreCol = collection<>(db, USER_SCORE_KEY);

  //   const scoreSnapshot = await getDocs(scoreCol);
  //   const scoreList = scoreSnapshot.docs.map(doc => doc.data());
  //   return scoreList;

  //   const collection = this.firestore.collection<User>('users', ref => ref.where('email', '==', email))
  //   const user$ = collection
  //     .valueChanges()
  //     .pipe(
  //       map(users => {
  //         const user = users[0];
  //         //console.log(user);
  //         return user;
  //       })
  //     );

  //   return user$;
  //   }
}
