// import { Authentication } from '@codetrix-studio/capacitor-google-auth';
import {
  AnimationBuilder,
  IonicSafeString,
  Mode,
  TextFieldTypes,
} from '@ionic/core';

// export class ExUser {
//   public name?: string;
//   public email?: string;
//   public photoURL?: string;
//   public imageUrl?: string;
//   public platform?: string;
//   public signInProvider?: string;
//   public termOfUse?: boolean;
//   public joinDate?: string;
//   public idT?: string;
//   public hProfile?: HospProfile[];
//   public scores?: UserScore[];

//   constructor(
//     name?: string,
//     email?: string,
//     hProfile?: HospProfile[],
//     termOfUse?: boolean,
//     platform?: string,
//     signInProvider?: string,
//     joinDate?: string
//   ) {
//     this.name = name;
//     this.email = email;
//     this.hProfile = hProfile !== undefined ? hProfile : [];
//     this.termOfUse = termOfUse !== undefined ? termOfUse : false;
//     this.platform = platform !== undefined ? platform : '';
//     this.signInProvider = signInProvider !== undefined ? signInProvider : '';
//     this.joinDate = joinDate !== undefined ? joinDate : '';
//   }
// }

export interface ExUser {
  name?: string;
  email?: string;
  photoURL?: string;
  imageUrl?: string;
  uid?: string;
  platform?: string;
  phoneModel?: string;
  manufacturer?: string;
  osVersion?: string;
  signInProvider?: string;
  termOfUse?: boolean;
  joinDate?: string;
  idT?: string;
  credentials?: string;
  hProfile?: HospProfile[];
  scores?: UserScore[];
  error?: string[];
}

export const userConverter = {
  toFirestore: (user: ExUser) => ({
    name: user?.name !== undefined ? user.name : '',
    email: user?.email !== undefined ? user.email : '',
    termOfUse: user?.termOfUse !== undefined ? user.termOfUse : '',
    platform: user?.platform !== undefined ? user.platform : '',
    phoneModel: user?.phoneModel !== undefined ? user.phoneModel : '',
    manufacturer: user?.manufacturer !== undefined ? user.manufacturer : '',
    osVersion: user?.osVersion !== undefined ? user.osVersion : '',
    signInProvider: user?.signInProvider !== undefined ? user.signInProvider : '',
    joinDate: user?.joinDate !== undefined ? user.joinDate : '',
    error: user?.error !== undefined ? user.error : [],
    credentials: user?.credentials !== undefined ? user.credentials : [],
    uid: user?.uid !== undefined ? user.uid : ''
  }),
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const exUser: ExUser = {
      name: data.name,
      email: data.email,
      termOfUse: data.termOfUse,
      platform: data.platform,
      phoneModel: data.phoneModel,
      manufacturer: data.manufacturer,
      osVersion: data.osVersion,
      signInProvider: data.signInProvider,
      joinDate: data.joinDate,
      error: data.error,
      credentials: data.credentials,
      uid: data.uid

    };
    return exUser;
  },
};

export interface HospProfile {
  id?: string;
  name?: string;
  emergency?: boolean;
  teachingHosp?: boolean;
  southSoutheast?: boolean;
  suplementary?: boolean;
  certification?: boolean;
  hospEffectGroup?: string;
  timeStamp?: string;
}

export interface UserAccess {
  email: string;
  timeStamp: any;
  origin: string;
}

export interface Nature {
  name: string;
  value: number;
}

export interface UserScore {
  age?: number;
  asa?: number;
  nature?: number;
  surgery?: Surgery;
  class?: string;
  preditoPerc?: number;
  risk?: string;
  risco2cat?: number;
  hProfile?: HospProfile;
  timeStamp?: string;
  dateMonthYear?: string;
  monthYear?: string;
  userId?: string;
  uid?: string;
  id?: string;
  hosp?: string;
  riskClass?: string;
  obs?: string;
  gender?: string;
}

export interface RiskClass {
  classI: number;
  classII: number;
  classIII: number;
  classIV: number;
}

export interface Surgery {
  id: number;
  general: string;
  specific: string;
  name: string;
  size: number;
  surgeryDateTime?: string;
}

export interface BetaProfile {
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  I: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  II: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  III: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IV: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  V: boolean;
}

export interface SurgParameter {
  general?: string;
  specific?: string;
}

export enum SurgSizePT {
  '_0' = 'Não Grande Porte',
  '_1' = 'Grande Porte',
}

export enum SurgSizeEN {
  '_0' = 'Non-Major',
  '_1' = 'Major',
}

export class MathArray {
  constructor() {}
  count(array) {
    const counts = [];
    for (const num of array) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    return counts;
  }
}



export interface SegmentChangeEventDetail {
  value?: string;
}

export interface SegmentCustomEvent extends CustomEvent {
  target: HTMLIonSegmentElement;
  detail: SegmentChangeEventDetail;
}

export interface DatetimeCustomEvent extends CustomEvent {
  detail: DatetimeChangeEventDetail;
  target: HTMLIonDatetimeElement;
}

export interface DatetimeChangeEventDetail {
  value?: string | null;
}

export interface AlertInput {
  type?: TextFieldTypes | 'checkbox' | 'radio' | 'textarea';
  name?: string;
  placeholder?: string;
  value?: any;
  /**
   * The label text to display next to the input, if the input type is `radio` or `checkbox`.
   */
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  handler?: (input: AlertInput) => void;
  min?: string | number;
  max?: string | number;
  cssClass?: string | string[];
  attributes?: { [key: string]: any };
  tabindex?: number;
}

export interface AlertOptions {
  header?: string;
  subHeader?: string;
  message?: string | IonicSafeString;
  cssClass?: string | string[];
  inputs?: AlertInput[];
  buttons?: (AlertButton | string)[];
  backdropDismiss?: boolean;
  translucent?: boolean;
  animated?: boolean;
  htmlAttributes?: { [key: string]: any };

  mode?: Mode;
  keyboardClose?: boolean;
  id?: string;

  enterAnimation?: AnimationBuilder;
  leaveAnimation?: AnimationBuilder;
}

export interface AlertButton {
  text: string;
  role?: 'cancel' | 'destructive' | string;
  cssClass?: string | string[];
  handler?: (value: any) => boolean | void | { [key: string]: any };
}

export const removeAccents = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

