import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleDisableService {

  constructor() {

    if(environment.production){
      console.log = () => {};
      console.warn = () => {};
      console.error = () => {};
    }

   }
}
